export enum Country {
  be = 'BE',
  nl = 'NL',
  fr = 'FR',
  lu = 'LU',
}

export type Address = {
  country: Country;
  postalCode: string;
  municipality: string;
  street: string | null;
  number: string | null;
};
