type CacheItem<Key, Value> = {
  key: Key;
  object: Value;
};

export default class MemoryCache<Key extends keyof any, Value> {
  private cachedObjects: CacheItem<Key, Value>[] = [];

  getObject(key: Key): Value | null {
    const item = this.cachedObjects.find((item) => item.key === key);
    return item?.object ?? null;
  }

  storeObject(key: Key, object: Value) {
    const item = this.cachedObjects.find((item) => item.key === key);
    if (item) {
      item.object = object;
      return;
    }

    const newItem: CacheItem<Key, Value> = { key, object };
    this.cachedObjects.push(newItem);
  }

}
