import { FormEvent, Fragment, useState } from 'react';
import validator from 'validator';
import { Labels, useTranslation } from '../../../i18n';
import authService from '../../domain/authService';
import { IconArrowReturn } from '../../../assets/icons';
import { LoadingIndicator } from '../../../common';

import styles from './ForgotPasswordContent.module.scss';
import classNames from 'classnames';

type ForgotPasswordProps = {
  onBack: () => void;
};

const ForgotPasswordContent = ({ onBack }: ForgotPasswordProps) => {
  const t = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  return (
    <Fragment>
      <div className={ styles.back }>
        <a href="#login" onClick={ onBack }>
          <IconArrowReturn />
          <span>{ t(Labels.forgotPasswordGoBackCta) }</span>
        </a>
      </div>
      { submitted ?
        <PasswordResetRequested /> :
        <ForgotPasswordForm
          onRequestSubmitted={ () => setSubmitted(true) }
        />
      }

    </Fragment>
  );
};

type ForgotPasswordFormProps = {
  onRequestSubmitted: () => void;
};


export const ForgotPasswordForm = ({ onRequestSubmitted }: ForgotPasswordFormProps) => {
  const t = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState('');

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const isValid = validator.isEmail(email);
    if (!isValid) return;

    try {
      setIsProcessing(true);
      await authService.forgotPassword(email);
      onRequestSubmitted();
    } finally {
      setIsProcessing(false);
    }
  };

  const buttonClasses = classNames('form-submit', { 'form-submit--loader': isProcessing });

  return (
    <div data-cy="passwordResetForm">
      <h1 className={ styles.title }>{ t(Labels.forgotPasswordTitle) }</h1>
      <form onSubmit={ onSubmit }>
        <div className="form-item form-item--required">
          <label htmlFor="emailaddress">
            { t(Labels.forgotPasswordEmailAddressLabel) }
          </label>
          <input
            id="emailaddress"
            name="emailaddress"
            type="email"
            value={ email }
            onChange={ (e) => setEmail(e.target.value) }
            disabled={ isProcessing }
            required
          />
        </div>

        <div className="form-actions">
          <button className={ buttonClasses } type="submit">
            <span>{ t(Labels.forgotPasswordRequestCta) }</span>
            { isProcessing && <LoadingIndicator /> }
          </button>
        </div>
      </form>
    </div>
  );
};

const PasswordResetRequested = () => {
  const t = useTranslation();
  return (
    <div data-cy="passwordResetRequested">
      <h1 className={ styles.notificationTitle }>{ t(Labels.forgotPasswordRequestedTitle) }</h1>
      <div className={ styles.notificationText }>
        <p>{ t(Labels.forgotPasswordRequestedMessage) }</p>
      </div>
    </div>
  );
};

export default ForgotPasswordContent;
