import React from 'react';
import { Labels, useTranslation } from '../../i18n';
import styles from './FullscreenLoader.module.scss';

const FullscreenLoader = () => {
  const t = useTranslation();

  return (
    <div className={ styles.loader }>
      <div className={ styles.inner }>
        <div className={ styles.circle }>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
        </div>
        <div className={ styles.text }>{ t(Labels.loading) }</div>
      </div>
    </div>
  );
};

export default FullscreenLoader;
