import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const totalPerimeterCalculation = (productPrice: ProductPrice, distance: number, sitePerimeter: number): OfferLine | undefined => {
  if (sitePerimeter <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(sitePerimeter);
  const quantity = formatters.formatMeter(sitePerimeter);
  const unitPrice = formatters.formatPricePerMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default totalPerimeterCalculation;
