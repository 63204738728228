import { MemoryCache } from '../../common';
import { pricesApi } from '../data';
import { PriceList } from './types';

const priceListCache = new MemoryCache<string, PriceList>();

const getMostRecentPriceList = async (): Promise<PriceList> => {
  const priceList = await pricesApi.getMostRecentPriceList();
  priceListCache.storeObject(priceList.id, priceList);
  return priceList;
};

const getPriceList = async (id: string): Promise<PriceList> => {
  const cachedItem = priceListCache.getObject(id);

  if (cachedItem) {
    console.debug('Returning price list from cache');
    return cachedItem;
  }

  console.debug('Price list not found in cache. Requesting api');
  const priceList = await pricesApi.getPriceList(id);
  priceListCache.storeObject(priceList.id, priceList);
  return priceList;
};

const priceService = {
  getMostRecentPriceList,
  getPriceList,
};

export default priceService;
