import { Email } from './../domain/types';
import { Offer, Money } from './../offer/domain/types';
import { Address, Country } from '../../common/domain/types';
import { Company, CompanyContact } from '../../company/domain/types';
import { OfferPdf, Project, ProjectLanguage, ProjectStatus, ProjectSummary } from '../domain/types';
import { ProjectApi } from './projectApi';
import { Configuration } from '../configurator/domain/types';
import mockImages from '../ui/detail/visuals/mockImages';

const mockProjects: Project[] = [
  {
    id: '1',
    name: 'Pachtgoed',
    companyId: '1',
    status: ProjectStatus.ongoing,
    latestUpdate: new Date('2023-01-16T11:36:00'),
    language: ProjectLanguage.nl_be,
    address: {
      country: Country.be,
      street: 'Amerigo Vespuccistraat',
      number: '2',
      postalCode: '9940',
      municipality: 'Evergem',
    },
    distance: 40,
    priceListId: '1',
    contact: {
      id: '1',
      email: 'contact1@duo-brugge.be',
      firstName: 'Rita',
      lastName: 'Gesmorov',
    },
    acreage: '22.000 m³',
    offer: null,
    mailSettings: {
      emailAddresses: [],
      message: 'wysiwyg.mailMessage',
    },
    visuals: [],
    floorPlans: [],
    thumbnail: null,
    discount: null,
    configuration: null,
  },
  {
    id: '2',
    name: 'DPK',
    companyId: '2',
    status: ProjectStatus.sent,
    latestUpdate: new Date('2023-01-16T11:36:00'),
    language: ProjectLanguage.en,
    address: {
      country: Country.be,
      street: 'Bargeweg',
      number: '2',
      postalCode: '8000',
      municipality: 'Brugge',
    },
    distance: 40,
    priceListId: '2',
    contact: {
      id: '2',
      email: 'contact2@duo-brugge.be',
      firstName: 'Djo',
      lastName: 'Baiden',
    },
    acreage: '18.000 m³',
    offer: null,
    mailSettings: {
      emailAddresses: [],
      message: 'wysiwyg.mailMessage',
    },
    visuals: [],
    floorPlans: [],
    thumbnail: null,
    discount: null,
    configuration: null,
  },
];

const getProjectSummaries = async (): Promise<ProjectSummary[]> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects;
};

const createProject = async (company: Company, contact: CompanyContact, projectName: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const updateProject = async (projectId: string, companyContactId: string, name: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const updateProjectStatus = async (projectId: string, status: ProjectStatus): Promise<Project> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const updateConfiguration = async (projectId: string, configuration: Configuration) => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const updateDiscount = async (projectId: string, discount: Money) => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const updateVisuals = async (projectId: string, images: string[]): Promise<string[]> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockImages;
};

const updateFloorPlans = async (projectId: string, images: string[]): Promise<string[]> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockImages;
};

const createOffer = async (projectId: string, offer: Offer, offerHash: string): Promise<OfferPdf> => {
  await new Promise((r) => setTimeout(r, 1000));
  return {
    id: 'mockOfferId',
    number: 'dummy',
    offerHash: offerHash,
    priceList: 'mockPriceListId',
    pdfs: {
      en: '#',
      nl_be: '#',
      nl_nl: '#',
      fr: '#',
    },
  };
};

const getProject = async (projectId: string): Promise<Project> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const duplicateProject = async (project: Project, name: string): Promise<Project> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockProjects[0];
};

const deleteProject = async (projectId: string): Promise<void> => {
  await new Promise((r) => setTimeout(r, 1000));
};

const downloadOfferPdf = async (endpoint: string): Promise<void> => {
  await new Promise((r) => setTimeout(r, 1000));
};

const mailOffer = async (offerId: string, emailAddresses: Email[], message: string): Promise<void> => {
  await new Promise((r) => setTimeout(r, 1000));
};

const mockProjectApi: ProjectApi = {
  getProjectSummaries,
  createProject,
  updateProject,
  updateProjectStatus,
  updateConfiguration,
  updateDiscount,
  updateVisuals,
  updateFloorPlans,
  createOffer,
  getProject,
  duplicateProject,
  deleteProject,
  downloadOfferPdf,
  mailOffer,
};

export default mockProjectApi;
