import styles from './ProjectLayout.module.scss';

type ProjectLayoutProps = {
  children?: React.ReactNode;
  loading?: boolean;
};

const ProjectLayout = (props: ProjectLayoutProps) => {

  return (
    <div className={ styles.wrapper }>
      <article className={ styles.content } data-loading={ props.loading }>
        { props.children }
      </article>

      <div className={ styles.image }>
        <picture>
          <img src="../../img/bg__building.png" width={ 984 } height={ 800 } alt="The Circle - building the future" />
        </picture>

        <div className={ styles.logo }>
          <a href="#the-circle">
            <img src="../../img/logo__the-circle-willy-naessens--white.png" width={ 159 } height={ 52 } alt="The Circle - Willy Naessens" />
          </a>
        </div>
      </div>
    </div >
  );
};


export default ProjectLayout;
