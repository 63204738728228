import { OfferLine } from '../../..';
import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { getDistancePrice } from '../priceFinders';

const concreteFloorOutsideAreaCalculation = (productPrice: ProductPrice, distance: number, concreteFloorOutsideArea: number): OfferLine | undefined => {
  if (concreteFloorOutsideArea <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(concreteFloorOutsideArea);
  const quantity = formatters.formatSquareMeter(concreteFloorOutsideArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default concreteFloorOutsideAreaCalculation;
