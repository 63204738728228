import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const greenAreaCalculation = (productPrice: ProductPrice, distance: number, greenArea: number): OfferLine | undefined => {
  if (greenArea <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(greenArea);
  const quantity = formatters.formatSquareMeter(greenArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default greenAreaCalculation;
