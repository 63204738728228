import React from 'react';
import { IconSelect } from '../../../assets/icons';

type DropdownProps<T extends DropdownOption> = {
  identifier: string; //To use as html#id
  placeholderText?: string;
  options: T[];
  selectedOption?: T;
  label: string;
  onChange: (option?: T) => void;
  labelHidden?: boolean;
  disabled?: boolean;
  required?: boolean;
  allowSelectPlaceholder?: boolean;
  hideAsterisk?: boolean;
  isSmall?: boolean;
};

export type DropdownOption = {
  value: string;
  text: string;
};

export default function Dropdown<T extends DropdownOption>(props: DropdownProps<T>) {
  const placeholderValue = 'placeholder_value';

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (placeholderValue === e.target.value) {
      props.onChange(undefined);
    } else {
      const option = props.options.find((option) => option.value === e.target.value);
      props.onChange(option);
    }
  };

  const selectValue = props.selectedOption ? props.selectedOption.value : placeholderValue;
  const labelClasses = props.labelHidden ? 'visually-hidden' : '';
  const wrapperClass = props.isSmall ? 'small-select' : '';

  return (
    <div className={ wrapperClass } >
      <label htmlFor={ props.identifier } className={ labelClasses }>
        { props.label }
        { props.required && !props.hideAsterisk && (
          <span> *</span>
        ) }
      </label>
      <div className="select-wrapper">
        <select name={ props.identifier } id={ props.identifier } onChange={ onChange } value={ selectValue } disabled={ props.disabled } required={ props.required }>
          {
            props.placeholderText
            && (<option value={ placeholderValue } disabled={ props.allowSelectPlaceholder === false }>{ props.placeholderText }</option>)
          }
          {
            props.options.map((option) => {
              return <option key={ option.value } value={ option.value }>{ option.text }</option>;
            })
          }
        </select>
        <div className="select-wrapper__arrow">
          <IconSelect />
        </div>
      </div>
    </div>
  );
}
