import { Labels, useTranslation } from '../../../i18n';
import { IconArrowReturn } from '../../../assets/icons';

import styles from './BackButton.module.scss';

type BackButtonProps = {
  onClick: () => void;
};

const BackButton = (props: BackButtonProps) => {
  const t = useTranslation();

  return <div className={ styles.button }>
    <button onClick={ props.onClick }>
      <IconArrowReturn />
      <span>{ t(Labels.userSettingsBackCta) }</span>
    </button>
  </div>;
};

export default BackButton;
