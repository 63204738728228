import { apiClient } from '../../common';
import { PriceList } from '../domain/types';
import mapper from './mapper';
import { PricesApi } from './pricesApi';
import { PriceListDto, PriceListDtoScheme } from './types';

const getMostRecentPriceList = async (): Promise<PriceList> => {
  try {
    const endpoint = priceListEndpoint();
    const response = await apiClient.get<PriceListDto>(endpoint);
    const dto = PriceListDtoScheme.parse(response.data);
    return mapper.mapPriceList(dto);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPriceList = async (id: string): Promise<PriceList> => {
  try {
    const endpoint = priceListEndpoint(id);
    const response = await apiClient.get<PriceListDto>(endpoint);
    const dto = PriceListDtoScheme.parse(response.data);
    return mapper.mapPriceList(dto);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const priceListEndpoint = (id?: string) => {
  const endpoint = '/api/price-list';
  if (id) {
    return `${endpoint}?id=${id}`;
  }
  return endpoint;
};


const pricesApiImpl: PricesApi = {
  getMostRecentPriceList,
  getPriceList,
};

export default pricesApiImpl;
