import { Money } from './../offer/domain/types';
import { Configuration } from './../configurator/domain/types';
import { Address } from '../../common/domain/types';
import { Company, CompanyContact } from '../../company/domain/types';
import { projectApi } from '../data';
import { Offer } from '../offer/domain/types';
import { Email, OfferPdf, Project, ProjectLanguage, ProjectStatus, ProjectSummary } from './types';

const getProjectSummaries = async (): Promise<ProjectSummary[]> => {
  return projectApi.getProjectSummaries();
};

const createProject = async (company: Company, contact: CompanyContact, projectName: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  return projectApi.createProject(company, contact, projectName, language, address);
};

const updateProject = async (projectId: string, contact: CompanyContact, projectName: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  return projectApi.updateProject(projectId, contact.id, projectName, language, address);
};

const updateProjectStatus = async (projectId: string, status: ProjectStatus): Promise<Project> => {
  return projectApi.updateProjectStatus(projectId, status);
};

const updateConfiguration = async (projectId: string, configuration: Configuration): Promise<Project> => {
  return await projectApi.updateConfiguration(projectId, configuration);
};

const updateDiscount = async (projectId: string, discount: Money): Promise<Project> => {
  return await projectApi.updateDiscount(projectId, discount);
};

const updateVisuals = async (projectId: string, images: string[]): Promise<string[]> => {
  return await projectApi.updateVisuals(projectId, images);
};

const updateFloorPlans = async (projectId: string, images: string[]): Promise<string[]> => {
  return await projectApi.updateFloorPlans(projectId, images);
};

const createOffer = async (projectId: string, offer: Offer, offerHash: string): Promise<OfferPdf> => {
  return projectApi.createOffer(projectId, offer, offerHash);
};

const getProject = async (projectId: string) => {
  return await projectApi.getProject(projectId);
};

const duplicateProject = async (project: Project, name: string) => {
  return await projectApi.duplicateProject(project, name);
};

const deleteProject = async (projectId: string) => {
  return await projectApi.deleteProject(projectId);
};

const downloadOfferPdf = async (endpoint: string) => {
  return await projectApi.downloadOfferPdf(endpoint);
};

const mailOffer = async (offerId: string, emailAddresses: Email[], message: string) => {
  return await projectApi.mailOffer(offerId, emailAddresses, message);
};

const projectService = {
  getProjectSummaries,
  createProject,
  updateProject,
  updateProjectStatus,
  updateConfiguration,
  updateDiscount,
  updateVisuals,
  updateFloorPlans,
  createOffer,
  getProject,
  duplicateProject,
  deleteProject,
  downloadOfferPdf,
  mailOffer,
};

export default projectService;
