import { useState } from 'react';
import ReactModal from 'react-modal';
import { IconEdit } from '../../../../assets/icons';
import { Labels, useTranslation } from '../../../../i18n';
import EditProjectForm from './EditProjectForm';
import styles from './EditProjectButton.module.scss';

const EditProjectButton = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  return (
    <div className={ styles.edit }>
      <EditProjectModal
        isOpen={ modalVisible }
        onClose={ closeModal }
      />
      <Button
        onClick={ openModal }
      />
    </div>
  );
};

type ButtonProps = {
  onClick: () => void;
};

const Button = (props: ButtonProps) => {
  const t = useTranslation();
  return (
    <button
      data-cy="editProjectCta"
      onClick={ props.onClick }
      className={ styles.btn }
    >
      <span className={ styles.icon }><IconEdit /></span>
      <span className={ styles.text }>{ t(Labels.editProjectEditDetails) }</span>
    </button>

  );
};


type EditProjectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const EditProjectModal = (props: EditProjectModalProps) => {
  const t = useTranslation();

  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className="modal modal--edit-project"
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner" data-cy="editProjectModal">
        <div className="modal__content">
          <h2 className="modal__title">{ t(Labels.editProjectModalTitle) }</h2>
          <div
            className="modal__form"
          >
            <EditProjectForm onUpdated={ props.onClose } />
          </div>
          <CloseButton onClose={ props.onClose } />
          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};


type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose } data-cy="closeModalCta">
        { t(Labels.editProjectModalClose) }
      </button>
    </div>
  );
};

export default EditProjectButton;
