import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import FullscreenLoader from '../../../common/ui/FullscreenLoader';
import { useCompany } from '../../../company';
import { Company } from '../../../company/domain/types';
import { Labels, useTranslation } from '../../../i18n';
import projectService from '../../domain/projectService';
import { ProjectStatus, ProjectSummary } from '../../domain/types';
import ProjectFilter from './ProjectFilter';
import ProjectTile from './ProjectTile';
import styles from './ProjectList.module.scss';
import { useErrorBanner } from '../../../common/ui/ErrorBanner';

const ProjectList = () => {
  const t = useTranslation();
  const [statusFilter, setStatusFilter] = useState<ProjectStatus | null>(null);
  const [selectedCompany] = useCompany();
  const state = useAsync(projectService.getProjectSummaries, []);
  const { setVisible } = useErrorBanner();

  useEffect(() => {
    if (state.error) {
      setVisible(true);
    }
  }, [setVisible, state.error]);

  if (state.loading || !state.value) {
    return <FullscreenLoader />;
  }

  const filteredProjects = filterProjects(state.value, selectedCompany, statusFilter);

  return (
    <div className={ styles.projects }>
      {
        filteredProjects.length !== 0 &&
        <ProjectFilter onFilterChanged={ setStatusFilter } currentFilter={ statusFilter } />
      }
      <div className={ styles.list }>
        {
          filteredProjects.map((project) => (
            <ProjectTile key={ `project-${project.id}` } projectSummary={ project } />
          ))
        }
        {
          filteredProjects.length === 0 &&
          <div className={ styles.empty }>
            <div className={ styles.lines4 }><img src="img/lines/style4.png" alt="" /></div>
            <div className={ styles.lines2 }><img src="img/lines/style2.png" alt="" /></div>
            <div className={ styles.lines5 }><img src="img/lines/style5.png" alt="" /></div>
            { t(Labels.projectOverviewEmpty) }
          </div>
        }
      </div>
    </div>
  );
};

const filterProjects = (allProjects: ProjectSummary[], company: Company | null, status: ProjectStatus | null) => {
  const filteredWithCompany = company
    ? allProjects.filter((project) => project.companyId === company.id)
    : allProjects;

  const filteredWithStatus = status
    ? filteredWithCompany.filter((project) => project.status === status)
    : filteredWithCompany;

  return filteredWithStatus;
};

export default ProjectList;
