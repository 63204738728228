import { InvalidCredentialsError, UnauthorizedByServerError } from '../../domain/exceptions';
import { AuthTokens } from '../../domain/types';
import { apiClient, unauthenticatedApiClient } from '../../../common';
import { AuthApi } from './authApi';
import { z } from 'zod';

const loginEndpoint = '/api/login';
const refreshTokenEndpoint = '/api/token/refresh';
const forgotPasswordEndpoint = '/api/app-user/forgot-password';
const resetPasswordEndpoint = '/api/app-user/reset-password';

const tokensDtoScheme = z.object({
  token: z.string(),
  refreshToken: z.string(),
});

type TokensDto = z.infer<typeof tokensDtoScheme>;

const requestTokensForCredentials = async (username: string, password: string): Promise<AuthTokens> => {
  const body = {
    username,
    password,
  };

  try {
    const response = await unauthenticatedApiClient.post<TokensDto>(
      loginEndpoint,
      body
    );
    return {
      accessToken: response.data.token,
      refreshToken: response.data.refreshToken,
    };
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new InvalidCredentialsError('Invalid credentials');
    }
    throw error;
  }
};

const forgotPassword = async (email: string): Promise<void> => {
  const body = {
    email,
  };

  await unauthenticatedApiClient.post(
    forgotPasswordEndpoint,
    body
  );
};


const requestPasswordReset = async (): Promise<void> => {
  await apiClient.post(resetPasswordEndpoint);
};

const refreshTokens = async (refreshToken: string): Promise<AuthTokens> => {
  const body = {
    refreshToken,
  };
  try {
    const response = await unauthenticatedApiClient.post(
      refreshTokenEndpoint,
      body
    );
    return {
      accessToken: response.data.token,
      refreshToken: response.data.refreshToken,
    };
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new UnauthorizedByServerError('Invalid credentials');
    }
    throw error;
  }
};

const authApiImpl: AuthApi = {
  requestTokensForCredentials,
  forgotPassword,
  requestPasswordReset,
  refreshTokens,
};

export default authApiImpl;
