class InvalidCredentialsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidCredentialsError';
  }
}

class NoValidTokensError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoValidTokensError';
  }
}

class UnauthorizedByServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedByServerError';
  }
}

export {
  InvalidCredentialsError,
  NoValidTokensError,
  UnauthorizedByServerError,
};
