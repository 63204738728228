import { IconSelect } from '../../../assets/icons';
import { useRef, useState } from 'react';
import { Labels, useTranslation } from '../../../i18n';
import { ProjectStatus } from '../../domain/types';
import { useFocus } from '../../../common/hooks/useFocus';
import styles from './ProjectFilter.module.scss';

type FilterStatusOption = {
  text: string;
  status: ProjectStatus | null;
};


type ProjectFilterProps = {
  currentFilter: ProjectStatus | null;
  onFilterChanged: (status: ProjectStatus | null) => any;
};
const ProjectFilter = (props: ProjectFilterProps) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useFocus(dropdownRef, () => setOpen(false));

  const onChanged = (option: FilterStatusOption) => {
    props.onFilterChanged(option.status);
    setOpen(false);
  };

  const statusOptions: FilterStatusOption[] = Object.values(ProjectStatus).map((status) => ({
    text: Labels[`projectStatus_${status}`],
    status,
  }));

  const optionAll: FilterStatusOption = {
    text: Labels.projectFilterAll,
    status: null,
  };

  const options = [...statusOptions, optionAll];
  const selectedOption = options.find((option) => option.status === props.currentFilter)!;

  return (
    <div className={ styles.filter } ref={ dropdownRef }>
      <button className={ styles.filterBtn } type="button" onClick={ () => setOpen(!open) } aria-expanded={ open } aria-controls="project-status">
        <span className={ styles.label }>{ t(Labels.projectFilterText) }</span>
        <span className={ styles.selected }>{ selectedOption.text }</span>
        <span className={ styles.icon }><IconSelect /></span>
      </button>
      <ul className={ styles.filterList } id="project-status" aria-hidden={ !open }>
        {
          options.map((option) => ((
            <FilterItem
              key={ `status-option-${option.status}` }
              option={ option }
              isSelected={ option.status === selectedOption.status }
              onClicked={ () => onChanged(option) }
            />
          )))
        }
      </ul>
    </div>
  );
};


type FilterItemProps = {
  option: FilterStatusOption;
  isSelected: boolean;
  onClicked: () => any;
};
const FilterItem = ({ option, isSelected, onClicked }: FilterItemProps) => {
  return (
    <li onClick={ onClicked } data-selected={ isSelected }>
      <button>{ option.text }</button>
    </li>
  );
};

export default ProjectFilter;
