import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const mezzanineWidthCalculation = (productPrice: ProductPrice, distance: number, mezzanineWidth: number): OfferLine | undefined => {
  if (mezzanineWidth <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(mezzanineWidth);
  const quantity = formatters.formatMeter(mezzanineWidth);
  const unitPrice = formatters.formatPricePerMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default mezzanineWidthCalculation;
