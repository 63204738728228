import ReactModal from 'react-modal';
import { Labels, useTranslation } from '../../i18n';

type InfoModalProps = {
  isOpen: boolean;
  onClose: () => any;
  className?: string;
  title?: string;
  message?: string;
};

const InfoModal = (props: InfoModalProps) => {
  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className={ props.className }
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner">
        <div className="modal__content">
          <h2 className="modal__title">{ props.title }</h2>
          <div className="modal__text">
            <p>{ props.message }</p>
          </div>

          <CloseButton onClose={ props.onClose } />

          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose }>
        { t(Labels.createProjectModalClose) }
      </button>
    </div>
  );
};

export default InfoModal;
