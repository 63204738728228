import { useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Dropdown, DropdownOption, FullscreenLoader, useErrorBanner } from '../../../../common';
import { formatters, Labels, useTranslation } from '../../../../i18n';
import { IconBox } from '../../../../assets/icons';
import { routes } from '../../../../navigation';
import { Project, ProjectStatus } from '../../../domain/types';
import { useViewModel } from '../../../domain/ProjectDetailViewModel';
import Header from './Header';
import ProjectLayout from '../layout/ProjectLayout';
import styles from './EditProject.module.scss';
import OfferOverview from './OfferOverview';
import { useCompany } from '../../../../company';
import AccordionItem from './Accordion';
import EditProjectButton from './EditProjectButton';

const EditProject = () => {
  const t = useTranslation();
  const { project, offer } = useViewModel();

  if (!project) {
    return (
      <ProjectLayout loading={ true }>
        <FullscreenLoader />
      </ProjectLayout>
    );
  }

  const configuratorPath = generatePath(routes.PROJECT_DETAILS_CONFIGURATOR, { projectId: project.id });

  return (
    <ProjectLayout loading={ false }>

      <Header project={ project } />

      <div className={ styles.content }>
        <Link to={ configuratorPath } className={ styles.build }>
          <IconBox />
          <span>{ t(Labels.editProjectBuildToolCta) }</span>
        </Link>

        <Status project={ project } />

        <div className={ styles.accordionWrapper }>
          <ul>
            <li key={ 'project-info' }><ProjectInfo project={ project } /></li>
            { offer &&
              <OfferOverview
                offer={ offer }
              /> }
          </ul>
        </div>
      </div>
    </ProjectLayout>
  );
};

type StatusProps = {
  project: Project;
};
const Status = ({ project }: StatusProps) => {
  const t = useTranslation();
  const latestUpdated = new Intl.DateTimeFormat().format(project.latestUpdate);
  const [loading, setLoading] = useState(false);
  const viewModel = useViewModel();
  const { setVisible } = useErrorBanner();

  const statusOptions: DropdownOption[] = Object.values(ProjectStatus).map((status) => ({
    text: Labels[`projectStatus_${status}`],
    value: status,
  }));

  const selectedOption = statusOptions.find((option) => option.value === project.status)!;

  const onStatusChange = async (newStatus: ProjectStatus) => {
    setLoading(true);
    try {
      await viewModel.updateStatus(newStatus);
    } catch (error) {
      console.error(error);
      setVisible(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={ styles.updateAndStatus }>
      <div className={ styles.latestUpdate }>
        <span className={ styles.label }>{ t(Labels.editProjectLatestUpdate) }</span>
        <span className={ styles.value }>{ latestUpdated }</span>
      </div>
      <div className={ styles.status }>
        <Dropdown
          identifier='project-status'
          options={ statusOptions }
          selectedOption={ selectedOption }
          label={ t(Labels.editProjectStatus) }
          onChange={ (option) => onStatusChange(option!.value as ProjectStatus) }
          disabled={ loading }
          isSmall={ true }
        />
      </div>
    </div>
  );
};

type ProjectInfoProps = {
  project: Project;
};
const ProjectInfo = ({ project }: ProjectInfoProps) => {
  const t = useTranslation();
  const [, , companies] = useCompany();

  const projectAddress = formatters.formatAddress(project.address);
  const country = Labels['country_' + project.address.country.toLowerCase()];
  const company = companies.find((company) => company.id === project.companyId)!;
  const hasAddress = project.address.municipality != null && project.address.number != null && project.address.postalCode != null && project.address.street != null;

  return (
    <AccordionItem
      identifier='project-info'
      title={ t(Labels.editProjectInfoTitle) }
      header={ <ProjectInfoHeader /> }
    >
      <Label title={ t(Labels.editProjectInfoCompanyName) } value={ company.name } />
      <Label title={ t(Labels.editProjectInfoCompanyAddress) } value={ company.address } />
      {
        hasAddress &&
        <Label title={ t(Labels.editProjectInfoProjectAddress) } value={ projectAddress } />
      }
      <Label title={ t(Labels.editProjectInfoProjectCountry) } value={ country } />
      <Label title={ t(Labels.editProjectInfoProjectVat) } value={ company.companyNumber } />
    </AccordionItem>
  );
};

const ProjectInfoHeader = () => {
  return (
    <div>
      <EditProjectButton />
    </div>
  );
};

type LabelProps = {
  title: string;
  value: string;
};
export const Label = ({ title, value }: LabelProps) => {
  return (
    <li className={ styles.accordionContentItem }>
      <div className={ styles.accordionContent }>
        <span className={ styles.accordionLabel }>{ title }</span>
        <span className={ styles.accordionValue }>{ value }</span>
      </div>
    </li>
  );
};

export default EditProject;
