const COMPANY_FILTER_KEY = 'companyFilter';

const setCompanyFilter = (id: string | null) => {
  if (id) {
    localStorage.setItem(COMPANY_FILTER_KEY, id);
  } else {
    localStorage.removeItem(COMPANY_FILTER_KEY);
  }
};

const getCompanyFilter = (): string | null => {
  return localStorage.getItem(COMPANY_FILTER_KEY);
};

const companyFilterStorage = {
  setCompanyFilter,
  getCompanyFilter,
};

export default companyFilterStorage;
