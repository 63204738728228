import { Country } from '../../../common/domain/types';
import Dropdown, { DropdownOption } from '../../../common/ui/form/Dropdown';
import { Labels } from '../../../i18n';

type CountryDropdownProps = {
  selectedCountry: Country;
  label: string;
  onChange: (selectedCountry: Country) => any;
  disabled: boolean;
};

function CountryDropdown(props: CountryDropdownProps) {

  const countries = Object.values(Country);

  const options: DropdownOption[] = countries.map((country) => ({
    text: Labels[`country_${country.toLowerCase()}`],
    value: country.toString(),
  }));

  const selectedOption = options.find((option) => option.value === props.selectedCountry?.toString());

  return (
    <Dropdown
      identifier='country'
      options={ options }
      label={ props.label }
      onChange={ (option) => props.onChange(option!.value as Country) }
      selectedOption={ selectedOption }
      required={ true }
      disabled={ props.disabled }
    />
  );
}

export default CountryDropdown;
