export type Labels = {
  [key: string]: string;
};

const labels: Labels = {
  country_be: 'Belgium',
  country_nl: 'The Netherlands',
  country_fr: 'France',
  country_lu: 'Luxembourg',

  language_nl_be: 'Dutch (BE)',
  language_nl_nl: 'Dutch (NL)',
  language_fr: 'French',
  language_en: 'English',

  projectStatus_ongoing: 'Ongoing',
  projectStatus_sent: 'Sent',
  projectStatus_rejected: 'Rejected',
  projectStatus_sold: 'Sold',
  projectStatus_postponed: 'Postponed',

  loading: 'Loading...',

  close: 'Close',

  confirmationModalConfirm: 'Confirm',
  confirmationModalCancel: 'Cancel',

  generalError: 'Something went wrong',
  loginTitle: 'Login',
  loginUsernameLabel: 'E-mail address',
  loginPasswordLabel: 'Password',
  loginLoginCta: 'Login',
  loginForgotPasswordCta: 'Forgot your password?',
  loginInvalidCredentials: 'We were unable to verify your credentials. Please check your username and password and try again.',
  forgotPasswordTitle: 'Forgot password?',
  forgotPasswordGoBackCta: 'Return to login',
  forgotPasswordEmailAddressLabel: 'E-mail address',
  forgotPasswordRequestCta: 'Request',
  forgotPasswordRequestedTitle: 'Check your inbox',
  forgotPasswordRequestedMessage: 'In case your account is connected to the e-mail address you gave, you will receive an e-mail with instructions for resetting your password.',
  sidebarDashboardTab: 'Start',
  sidebarProjectsTab: 'Projects',
  sidebarCloseBtn: 'Close',
  sidebar: 'Edit',
  sidebarEditTab: 'Edit',
  sidebarVisualsTab: 'Visuals',
  sidebarShareTab: 'Share',
  userSettingsHi: 'Hi,',
  userSettingsGreeting: '{{name}}',
  userSettingsCloseCta: 'Close',
  userSettingsRequestPasswordCta: 'Change password',
  userSettingsRequestPasswordTitle: 'Request new password',
  userSettingsRequestPasswordBody: 'We will send an email to {{email}} with a link to change your password',
  userSettingsRequestPasswordSubmit: 'Send',
  userSettingsRequestPasswordSuccess: 'An email has been send to {{email}}. Please check your inbox.',
  userSettingsLogoutCta: 'Logout',
  userSettingsLogoutConfirmTitle: 'Are you sure you want to logout?',
  userSettingsLogoutConfirmCta: 'Yes, logout',
  createProjectCta: 'Start a new circle',
  userSettingsBackCta: 'Back to menu',
  companyFilterTitle: 'Select a company',
  companyFilterAllCompanies: 'All companies',
  dashboardChangeCompanyCta: 'Change',
  dashboardSelectedCompany: 'For {{name}}',
  dashboardAllCompanies: 'Displaying all companies',
  createProjectModalTitle: 'Start a new circle',
  createProjectModalClose: 'Close',
  createProjectModalNoCompanies: 'No new circle can be created because there are no companies available.',
  createProjectModalNoContacts: 'Can\'t make a new circle because the selected company does not have any contacts.',
  createProjectModalCompany: 'Client',
  createProjectModalContact: 'Contact',
  createProjectModalName: 'Project name',
  createProjectModalLanguage: 'Project language',
  createProjectModalCountry: 'Project country',
  createProjectModalStreet: 'Street name',
  createProjectModalNumber: 'Number',
  createProjectModalPostalCode: 'Postal code',
  createProjectModalMunicipality: 'Place',
  createProjectModalSubmit: 'Start this new circle',
  companyDetailsName: 'Company name',
  companyDetailsAddress: 'Address',
  companyDetailsVAT: 'VAT',
  companyDetailsChangeCompanyCta: 'Change client',
  companyDetailsNoCompanySelectedTitle: 'All projects',
  projectOverviewEmpty: 'No circles here yet.',
  projectFilterText: 'Show',
  projectFilterAll: 'All',
  projectTileName: 'Project: {{name}}',
  projectTileSurface: 'Surface: {{surface}}',
  projectTileCountry: 'Country project: {{country}}',

  mainCategoryGeneral: 'General',
  mainCategoryWarehouse: 'Warehouse',
  mainCategoryMezzanine: 'Mezzanine',
  mainCategoryOffice: 'Office',
  subCategoryPreparatoryWorks: 'Preparatory works',
  subCategoryGroundworks: 'Groundworks',
  subCategoryFoundations: 'Foundations',
  subCategoryStructure: 'Structure',
  subCategoryWalls: 'Walls',
  subCategoryFloors: 'Floors',
  subCategoryFlatRoofs: 'Flat roofs',
  subCategoryRoofLighting: 'Roof lighting',
  subCategoryRainwaterDrainage: 'Rainwater drainage',
  subCategorySewageSystem: 'Sewage system',
  subCategoryFlooring: 'Flooring',
  subCategoryGatesAndQuayEquipment: 'Gates and quat equipment',
  subCategoryExteriorJoinery: 'Exterior joinery',
  subCategoryStairs: 'Stairs',
  subCategoryTechniques: 'Techniques',
  subCategoryEnvironmentalWorks: 'Environmental works',
  subCategoryInteriorFinish: 'Interior finish',

  editProjectDuplicateCta: 'Duplicate project',
  editProjectDuplicateSuffix: '_copy',
  editProjectDuplicateConfirmTitle: 'Confirm duplication',
  editProjectDuplicateConfirmMessage: 'Are you sure you want to duplicate this project? The new project will be called "{{newName}}".\nYou will be automatically redirected to the new project.',
  editProjectDeleteCta: 'Delete project',
  editProjectDeleteConfirmTitle: 'Confirm delete',
  editProjectDeleteConfirmMessage: 'Are you sure you want to delete "{{projectName}}"?',
  editProjectBuildToolCta: 'Open the build tool',
  editProjectLatestUpdate: 'Latest update',
  editProjectStatus: 'Status',
  editProjectEditDetails: 'Change details',
  editProjectInfoTitle: 'Project',
  editProjectInfoCompanyName: 'Client',
  editProjectInfoCompanyAddress: 'Address',
  editProjectInfoProjectAddress: 'Project address',
  editProjectInfoProjectLanguage: 'Project language',
  editProjectInfoProjectCountry: 'Project country',
  editProjectInfoProjectVat: 'VAT data',

  editProjectOfferGeneralDetails: 'Terrain',
  editProjectOfferGeneralDetailsSurface: 'Terrain surface',
  editProjectOfferGeneralDetailsGreenArea: 'Surface green area',
  editProjectOfferGeneralDetailsConcreteArea: 'Surface hardening terrain',
  editProjectOfferGeneralDetailsRoadArea: 'Surface road area',
  editProjectOfferGeneralDetailsPerimeter: 'Perimeter',
  editProjectOfferWarehouseDetailsFootprint: 'Total warehouse footprint',
  editProjectOfferWarehouseDetailsPerimeter: 'Perimeter',
  editProjectOfferWarehouseDetailsHeight: 'Height',
  editProjectOfferWarehouseDetailsWidth: 'Width',
  editProjectOfferWarehouseDetailsLength: 'Length',
  editProjectOfferWarehouseDetailsMezzanines: 'Surface mezzanines',
  editProjectOfferWarehouseDetailsOffices: 'Surface offices',
  editProjectOfferWarehouseNoMaterials: 'This warehouse does not contain any materials',
  editProjectOfferTotalCost: 'Total cost',
  editProjectOfferAdmin: 'Admin',
  editProjectOfferCommercialDiscount: 'Commercial discount',

  editProjectOfferSectionDetails: 'Details',
  editProjectOfferSectionMaterials: 'Materials',
  editProjectOfferMaterialName: 'Item',
  editProjectOfferMaterialQuantity: 'Amount',
  editProjectOfferMaterialPricePp: 'Price p.p.',
  editProjectOfferMaterialTotal: 'Total',

  editProjectModalContact: 'Contact',
  editProjectModalName: 'Project name',
  editProjectModalLanguage: 'Project language',
  editProjectModalCountry: 'Project country',
  editProjectModalStreet: 'Street name',
  editProjectModalNumber: 'Number',
  editProjectModalPostalCode: 'Postal code',
  editProjectModalMunicipality: 'Place',
  editProjectModalSubmit: 'Update',
  editProjectModalClose: 'Close',
  accordionOpen: 'Open',
  accordionClose: 'Close',
  shareProjectEmpty: 'No offer yet.',
  shareProjectDownloadNLBE: 'Download Dutch (BE)',
  shareProjectDownloadNLNL: 'Download Dutch (NL)',
  shareProjectDownloadFR: 'Download French',
  shareProjectDownloadEN: 'Download English',
  shareProjectSendQuote: 'Send this quote.',
  shareProjectContactEmail: 'Contact e-mail address',
  shareProjectEmailAddressLabel: 'Recipient e-mail addresses',
  shareProjectEmailAddressRemove: 'Remove e-mail address',
  shareProjectAddContact: 'Add contact manually',
  shareProjectMessageLabel: 'Message',
  shareProjectSendButton: 'Send',
  shareProjectConfirmTitle: 'Please confirm',
  shareProjectConfirmMessage: 'Are you sure you want to send this quote?',
  shareProjectSentModal: 'Thank you. The quote has been sent.',
  viewProjectPdf: 'Quote document',
  viewProjectPdfDownload: 'Download PDF',
  projectVisualsAccessibilityTitle: 'Project visuals for {{projectName}}',
  projectVisualsNext: 'Next',
  projectVisualsPrevious: 'Previous',
};

export default labels;
