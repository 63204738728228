import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { FullscreenLoader, usePrevious } from '../../common';
import companyService from '../domain/companyService';
import { Company } from '../domain/types';

type usePersistedCompanyIdValue = [string | null, (companyId: string | null) => void];
type CompanyContextValue = [Company | null, (companyId: string | null) => void, Company[]];

const CompanyContext = createContext<CompanyContextValue>([
  null,
  () => { console.log('initial setCompany'); },
  [],
]);

const usePersistedCompanyId = (): usePersistedCompanyIdValue => {
  const [companyId, setCompanyId] = useState<string | null>(companyService.getCompanyFilter());
  const prevCompanyId = usePrevious(companyId);

  useEffect(() => {
    const storedCompanyId = companyService.getCompanyFilter();
    if (storedCompanyId) {
      setCompanyId(storedCompanyId);
    }
  }, []);

  useEffect(() => {
    if (prevCompanyId !== companyId) {
      companyService.setCompanyFilter(companyId);
    }
  }, [companyId, prevCompanyId]);

  return [companyId, setCompanyId];
};

type CompanyListenerProps = {
  children: ReactNode;
};

// A nice-to-have would be if the selected company can be reflected in the URL, for bookmarking
// This requires quite some effort, since keeping the state, storage and url in sync is quite hard
const CompanyProvider = (props: CompanyListenerProps) => {
  const [companyId, setCompanyId] = usePersistedCompanyId();
  const state = useAsync(companyService.getCompanies, []);
  if (state.loading) {
    return (
      <FullscreenLoader />
    );
  }

  if (state.error) {
    return (
      <div>
      </div>
    );
  }

  const companies = state.value ?? [];
  const selectedCompany = companies.find((company) => companyId === company.id) ?? null;

  return (
    <CompanyContext.Provider value={ [selectedCompany, setCompanyId, companies] }>
      { props.children }
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(CompanyContext);
};


export default CompanyProvider;
