import { PriceList, Prices, ProductPrice } from '../domain/types';
import { PriceListDto, PricesDto, ProductPriceDto } from './types';

const mapPriceList = (dto: PriceListDto): PriceList => {
  return {
    id: dto.id,
    createdAt: new Date(dto.createdAt),
    productPrices: dto.productPrices.map(mapProductPrice),
  };
};

const mapProductPrice = (dto: ProductPriceDto): ProductPrice => {
  return {
    sku: dto.sku,
    title: dto.title || '',
    description: dto.description || '',
    mainCategory: dto.mainCategory,
    subCategory: dto.subCategory,
    unit: dto.unit,
    prices: mapPrices(dto.prices),
  };
};

const mapPrices = (dto: PricesDto): Prices => {
  return {
    price60: parseFloat(dto.price60),
    price100: parseFloat(dto.price100),
    price200: parseFloat(dto.price200),
    price300: parseFloat(dto.price300),
    price400: parseFloat(dto.price400),
    priceOther: parseFloat(dto.priceOther),
  };
};

const mapper = {
  mapPriceList,
};
export default mapper;
