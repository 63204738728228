import { PriceList } from '../domain/types';
import { PricesApi } from './pricesApi';

const mockPriceLists: PriceList[] = [
  {
    id: '0',
    createdAt: new Date(),
    productPrices: [
      {
        sku: 'sku-01',
        title: 'Gyproc',
        description: 'Dé beste Gyproc die je ooit gezien hebt!',
        mainCategory: 'category-1',
        subCategory: 'subcategory-1',
        unit: 'EUR',
        prices: {
          price60: 60,
          price100: 100,
          price200: 200,
          price300: 300,
          price400: 400,
          priceOther: 50,
        },
      },
      {
        sku: 'sku-02',
        title: 'Baksteen',
        description: 'Dé beste baksteen die je ooit gezien hebt!',
        mainCategory: 'category-1',
        subCategory: 'subcategory-2',
        unit: 'EUR',
        prices: {
          price60: 60,
          price100: 100,
          price200: 200,
          price300: 300,
          price400: 400,
          priceOther: 50,
        },
      },
    ],
  },
];

const getMostRecentPriceList = async (): Promise<PriceList> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockPriceLists[0];
};

const getPriceList = async (id: string): Promise<PriceList> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockPriceLists[0];
};

const mockPricesApi: PricesApi = {
  getMostRecentPriceList,
  getPriceList,
};

export default mockPricesApi;
