import { AuthTokens, User } from '../domain/types';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { z } from 'zod';

const TOKENS_KEY = 'authTokens';

const AccessTokenPayloadScheme = z.object({
  firstName: z.string(),
  username: z.string(),
});

type AccessTokenPayload = JwtPayload & z.infer<typeof AccessTokenPayloadScheme>;

const hasValidTokens = () => {
  const tokens = getTokens();
  const accessTokenValid = tokens?.accessToken;
  const refreshTokenValid = tokens?.refreshToken;
  return accessTokenValid && refreshTokenValid;
};

const getAccessToken = (): string | undefined => {
  const tokens = getTokens();
  const token = tokens?.accessToken;
  if (isValidToken(token)) return token;
};

const getRefreshToken = (): string | undefined => {
  const tokens = getTokens();
  return tokens?.refreshToken;
};

const removeTokens = () => clearTokens();

const getUser = (): User | undefined => {
  const tokens = getTokens();
  if (!tokens) return;
  const payload = jwt_decode<AccessTokenPayload>(tokens?.accessToken);
  return {
    firstName: payload.firstName,
    email: payload.username,
  };
};

const clearTokens = () => {
  localStorage.removeItem(TOKENS_KEY);
};

const saveTokens = (authTokens: AuthTokens) => {
  const json = JSON.stringify(authTokens);
  localStorage.setItem(TOKENS_KEY, json);
};

const getTokens = (): AuthTokens | undefined => {
  const json = localStorage.getItem(TOKENS_KEY);
  if (!json) return;
  return JSON.parse(json);
};

const isValidToken = (token?: string): boolean => {
  if (!token) return false;
  const payload = jwt_decode<JwtPayload>(token);
  const expirationUnixTimestamp = payload.exp;
  if (!expirationUnixTimestamp) return false;
  const isValid = (Date.now() / 1000) < expirationUnixTimestamp;
  return isValid;
};

const authStorage = {
  hasTokens: hasValidTokens,
  saveTokens,
  removeTokens,
  getUser,
  getAccessToken,
  getRefreshToken,
};

export default authStorage;
