import { generatePath, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { Labels, useTranslation } from '../../../../i18n';
import { routes } from '../../../../navigation';
import projectService from '../../../domain/projectService';
import { Project } from '../../../domain/types';
import { IconAdd, IconRemove } from '../../../../assets/icons';
import { LoadingIndicator } from '../../../../common';

import styles from './Header.module.scss';
import ConfirmationModal from '../../../../common/ui/ConfirmationModal';
import { useState } from 'react';
import { useErrorBanner } from '../../../../common/ui/ErrorBanner';

type HeaderProps = {
  project: Project;
};
const Header = ({ project }: HeaderProps) => {
  return (
    <div className={ styles.header }>
      <div className={ styles.info }>
        <h1 className={ styles.title }>{ project.name }</h1>
        { project.address.municipality && <span className={ styles.location }>{ project.address.municipality }</span> }
      </div>
      <div className={ styles.actions }>
        <DuplicateButton project={ project } />
        <DeleteButton project={ project } />
      </div>
    </div>
  );
};


type DuplicateButtonProps = {
  project: Project;
};
const DuplicateButton = ({ project }: DuplicateButtonProps) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const errorBanner = useErrorBanner();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const closeModal = () => setConfirmModalOpen(false);
  const showModal = () => setConfirmModalOpen(true);

  const duplicateName = project.name + t(Labels.editProjectDuplicateSuffix);

  const duplicateProject = async () => {
    try {
      const duplicate = await projectService.duplicateProject(project, duplicateName);
      const route = generatePath(routes.PROJECT_DETAILS, { projectId: duplicate.id });
      navigate(route);
    } catch (e) {
      errorBanner.setVisible(true);
    }
  };

  const [state, doFetch] = useAsyncFn(duplicateProject, [project.id]);

  const onConfirmed = async () => {
    setConfirmModalOpen(false);
    await doFetch();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={ confirmModalOpen }
        onClose={ closeModal }
        onConfirm={ onConfirmed }
        title={ t(Labels.editProjectDuplicateConfirmTitle) }
        message={ t(Labels.editProjectDuplicateConfirmMessage, { newName: duplicateName }) }
        className={ 'modal modal--duplicate' }
      />
      {
        state.loading
          ? <LoadingIndicator />
          : <div>
            <button className={ styles.buttonDuplicate } onClick={ showModal } disabled={ state.loading }>
              <IconAdd />
              <span>{ t(Labels.editProjectDuplicateCta) }</span>
            </button>
          </div>
      }
    </>
  );
};


type DeleteButtonProps = {
  project: Project;
};
const DeleteButton = ({ project }: DeleteButtonProps) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const errorBanner = useErrorBanner();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const closeModal = () => setConfirmModalOpen(false);
  const showModal = () => setConfirmModalOpen(true);

  const deleteProject = async () => {
    try {
      await projectService.deleteProject(project.id);
      navigate(routes.PROJECTS);
    } catch (e) {
      errorBanner.setVisible(true);
    }
  };

  const [state, doFetch] = useAsyncFn(deleteProject, [navigate, project.id]);

  const onConfirmed = async () => {
    setConfirmModalOpen(false);
    await doFetch();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={ confirmModalOpen }
        onClose={ closeModal }
        onConfirm={ onConfirmed }
        title={ t(Labels.editProjectDeleteConfirmTitle) }
        message={ t(Labels.editProjectDeleteConfirmMessage, { projectName: project.name }) }
        className={ 'modal modal--delete' }
      />
      {
        state.loading
          ? <LoadingIndicator />
          : <div>
            <button className={ styles.buttonRemove } onClick={ showModal } disabled={ state.loading } data-cy="projectDeleteCta">
              <IconRemove />
              <span>{ t(Labels.editProjectDeleteCta) }</span>
            </button>
          </div>
      }
    </>
  );
};


export default Header;
