import { MainSidebar } from '../../common';
import CompanyDetails from './overview/CompanyDetails';
import ProjectList from './overview/ProjectList';
import styles from './ProjectOverview.module.scss';

const ProjectOverview = () => {
  return (
    <div className={ styles.projects } data-cy="projects">
      <MainSidebar projectsSelected />
      <div className={ styles.responsiveWrapper }>
        <article className={ styles.overview }>
          <ProjectList />
        </article>
        <aside className={ styles.detail }>
          <CompanyDetails />
        </aside>
      </div>
    </div>
  );
};


export default ProjectOverview;
