import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useTranslation, Labels } from '../../i18n';
import CompanyList from './CompanyList';
import { IconChange } from '../../assets/icons';

import styles from './CompanyFilter.module.scss';

type CompanyFilterProps = {
  buttonText: string;
  dashboard?: boolean;
};

const CompanyFilter = (props: CompanyFilterProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  return (
    <div>
      <CompanyFilterModal
        isOpen={ modalVisible }
        onClose={ closeModal }
      />
      <CompanyFilterButton
        text={ props.buttonText }
        onClick={ openModal }
        dashboard={ props.dashboard }
      />
    </div>
  );
};

type CompanyFilterModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CompanyFilterModal = (props: CompanyFilterModalProps) => {
  const t = useTranslation();

  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className="modal modal--change"
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner">
        <div className="modal__content">
          <h2 className="modal__title">{ t(Labels.companyFilterTitle) }</h2>
          <CompanyList
            onSelect={ props.onClose }
          />
          <CloseButton onClose={ props.onClose } />
          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};


type CompanyFilterButtonProps = {
  text: string;
  onClick: () => void;
  dashboard?: boolean;
};

const CompanyFilterButton = (props: CompanyFilterButtonProps) => {
  return (
    <div className={ styles.button } data-on-dashboard={ props.dashboard }>
      <button
        data-cy="companyFilterButton"
        onClick={ props.onClick }
      >
        <span className={ styles.icon }><IconChange /></span>
        <span className={ styles.text }>{ props.text }</span>
      </button>
    </div>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose }>
        { t(Labels.close) }
      </button>
    </div>
  );
};

export default CompanyFilter;

