import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const soilAreaCalculation = (productPrice: ProductPrice, distance: number, siteArea: number): OfferLine | undefined => {
  if (siteArea <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(siteArea);
  const quantity = formatters.formatSquareMeter(siteArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default soilAreaCalculation;
