import { authApi } from '../data/api';
import authStorage from '../data/authStorage';
import { InvalidCredentialsError } from './exceptions';

let subscribers: (() => void)[] = [];

const login = async (username: string, password: string) => {
  try {
    const authTokens = await authApi.requestTokensForCredentials(username, password);
    authStorage.saveTokens(authTokens);
    notifySubscribers();
    return true;
  } catch (e) {
    console.error(e);
    if (e instanceof InvalidCredentialsError) {
      return false;
    }
    throw e;
  }
};

const forgotPassword = async (email: string) => {
  try {
    await authApi.forgotPassword(email);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const requestPasswordReset = async () => {
  try {
    await authApi.requestPasswordReset();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const isLoggedIn = () => {
  return authStorage.hasTokens();
};

const logout = () => {
  console.log('Auth service logout');
  authStorage.removeTokens();
  notifySubscribers();
};

const notifySubscribers = () => {
  subscribers.forEach((subscriber) => subscriber());
};

const subscribe = (callback: () => void): (() => void) => {
  subscribers.push(callback);
  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber !== callback);
  };
};

const getUser = () => authStorage.getUser();

const authService = {
  login,
  isLoggedIn,
  logout,
  forgotPassword,
  requestPasswordReset,
  getUser,
  subscribe,
};

export default authService;
