import { z } from 'zod';
import { Country } from '../domain/types';

export const MoneyDtoScheme = z.object({
  amount: z.number(),
  currency: z.string(),
});
export const MoneyApiDtoScheme = z.object({
  amount: z.string(),
  currency: z.string(),
});

export const CountryDtoScheme = z.nativeEnum(Country);
export type CountryDto = z.infer<typeof CountryDtoScheme>;

export type MoneyDto = z.infer<typeof MoneyDtoScheme>;
export type MoneyApiDto = z.infer<typeof MoneyApiDtoScheme>;
