import React, { useState } from 'react';
import { IconArrow } from '../../../../assets/icons';
import { FullscreenLoader, Slider } from '../../../../common';
import { SwiperSlide } from 'swiper/react';
import { Labels, useTranslation } from '../../../../i18n';

import styles from './ProjectVisuals.module.scss';
import { useViewModel } from '../../../domain/ProjectDetailViewModel';
import ProjectLayout from '../layout/ProjectLayout';

const ProjectVisuals = () => {
  const t = useTranslation();
  const { project } = useViewModel();
  const previousButtonRef = React.useRef(null);
  const nextButtonRef = React.useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!project) {
    return (
      <ProjectLayout loading={ true }>
        <FullscreenLoader />
      </ProjectLayout>
    );
  }

  // If wanted: floor plans can be added here to visualize them
  const images = [...project.visuals, ...project.floorPlans];
  // const images = project.visuals;

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.content }>
        <div className="visually-hidden">{ t(Labels.projectVisualsAccessibilityTitle, { projectName: project.name }) }</div>

        <div className={ styles.visuals }>
          <SliderActions
            currentIndex={ currentIndex }
            maxIndex={ images.length }
            nextButtonRef={ nextButtonRef }
            previousButtonRef={ previousButtonRef }
          />

          <div className={ styles.swiper }>
            <Slider
              slidesPerView={ 1 }
              onIndexChanged={ setCurrentIndex }
              nextButtonRef={ nextButtonRef }
              previousButtonRef={ previousButtonRef }
            >
              { images.map((image: string) =>
                <SwiperSlide key={ image }>
                  <picture>
                    <img src={ image } width={ 984 } height={ 800 } alt="todo" />
                  </picture>
                </SwiperSlide>
              ) }
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};


type SliderActionsProps = {
  currentIndex: number;
  maxIndex: number;
  nextButtonRef?: React.MutableRefObject<any>;
  previousButtonRef?: React.MutableRefObject<any>;
};

const SliderActions = (props: SliderActionsProps) => {
  const t = useTranslation();
  const currentIndex = props.maxIndex === 0 ? 0 : props.currentIndex + 1; // Makes sure that "0/0" is shown when there are no images instead of "1/0"
  const indexLabel = `${currentIndex} / ${props.maxIndex}`;

  return (
    <div className={ styles.actions }>
      <ol>
        <li className={ styles.previous }>
          <button ref={ props.previousButtonRef }>
            <IconArrow />
            <span className="visually-hidden">{ t(Labels.projectVisualsPrevious) }</span>
          </button>
        </li>
        <li className={ styles.amount }>
          <span>{ indexLabel }</span>
        </li>
        <li className={ styles.next }>
          <button ref={ props.nextButtonRef }>
            <IconArrow />
            <span className="visually-hidden">{ t(Labels.projectVisualsNext) }</span>
          </button>
        </li>
      </ol>
    </div>
  );
};

export default ProjectVisuals;
