import { BigNumber } from 'bignumber.js';
import offerMapper from './offerMapper';
import { PriceList } from '../../../prices';
import { Labels, formatters } from '../../../i18n';
import { Configuration } from '../../configurator/domain/types';
import { MainCategory, SubCategory, CalculationType, ProductPrice } from './../../../prices/domain/types';
import { GeneralMeasurements, GeneralPricingInfo, WarehousePricingInfo, Mezzanine, Office, WarehouseMeasurements, Material } from './../../configurator/domain/types';
import { Offer, OfferLine, WarehouseCategory, OfferSection, Money, GeneralCategory, GeneralDetails, WarehouseDetails, OfferPricing } from './types';
import totalAreaCalculation from './calculations/general/totalAreaCalculation';
import concreteFloorOutsideAreaCalculation from './calculations/general/concreteFloorOutsideAreaCalculation';
import fixedPriceCalculation from './calculations/general/fixedPriceCalculation';
import greenAreaCalculation from './calculations/general/greenAreaCalculation';
import quantityCalculation from './calculations/general/quantityCalculation';
import roadAreaCalculation from './calculations/general/roadAreaCalculation';
import soilAreaCalculation from './calculations/general/soilAreaCalculation';
import totalPerimeterCalculation from './calculations/general/totalPerimeterCalculation';
import mezzanineAreaCalculation from './calculations/mezzanine/mezzanineAreaCalculation';
import mezzanineWidthCalculation from './calculations/mezzanine/mezzanineWidthCalculation';
import officeGroundFloorAreaCalculation from './calculations/office/officeGroundFloorAreaCalculation';
import totalOfficeAreaCalculation from './calculations/office/totalOfficeAreaCalculation';
import firewallWidthCalculation from './calculations/warehouse/firewallWidthCalculation';
import perWarehouseCalculation from './calculations/warehouse/perWarehouseCalculation';
import warehouseAreaCalculation from './calculations/warehouse/warehouseAreaCalculation';
import warehouseConcreteFloorAreaCalculation from './calculations/warehouse/warehouseConcreteFloorAreaCalculation';
import warehouseHeightCalculation from './calculations/warehouse/warehouseHeightCalculation';
import warehousePerimeterCalculation from './calculations/warehouse/warehousePerimeterCalculation';
import warehouseTechniquesAreaCalculation from './calculations/warehouse/warehouseTechniquesAreaCalculation';
import volumeCalculation from './calculations/general/volumeCalculation';
import lengthMeterCalculation from './calculations/general/lengthMeterCalculation';

const createOffer = (configuration: Configuration, priceList: PriceList, distance: number, discount: Money): Offer => {
  const generalCategory: GeneralCategory = createGeneralCategory(configuration.pricingInfo.general, priceList, distance);
  const warehouseCategories: WarehouseCategory[] = configuration.pricingInfo.warehouses.map((warehouse) => createWarehouseCategory(warehouse, priceList, distance));
  const offerPricing: OfferPricing = createOfferPricing(generalCategory, warehouseCategories, discount);

  const offer = offerMapper.constructOffer(generalCategory, warehouseCategories, offerPricing);
  return offer;
};

const createOfferPricing = (generalCategory: GeneralCategory, warehouseCategories: WarehouseCategory[], discount: Money): OfferPricing => {
  const subPrice = calculateSubPrice(generalCategory, warehouseCategories);
  const totalPrice = calculateTotalPrice(subPrice, discount);

  return offerMapper.constructOfferPricing(subPrice, discount, totalPrice);
};

const calculateSubPrice = (generalCategory: GeneralCategory, warehouseCategories: WarehouseCategory[]): Money => {
  let subPrice = BigNumber(generalCategory.totalPrice.amount);
  warehouseCategories.forEach((warehouse) => {
    subPrice = subPrice.plus(warehouse.totalPrice.amount);
  });

  return offerMapper.mapBigNumberToMoney(subPrice);
};

const calculateTotalPrice = (subPrice: Money, discount: Money): Money => {
  const totalPrice = BigNumber(subPrice.amount).minus(BigNumber(discount.amount));
  return offerMapper.mapBigNumberToMoney(totalPrice);
};

// General
const createGeneralCategory = (generalConfiguration: GeneralPricingInfo, priceList: PriceList, distance: number): GeneralCategory => {
  const preparatoryWorksSection = createGeneralPreparatoryWorksSection(generalConfiguration, priceList, distance);
  const groundworksSection = createGeneralGroundworksSection(generalConfiguration, priceList, distance);
  const environmentWorksSection = createGeneralEnvironmentWorksSection(generalConfiguration, priceList, distance);
  const details = createGeneralDetails(generalConfiguration.measurements);

  const totalPrice = [preparatoryWorksSection, groundworksSection, environmentWorksSection]
    .map((section) => section.totalPrice.amount)
    .reduce((sub, cur) => sub.plus(cur), BigNumber(0));

  return offerMapper.constructGeneralCategory(
    offerMapper.mapBigNumberToMoney(totalPrice),
    preparatoryWorksSection,
    groundworksSection,
    environmentWorksSection,
    details
  );
};

const createGeneralDetails = (measurements: GeneralMeasurements): GeneralDetails => {
  return {
    areaSite: formatters.formatSquareMeter(measurements.areaSite),
    perimeterSite: formatters.formatMeter(measurements.perimeterSite),
    areaGreen: formatters.formatSquareMeter(measurements.areaGreen),
    areaConcreteExterior: formatters.formatSquareMeter(measurements.areaConcreteExterior),
    areaRoad: formatters.formatSquareMeter(measurements.areaRoad),
  };
};

const createGeneralPreparatoryWorksSection = (generalConfiguration: GeneralPricingInfo, priceList: PriceList, distance: number): OfferSection => {
  const offerLines: OfferLine[] = [];

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.General)
    .filter((productPrice) => productPrice.subCategory === SubCategory.PreparatoryWorks);

  relevantProductPrices.forEach((productPrice) => {
    let offerLine: OfferLine | undefined;

    switch (productPrice.unit) {
      case CalculationType.FIXED_PRICE:
        offerLine = fixedPriceCalculation(productPrice, distance);
        break;
    }

    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(Labels.subCategoryPreparatoryWorks, offerLines, totalPrice);
};

const createGeneralGroundworksSection = (generalConfiguration: GeneralPricingInfo, priceList: PriceList, distance: number): OfferSection => {
  const offerLines: OfferLine[] = [];

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.General)
    .filter((productPrice) => productPrice.subCategory === SubCategory.Groundworks);

  relevantProductPrices.forEach((productPrice) => {
    let offerLine: OfferLine | undefined;

    switch (productPrice.unit) {
      case CalculationType.TOTAL_AREA:
        if (productPrice.sku === '00000-00000-02050500000000000000-228') { // Verwijderen van plantengroei
          const material: Material | undefined = generalConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) offerLine = totalAreaCalculation(productPrice, distance, material.amount);
        } else {
          offerLine = totalAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaSite);
        }
        break;
      case CalculationType.CONCRETE_FLOOR_OUTSIDE_AREA:
        offerLine = concreteFloorOutsideAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaConcreteExterior);
        break;
      case CalculationType.GREEN_AREA:
        offerLine = greenAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaGreen);
        break;
      case CalculationType.ROAD_AREA:
        offerLine = roadAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaRoad);
        break;
      case CalculationType.SOIL_AREA: {
        const material: Material | undefined = generalConfiguration.materials.find((material) => material.sku === productPrice.sku);
        if (material) offerLine = soilAreaCalculation(productPrice, distance, material.amount);
        break;
      }
      case CalculationType.VOLUME: {
        const material: Material | undefined = generalConfiguration.materials.find((material) => material.sku === productPrice.sku);
        if (material) offerLine = volumeCalculation(productPrice, distance, material.amount);
        break;
      }
    }

    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(Labels.subCategoryGroundworks, offerLines, totalPrice);
};

const createGeneralEnvironmentWorksSection = (generalConfiguration: GeneralPricingInfo, priceList: PriceList, distance: number): OfferSection => {
  const offerLines: OfferLine[] = [];

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.General)
    .filter((productPrice) => productPrice.subCategory === SubCategory.EnvironmentalWorks);

  relevantProductPrices.forEach((productPrice) => {
    let offerLine: OfferLine | undefined;

    switch (productPrice.unit) {
      case CalculationType.CONCRETE_FLOOR_OUTSIDE_AREA:
        offerLine = concreteFloorOutsideAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaConcreteExterior);
        break;
      case CalculationType.GREEN_AREA:
        offerLine = greenAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaGreen);
        break;
      case CalculationType.ROAD_AREA:
        offerLine = roadAreaCalculation(productPrice, distance, generalConfiguration.measurements.areaRoad);
        break;
      case CalculationType.TOTAL_PERIMETER:
        offerLine = totalPerimeterCalculation(productPrice, distance, generalConfiguration.measurements.perimeterSite);
        break;
      case CalculationType.FIXED_PRICE:
        offerLine = fixedPriceCalculation(productPrice, distance);
        break;
    }
    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(Labels.subCategoryEnvironmentalWorks, offerLines, totalPrice);
};

// Warehouse
const createWarehouseCategory = (warehousePricing: WarehousePricingInfo, priceList: PriceList, distance: number): WarehouseCategory => {
  const preparatoryWorksSection = createWarehousePreparatoryWorksSection(warehousePricing, priceList, distance);
  const groundworksSection = createWarehouseGroundworksSection(warehousePricing, priceList, distance);
  const foundationSection = createWarehouseFoundationSection(warehousePricing, priceList, distance);
  const structureSection = createWarehouseStructureSection(warehousePricing, priceList, distance);
  const wallsSection = createWarehouseWallsSection(warehousePricing, priceList, distance);
  const flatRoofsSection = createWarehouseFlatRoofsSection(warehousePricing, priceList, distance);
  const roofLightingSection = createWarehouseRoofLightingSection(warehousePricing, priceList, distance);
  const rainwaterDrainageSection = createWarehouseRainwaterDrainageSection(warehousePricing, priceList, distance);
  const sewageSystemSection = createWarehouseSewageSystemSection(warehousePricing, priceList, distance);
  const flooringSection = createWarehouseFlooringSection(warehousePricing, priceList, distance);
  const gatesAndQuayEquipmentSection = createWarehouseGatesAndQuayEquipmentSection(warehousePricing, priceList, distance);
  const exteriorJoinerySection = createWarehouseExteriorJoinerySection(warehousePricing, priceList, distance);
  const techniquesSection = createWarehouseTechniquesSection(warehousePricing, priceList, distance);
  const environmentalWorksSection = createWarehouseEnvironmentalWorksSection(warehousePricing, priceList, distance);

  const mezzaninesSection = createWarehouseMezzaninesSection(warehousePricing, priceList, distance);
  const officesSection = createWarehouseOfficesSection(warehousePricing, priceList, distance);
  const details = createWarehouseDetails(warehousePricing.measurements, warehousePricing.mezzanines, warehousePricing.offices);

  const totalPrice = [
    preparatoryWorksSection,
    groundworksSection,
    foundationSection,
    structureSection,
    wallsSection,
    flatRoofsSection,
    roofLightingSection,
    rainwaterDrainageSection,
    sewageSystemSection,
    flooringSection,
    gatesAndQuayEquipmentSection,
    exteriorJoinerySection,
    techniquesSection,
    environmentalWorksSection,
    mezzaninesSection,
    officesSection,
  ]
    .map((section) => section.totalPrice.amount)
    .reduce((sub, cur) => sub.plus(cur), BigNumber(0));

  return offerMapper.constructWarehouseCategory(
    warehousePricing.name,
    offerMapper.mapBigNumberToMoney(totalPrice),
    preparatoryWorksSection,
    groundworksSection,
    foundationSection,
    structureSection,
    wallsSection,
    flatRoofsSection,
    roofLightingSection,
    rainwaterDrainageSection,
    sewageSystemSection,
    flooringSection,
    gatesAndQuayEquipmentSection,
    exteriorJoinerySection,
    techniquesSection,
    environmentalWorksSection,

    mezzaninesSection,
    officesSection,
    details
  );
};

const createWarehouseDetails = (measurements: WarehouseMeasurements, mezzanines: Mezzanine[], offices: Office[]): WarehouseDetails => {
  const totalMezzanineArea = mezzanines.map((mez) => mez.area).reduce((sub, cur) => sub + cur, 0);
  const totalOfficeArea = offices.map((off) => off.area).reduce((sub, cur) => sub + cur, 0);

  return {
    area: formatters.formatSquareMeter(measurements.area),
    perimeter: formatters.formatMeter(measurements.perimeter),
    height: formatters.formatMeter(measurements.height),
    width: formatters.formatMeter(measurements.width),
    length: formatters.formatMeter(measurements.length),
    totalMezzanineArea: formatters.formatSquareMeter(totalMezzanineArea),
    totalOfficeArea: formatters.formatSquareMeter(totalOfficeArea),
  };
};

type WarehouseCalculationTypeSwitchFunction = (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => OfferLine | undefined;
const createWarehouseOfferSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number, subCategory: SubCategory, sectionTitle: string, switchFunction: WarehouseCalculationTypeSwitchFunction): OfferSection => {
  const offerLines: OfferLine[] = [];

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.Warehouse)
    .filter((productPrice) => productPrice.subCategory === subCategory);

  relevantProductPrices.forEach((productPrice) => {
    const offerLine: OfferLine | undefined = switchFunction(productPrice, warehouseConfiguration, distance);
    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(sectionTitle, offerLines, totalPrice);
};

const createWarehousePreparatoryWorksSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number) => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.PreparatoryWorks,
    Labels.subCategoryPreparatoryWorks,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
        case CalculationType.WAREHOUSE_PERIMETER:
          return warehousePerimeterCalculation(productPrice, distance, warehouseConfiguration.measurements.perimeter);
      }
    }
  );
};

const createWarehouseGroundworksSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Groundworks,
    Labels.subCategoryGroundworks,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
      }
    }
  );
};

const createWarehouseFoundationSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Foundations,
    Labels.subCategoryFoundations,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.QUANTITY: {
          const foundationOptions = ['00000-00000-03050505050000000000-007', '00000-00000-03050505050000000000-009'];
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) {
            if (foundationOptions.includes(material.sku)) {
              if (warehouseConfiguration.foundation === material.sku) { // If the material is related to the foundation options, only include it if selected
                return quantityCalculation(productPrice, distance, material.amount);
              }
            } else { // Material is not related to foundation options
              return quantityCalculation(productPrice, distance, material.amount);
            }
          }
        }
      }
    }
  );
};

const createWarehouseStructureSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Structure,
    Labels.subCategoryStructure,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
        case CalculationType.FIREWALL_WIDTH:
          if (warehouseConfiguration.measurements.lenghtFireWall) return firewallWidthCalculation(productPrice, distance, warehouseConfiguration.measurements.lenghtFireWall);
          break;
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseWallsSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Walls,
    Labels.subCategoryWalls,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_HEIGHT: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return warehouseHeightCalculation(productPrice, distance, warehouseConfiguration.measurements.height, material.amount);
          break;
        }
        case CalculationType.WAREHOUSE_PERIMETER:
          return warehousePerimeterCalculation(productPrice, distance, warehouseConfiguration.measurements.perimeter);
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);

          if (productPrice.sku === '00000-00000-06101035150000000000-170') { // Verticaal voegprofiel
            if (material) return lengthMeterCalculation(productPrice, distance, material.amount);
          } else {
            if (material) return quantityCalculation(productPrice, distance, material.amount);
          }
        }
      }
    }
  );
};

const createWarehouseFlatRoofsSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.FlatRoofs,
    Labels.subCategoryFlatRoofs,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
        case CalculationType.WAREHOUSE_PERIMETER:
          return warehousePerimeterCalculation(productPrice, distance, warehouseConfiguration.measurements.perimeter);
      }
    }
  );
};

const createWarehouseRoofLightingSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.RoofLighting,
    Labels.subCategoryRoofLighting,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseRainwaterDrainageSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.RainwaterDrainage,
    Labels.subCategoryRainwaterDrainage,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseSewageSystemSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.SewageSystem,
    Labels.subCategorySewageSystem,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_AREA:
          return warehouseAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
      }
    }
  );
};

const createWarehouseFlooringSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Flooring,
    Labels.subCategoryFlooring,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_CONCRETE_FLOOR_AREA:
          if (warehouseConfiguration.concreteFloor === productPrice.sku) return warehouseConcreteFloorAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
      }
    }
  );
};

const createWarehouseGatesAndQuayEquipmentSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.GatesAndQuayEquipment,
    Labels.subCategoryGatesAndQuayEquipment,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseExteriorJoinerySection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.ExteriorJoinery,
    Labels.subCategoryExteriorJoinery,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseTechniquesSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.Techniques,
    Labels.subCategoryTechniques,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.WAREHOUSE_TECHNIQUES_AREA:
          if (warehouseConfiguration.techniques === productPrice.sku) return warehouseTechniquesAreaCalculation(productPrice, distance, warehouseConfiguration.measurements.area);
      }
    }
  );
};

const createWarehouseEnvironmentalWorksSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  return createWarehouseOfferSection(
    warehouseConfiguration,
    priceList,
    distance,
    SubCategory.EnvironmentalWorks,
    Labels.subCategoryEnvironmentalWorks,
    (productPrice: ProductPrice, warehouseConfiguration: WarehousePricingInfo, distance: number) => {
      switch (productPrice.unit) {
        case CalculationType.PER_WAREHOUSE:
          return perWarehouseCalculation(productPrice, distance);
        case CalculationType.QUANTITY: {
          const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
          if (material) return quantityCalculation(productPrice, distance, material.amount);
        }
      }
    }
  );
};

const createWarehouseMezzaninesSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  const offerLines: OfferLine[] = [];

  const totalMezzanineAreas = warehouseConfiguration.mezzanines
    .map((mezzanine) => mezzanine.area)
    .reduce((sub, cur) => sub + cur, 0);

  const totalMezzanineWidths = warehouseConfiguration.mezzanines
    .map((mezzanine) => mezzanine.width)
    .reduce((sub, cur) => sub + cur, 0);

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.Mezzanine);

  relevantProductPrices.forEach((productPrice) => {
    let offerLine: OfferLine | undefined;

    switch (productPrice.unit) {
      case CalculationType.MEZZANINE_AREA:
        offerLine = mezzanineAreaCalculation(productPrice, distance, totalMezzanineAreas);
        break;
      case CalculationType.MEZZANINE_WIDTH:
        offerLine = mezzanineWidthCalculation(productPrice, distance, totalMezzanineWidths);
        break;
      case CalculationType.QUANTITY: {
        const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
        if (material) offerLine = quantityCalculation(productPrice, distance, material.amount);
        break;
      }
    }
    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(Labels.mainCategoryMezzanine, offerLines, totalPrice);
};

const createWarehouseOfficesSection = (warehouseConfiguration: WarehousePricingInfo, priceList: PriceList, distance: number): OfferSection => {
  const offerLines: OfferLine[] = [];

  const totalOfficeAreas = warehouseConfiguration.offices
    .map((office) => office.area)
    .reduce((sub, cur) => sub + cur, 0);

  const totalGroundFloorOfficeAreas = warehouseConfiguration.offices
    .filter((office) => office.groundFloor)
    .map((office) => office.area)
    .reduce((sub, cur) => sub + cur, 0);

  const relevantProductPrices = priceList.productPrices
    .filter((productPrice) => productPrice.mainCategory === MainCategory.Office);

  relevantProductPrices.forEach((productPrice) => {
    let offerLine: OfferLine | undefined;

    switch (productPrice.unit) {
      case CalculationType.TOTAL_OFFICE_AREA:
        offerLine = totalOfficeAreaCalculation(productPrice, distance, totalOfficeAreas);
        break;
      case CalculationType.OFFICE_GROUND_FLOOR_AREA:
        offerLine = officeGroundFloorAreaCalculation(productPrice, distance, totalGroundFloorOfficeAreas);
        break;
      case CalculationType.QUANTITY: {
        const material: Material | undefined = warehouseConfiguration.materials.find((material) => material.sku === productPrice.sku);
        if (material) offerLine = quantityCalculation(productPrice, distance, material.amount);
        break;
      }
    }
    if (offerLine) offerLines.push(offerLine);
  });

  const totalPrice = offerLines.map((line) => BigNumber(line.totalPrice.amount)).reduce((sub, cur) => sub.plus(cur), BigNumber(0));
  return offerMapper.constructOfferSection(Labels.mainCategoryOffice, offerLines, totalPrice);
};

const offerService = {
  createOffer,
};

export default offerService;
