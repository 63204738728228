import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const warehouseAreaCalculation = (productPrice: ProductPrice, distance: number, warehouseArea: number): OfferLine | undefined => {
  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(warehouseArea);
  const quantity = formatters.formatSquareMeter(warehouseArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default warehouseAreaCalculation;
