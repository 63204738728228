import { FormEvent, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { LoadingIndicator } from '../../../common';
import { Address, Country } from '../../../common/domain/types';
import { TextField } from '../../../common/ui/form/TextField';
import { useCompany } from '../../../company';
import { Company, CompanyContact } from '../../../company/domain/types';
import { Labels, useTranslation } from '../../../i18n';
import { routes } from '../../../navigation';
import projectService from '../../domain/projectService';
import { ProjectLanguage } from '../../domain/types';
import classNames from 'classnames';
import CountryDropdown from '../form/CountryDropdown';
import LanguageDropdown from '../form/LanguageDropdown';
import ContactDropdown from '../form/ContactDropdown';
import CompanyDropdown from '../form/CompanyDropdown';


const emptyAddress: Address = {
  country: Country.be,
  street: null,
  number: null,
  postalCode: '',
  municipality: '',
};

function CreateProjectForm() {
  const t = useTranslation();
  const [selectedCompany, , companies] = useCompany();

  if (selectedCompany == null && companies.length === 0) {
    return <p>{ t(Labels.createProjectModalNoCompanies) }</p>;
  }

  const initialCompany = selectedCompany ?? companies[0];


  return (
    <FormContent
      initialCompany={ initialCompany }
      companies={ companies }
    />
  );
}


type FormContentProps = {
  initialCompany: Company;
  companies: Company[];
};
const FormContent = ({ initialCompany, companies }: FormContentProps) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const initialContact = initialCompany?.companyContacts.length > 0
    ? initialCompany?.companyContacts[0]
    : null;

  const [company, setCompany] = useState(initialCompany);
  const [contact, setContact] = useState<CompanyContact | null>(initialContact);
  const [projectName, setProjectName] = useState('');
  const [language, setLanguage] = useState<ProjectLanguage>(ProjectLanguage.nl_be);
  const [address, setAddress] = useState<Address>(emptyAddress);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const project = await doFetch();
    const path = generatePath(routes.PROJECT_DETAILS, { projectId: project.id });
    navigate(path);
  };

  const createProject = () => {
    return projectService.createProject(company, contact!, projectName, language, address);
  };

  const [state, doFetch] = useAsyncFn(createProject, [address, company, contact, language, projectName]);

  const changeCompany = (company: Company) => {
    const contact = company.companyContacts.length > 0
      ? company.companyContacts[0]
      : null;
    setCompany(company);
    setContact(contact);
  };

  const hasContacts = company.companyContacts.length > 0 && contact != null;
  const buttonClasses = classNames('form-submit', { 'form-submit--loader': state.loading });

  return (
    <form onSubmit={ onSubmit } data-cy="createProjectForm">

      <div className="form-item">
        <CompanyDropdown
          selectedCompany={ company }
          companies={ companies }
          onChange={ changeCompany }
          disabled={ state.loading }
        />
      </div>

      {
        !hasContacts
          ? <p>{ t(Labels.createProjectModalNoContacts) }</p>
          : <>
            <div className="form-item">
              <ContactDropdown
                selectedContact={ contact }
                label={ t(Labels.createProjectModalContact) }
                contacts={ company.companyContacts }
                onChange={ setContact }
                disabled={ state.loading }
              />
            </div>

            <div className="form-item">
              <TextField
                identifier='name'
                value={ projectName }
                label={ t(Labels.createProjectModalName) }
                onChange={ setProjectName }
                required={ true }
                disabled={ state.loading }
              />
            </div>

            <div className="form-item">
              <LanguageDropdown
                selectedLanguage={ language }
                label={ t(Labels.createProjectModalLanguage) }
                onChange={ setLanguage }
                disabled={ state.loading }
              />
            </div>

            <div className="form-item">
              <CountryDropdown
                selectedCountry={ address.country }
                label={ t(Labels.createProjectModalCountry) }
                onChange={ (country) => setAddress({ ...address, country }) }
                disabled={ state.loading }
              />
            </div>

            <div className="form-item form-item--double">
              <TextField
                identifier='street'
                value={ address.street ?? '' }
                label={ t(Labels.createProjectModalStreet) }
                onChange={ (street) => setAddress({ ...address, street: street.trim() === '' ? null : street }) }
                disabled={ state.loading }
              />

              <TextField
                identifier='number'
                value={ address.number ?? '' }
                label={ t(Labels.createProjectModalNumber) }
                onChange={ (number) => setAddress({ ...address, number: number.trim() === '' ? null : number }) }
                disabled={ state.loading }
              />
            </div>

            <div className="form-item form-item--double">
              <TextField
                identifier='postal-code'
                value={ address.postalCode ?? '' }
                label={ t(Labels.createProjectModalPostalCode) }
                onChange={ (postalCode) => setAddress({ ...address, postalCode: postalCode.trim() }) }
                required={ true }
                disabled={ state.loading }
              />

              <TextField
                identifier='municipality'
                value={ address.municipality ?? '' }
                label={ t(Labels.createProjectModalMunicipality) }
                onChange={ (municipality) => setAddress({ ...address, municipality: municipality.trim() }) }
                required={ true }
                disabled={ state.loading }
              />
            </div>

            <div className="form-actions">
              <button className={ buttonClasses } type="submit">
                <span>{ t(Labels.createProjectModalSubmit) }</span>
                { state.loading && <LoadingIndicator /> }
              </button>
            </div>
          </>
      }
    </form>
  );
};


export default CreateProjectForm;
