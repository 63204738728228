import { RequireAuth } from '../../auth';
import { CompanyProvider } from '../../company';

const AuthenticatedRoute = ({ children }: { children: JSX.Element; }) => {
  return (
    <RequireAuth>
      <CompanyProvider>
        { children }
      </CompanyProvider>
    </RequireAuth>
  );
};

export default AuthenticatedRoute;
