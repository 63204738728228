import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const fixedPriceCalculation = (productPrice: ProductPrice, distance: number): OfferLine => {
  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice;
  const quantity = formatters.formatPiece(1);
  const unitPrice = formatters.formatPricePerPiece(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default fixedPriceCalculation;
