import { FormEvent, useEffect, useState } from 'react';
import { IconAdd, IconChecked, IconClose, IconDownload } from '../../../../assets/icons';
import { FullscreenLoader, TextField, TextArea, LoadingIndicator } from '../../../../common';
import { Labels, useTranslation } from '../../../../i18n';
import { useViewModel } from '../../../domain/ProjectDetailViewModel';
import ProjectLayout from '../layout/ProjectLayout';
import { Email } from '../../../domain/types';
import projectService from '../../../domain/projectService';
import InfoModal from '../../../../common/ui/InfoModal';

import styles from './ShareOffer.module.scss';
import ConfirmationModal from '../../../../common/ui/ConfirmationModal';

const ShareOffer = () => {
  const t = useTranslation();
  const { project } = useViewModel();

  const [emailAddresses, setEmailAddresses] = useState<Email[]>([]);
  const [message, setMessage] = useState('');

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const askConfirmation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const mailOffer = () => {
    setIsConfirmModalOpen(false);
    setIsInfoModalOpen(true);
    projectService.mailOffer(project.offer.id, emailAddresses, message);
  };

  useEffect(() => {
    if (project) {
      setEmailAddresses(project.mailSettings.emailAddresses);
      setMessage(project.mailSettings.message);
    }
  }, [project]);

  if (!project) {
    return (
      <ProjectLayout loading={ true }>
        <FullscreenLoader />
      </ProjectLayout>
    );
  }

  if (!project.offer) {
    return (
      <ProjectLayout loading={ true }>
        <div className={ styles.empty }>{ t(Labels.shareProjectEmpty) }</div>
      </ProjectLayout>
    );
  }

  return (
    <ProjectLayout loading={ false }>
      <div>
        <div>
          <h1 className={ styles.title }>{ project.name }</h1>
          { project.address.municipality && <span className={ styles.location }>{ project.address.municipality }</span> }
        </div>
      </div>

      <div className={ styles.content }>
        <OfferPdfButtons />
      </div>

      <div className={ styles.shareForm }>
        <h2 className="form-title">{ t(Labels.shareProjectSendQuote) }</h2>

        <form onSubmit={ askConfirmation }>
          <TextField
            identifier={ 'contactEmail' }
            value={ project.contact.email }
            type={ 'email' }
            label={ t(Labels.shareProjectContactEmail) }
            disabled={ true }
          />

          <EmailAddresses
            addresses={ emailAddresses }
            setEmailAddresses={ setEmailAddresses }
          />

          <div className="form-item">
            <TextArea
              identifier={ 'message' }
              label={ t(Labels.shareProjectMessageLabel) }
              required={ true }
              value={ message } // TODO wordt message nog HTML? Wat met breaklines in de tekst? Project naam invullen?
              onChange={ setMessage }
            />
          </div>

          <div className="form-actions">
            <button className="form-submit form-submit--check" type={ 'submit' }>
              <span className="form-submit__icon"><IconChecked /></span>
              <span className="form-submit__text">{ t(Labels.shareProjectSendButton) }</span>
            </button>
          </div>
        </form>
        <ConfirmationModal
          isOpen={ isConfirmModalOpen }
          title={ t(Labels.shareProjectConfirmTitle) }
          message={ t(Labels.shareProjectConfirmMessage) }
          onClose={ () => setIsConfirmModalOpen(false) }
          onConfirm={ mailOffer }
          className={ 'modal' }
        />
        <InfoModal
          isOpen={ isInfoModalOpen }
          onClose={ () => setIsInfoModalOpen(false) }
          title={ t(Labels.shareProjectSentModal) }
          className={ 'modal' }
        />
      </div>
    </ProjectLayout>
  );
};

const OfferPdfButtons = () => {
  const t = useTranslation();
  const { project } = useViewModel();

  const viewNlBePdfPath = project.offer.pdfs.nl_be;
  const viewNlNlPdfPath = project.offer.pdfs.nl_nl;
  const viewEnPdfPath = project.offer.pdfs.en;
  const viewFrPdfPath = project.offer.pdfs.fr;

  return (
    <div>
      <ul className={ styles.downloadList }>
        <OfferPdfButton key={ viewNlBePdfPath } path={ viewNlBePdfPath } label={ t(Labels.shareProjectDownloadNLBE) } />
        <OfferPdfButton key={ viewNlNlPdfPath } path={ viewNlNlPdfPath } label={ t(Labels.shareProjectDownloadNLNL) } />
        <OfferPdfButton key={ viewEnPdfPath } path={ viewEnPdfPath } label={ t(Labels.shareProjectDownloadEN) } />
        <OfferPdfButton key={ viewFrPdfPath } path={ viewFrPdfPath } label={ t(Labels.shareProjectDownloadFR) } />
      </ul>
    </div>
  );
};

interface OfferPdfButtonProps {
  path: string;
  label: string;
}
const OfferPdfButton = ({ path, label }: OfferPdfButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadOffer = async () => {
    setIsDownloading(true);
    await projectService.downloadOfferPdf(path);
    setIsDownloading(false);
  };

  return (
    <li>
      <button onClick={ downloadOffer } disabled={ isDownloading }>
        {/* <Link to={ path }> */ } { /* Wordt mogelijks later nog veranderd van button naar Link, mag blijven staan */ }

        { isDownloading
          ? <LoadingIndicator />
          : <>
            <IconDownload />
            <span>{ label }</span>
          </> }
        {/* </Link> */ }
      </button>
    </li>
  );
};

type EmailAddressesProps = {
  addresses: Email[];
  setEmailAddresses: (addresses: Email[]) => void;
};
const EmailAddresses = ({ addresses, setEmailAddresses }: EmailAddressesProps) => {
  const t = useTranslation();

  const addAddress = () => {
    setEmailAddresses([...addresses, { email: '' }]);
  };

  const updateAddress = (index: number, updatedAddress: string) => {
    const updatedAddresses = [...addresses];
    const currenctAddressState = addresses.at(index);
    updatedAddresses[index] = { ...currenctAddressState, email: updatedAddress };
    setEmailAddresses(updatedAddresses);
  };

  const removeAddress = (index: number) => {
    const newAddresses = [...addresses];
    newAddresses.splice(index, 1);
    setEmailAddresses(newAddresses);
  };

  return (
    <div className="form-item form-item--share">
      <label htmlFor="mail">{ t(Labels.shareProjectEmailAddressLabel) }</label>
      { addresses.map((address, index) => (
        <div className="form-item__inner" key={ index }>
          <TextField
            identifier={ `emailAddress${index}` }
            required={ true }
            value={ address.email }
            type={ 'email' }
            onChange={ (value) => updateAddress(index, value) }
          />
          <button onClick={ () => removeAddress(index) }>
            <IconClose />
            <span className="visually-hidden">{ t(Labels.shareProjectEmailAddressRemove) }</span>
          </button>
        </div>
      )) }
      <div className={ styles.buttons }>
        <button className={ styles.buttonAdd } onClick={ addAddress }>
          <IconAdd />
          <span>{ t(Labels.shareProjectAddContact) }</span>
        </button>
      </div>
    </div>
  );
};

export default ShareOffer;
