import { z } from 'zod';
import { CountryDtoScheme, MoneyApiDtoScheme, MoneyDtoScheme } from '../../common/data/types';
import { CompanyContactDtoScheme } from '../../company/data/api/types';
import { ProjectLanguage, ProjectStatus } from '../domain/types';

// Submodels project
export const ProjectStatusDtoScheme = z.nativeEnum(ProjectStatus);
export const ProjectLanguageDtoScheme = z.nativeEnum(ProjectLanguage);

export const OfferPdfSchema = z.object({
  id: z.string(),
  priceList: z.string(),
  number: z.string(),
  offerHash: z.string(),
  pdfs: z.object({
    en: z.string(),
    nl_be: z.string(),
    nl_nl: z.string(),
    fr: z.string(),
  }),
});

export const ConfigurationDtoSchema = z.object({
  pricingInfo: z.object({
    general: z.object({
      measurements: z.object({
        areaSite: z.number(),
        perimeterSite: z.number(),
        areaGreen: z.number(),
        areaConcreteExterior: z.number(),
        areaRoad: z.number(),
      }),
      soil: z.string(),
    }),
    warehouses: z.array(z.object({
      name: z.string(),
      measurements: z.object({
        area: z.number(),
        perimeter: z.number(),
        height: z.number(),
        width: z.number(),
        length: z.number(),
        lenghtFireWall: z.number(),
      }),
      concreteFloor: z.string(),
      techniques: z.string(),
      foundation: z.string(),
      mezzanines: z.array(z.object({
        area: z.number(),
        perimeter: z.number(),
        width: z.number(),
        length: z.number(),
      })),
      offices: z.array(z.object({
        area: z.number(),
        perimeter: z.number(),
        width: z.number(),
        length: z.number(),
        groundFloor: z.boolean(),
      })),
      materials: z.array(z.object({
        sku: z.string(),
        amount: z.number(),
      })),
    })),
  }),
  // will contain dynamic data, next to the pricingInfo, from the configurator itself
});

export const MailSettingsSchema = z.object({
  emailAddresses: z.array(z.object({
    email: z.string(),
  })),
  message: z.string(),
});

// Project models
export const ProjectDtoScheme = z.object({
  id: z.string(),
  name: z.string(),
  status: ProjectStatusDtoScheme,
  country: CountryDtoScheme,
  street: z.string().nullable(),
  number: z.string().nullable(),
  postalCode: z.string(),
  municipality: z.string(),
  company: z.object({
    id: z.string(),
    name: z.string(),
  }),
  contact: CompanyContactDtoScheme,
  acreage: z.string().nullable(),
  language: ProjectLanguageDtoScheme,
  updatedAt: z.string(),
  priceList: z.string(),
  // Not a concern of the API, but the configurator. API only persists the configuration
  // a scheme can be added later on, when the configurator is implemented
  configuration: z.any().nullable(),
  distance: z.number(), // in meter
  offer: OfferPdfSchema.nullable(),
  mailSettings: MailSettingsSchema,
  visuals: z.array(z.string()),
  floorPlans: z.array(z.string()),
  thumbnail: z.string().nullable(),
  discount: MoneyApiDtoScheme.nullable(),
});

export const ProjectSummaryDtoScheme = z.object({
  id: z.string(),
  name: z.string(),
  status: ProjectStatusDtoScheme,
  country: CountryDtoScheme,
  street: z.string().nullable(),
  number: z.string().nullable(),
  postalCode: z.string(),
  municipality: z.string(),
  company: z.object({
    id: z.string(),
    name: z.string(),
  }),
  contact: CompanyContactDtoScheme,
  acreage: z.string().nullable(),
  updatedAt: z.string(),
  thumbnail: z.string().nullable(),
});

export const ProjectListDtoScheme = z.array(ProjectDtoScheme);
export const ProjectSummaryListDtoScheme = z.array(ProjectSummaryDtoScheme);

export const ProjectUpdateRequestScheme = z.object({
  name: z.string(),
  contact: z.string(),
  language: z.string(),
  status: ProjectStatusDtoScheme,
  country: CountryDtoScheme,
  street: z.string(),
  number: z.string(),
  postalCode: z.string(),
  municipality: z.string(),
  configuration: z.any(),
  visuals: z.array(z.string()),
  floorPlans: z.array(z.string()),
  discount: MoneyDtoScheme,
});

export type ProjectStatusDto = z.infer<typeof ProjectStatusDtoScheme>;
export type ProjectDto = z.infer<typeof ProjectDtoScheme>;
export type ProjectSummaryDto = z.infer<typeof ProjectSummaryDtoScheme>;
export type ProjectListDto = z.infer<typeof ProjectListDtoScheme>;
export type ProjectUpdateRequest = Partial<z.infer<typeof ProjectUpdateRequestScheme>>;
export type OfferPdfDto = z.infer<typeof OfferPdfSchema>;
export type MailSettingsDto = z.infer<typeof MailSettingsSchema>;
