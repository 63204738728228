import { Labels, useTranslation } from '../../i18n';
import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = () => {
  const t = useTranslation();

  return (
    <div className={ styles.loader } data-cy="loading-indicator">
      <div className={ styles.inner }>
        <div className={ styles.circle }>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
          <div className={ styles.circleItem }></div>
        </div>
        <div className="visually-hidden">{ t(Labels.loading) }</div>
      </div>
    </div>
  );
};

export default LoadingIndicator;
