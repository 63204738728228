import React from 'react';

import './App.css';
import { Dashboard } from './dashboard';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedRoute, routes } from './navigation';
import { Login } from './auth';
import { ProjectOverview } from './projects';

import './styles/global-styles.scss';
import ProjectDetail from './projects/ui/detail/ProjectDetail';
import EditProject from './projects/ui/detail/edit/EditProject';
import ProjectVisuals from './projects/ui/detail/visuals/ProjectVisuals';
import ShareOffer from './projects/ui/detail/share/ShareOffer';
import ErrorBannerProvider from './common/ui/ErrorBanner';
import Configurator from './projects/configurator/ui/Configurator';
import ProjectPdf from './projects/ui/detail/ProjectPdf';

const App = () => {
  return (
    <ErrorBannerProvider>
      <Routes>
        <Route path={ routes.LOGIN } element={ <Login /> } />
        {/* TODO challenge this RequireAuth wrapper around the routes */ }
        <Route path={ routes.HOME } element={ <AuthenticatedRoute><Dashboard /></AuthenticatedRoute> } />
        <Route path={ routes.PROJECTS } element={ <AuthenticatedRoute><ProjectOverview /></AuthenticatedRoute> } />
        <Route path={ routes.PROJECT_DETAILS } element={ <AuthenticatedRoute><ProjectDetail /></AuthenticatedRoute> }>
          <Route index path={ routes.PROJECT_DETAILS_EDIT } element={ <EditProject /> } />
          <Route path={ routes.PROJECT_DETAILS_VISUALS } element={ <ProjectVisuals /> } />
          <Route path={ routes.PROJECT_DETAILS_SHARE } element={ <ShareOffer /> } />
          <Route path={ routes.PROJECT_DETAILS_CONFIGURATOR } element={ <Configurator /> } />
        </Route>
        <Route path={ routes.PROJECT_VIEW_PDF } element={ <AuthenticatedRoute><ProjectPdf /></AuthenticatedRoute> } />
      </Routes>
    </ErrorBannerProvider>
  );
};

export default App;
