// API response of price list
// {
//   "id": "1BuEvmrLL9aMJkwcjxfSc8",
//   "priceListId": "1BuEvmrGyQAxLGdrNUuWW1.xlsx",
//   "productPrices": [
//       {
//           "sku": "00000-00000-01150000000000000000-005",
//           "title": "Safety plan",
//           "description": "As a contractor, we fulfill the reporting obligation to the relevant government authorities (NAVB, FOD-TWW and RSZ).\nAt the request of the safety coordinator (appointed by the client), a safety plan and risk analysis are drawn up with regard to the work carried out by Willy Naessens Industriebouw NV.\nThe site manager (and, if necessary, the internal prevention advisor) of our company participates in the meeting(s) related to safety coordination.",
//           "mainCategory": "Warehouse",
//           "subCategory": "Voorbereidende werken",
//           "unit": "vast",
//           "prices": {
//               "price60": "577",
//               "price100": "577",
//               "price200": "577",
//               "price300": "577",
//               "price400": "577",
//               "priceOther": "577"
//           }
//       },
//       {
//           "sku": "00000-00000-01200500000000000000-006",
//           "title": "Setting up site and cleaning up site",
//           "description": "The layout and installation of the building is done according to our implementation plans, approved by the client and/or architect.\nThe coordination and site management of the works described in this tender.\nDuring our work we provide the necessary excavation and assembly cranes.\nA temporary connection for water and electricity is provided during our works.\nA sanitary unit is provided during the period that the work included in this offer is being carried out.\nDepending on the legislation with regard to safety on the site, a stair tower will be installed that gives access to the roof, during the period that the work included in this offer is being carried out.\nA balustrade will be provided during our works at the height of the storeys, eaves and/or around the opening(s) in the roof.\nThe yard is delivered brush-clean before delivery.",
//           "mainCategory": "Warehouse",
//           "subCategory": "Voorbereidende werken",
//           "unit": "m² warehouse",
//           "prices": {
//               "price60": "1.46",
//               "price100": "1.54",
//               "price200": "1.69",
//               "price300": "1.85",
//               "price400": "2",
//               "priceOther": "2.15"
//           }
//       },
//       {
//           "sku": "00000-00000-01200500000000000000-007",
//           "title": "Tijdelijke borstwering dak",
//           "description": "Fall protection will be provided at the roof edge during the work in progress.",
//           "mainCategory": "Warehouse",
//           "subCategory": "Voorbereidende werken",
//           "unit": "lm omtrek warehouse",
//           "prices": {
//               "price60": "13.86",
//               "price100": "13.86",
//               "price200": "13.86",
//               "price300": "13.86",
//               "price400": "13.86",
//               "priceOther": "13.86"
//           }
//       },
//       {
//           "sku": "00000-00000-03050505050000000000-007",
//           "title": "Foundation: Piles + Massif of piles + coppicing and disposal of concrete waste. Excess earth is stored on site.",
//           "description": "",
//           "mainCategory": "Warehouse",
//           "subCategory": "Funderingen",
//           "unit": "st",
//           "prices": {
//               "price60": "3611",
//               "price100": "3637",
//               "price200": "3835",
//               "price300": "3899",
//               "price400": "3962",
//               "priceOther": "4049"
//           }
//       },
//       {
//           "sku": "00000-00000-03050505050000000000-009",
//           "title": "OPTION: Foundation: Piles + Massif of piles + coppicing and disposal of concrete waste. Including soil removal (unpolluted and stone-free soil)",
//           "description": "",
//           "mainCategory": "Warehouse",
//           "subCategory": "Funderingen",
//           "unit": "st",
//           "prices": {
//               "price60": "3696",
//               "price100": "3721",
//               "price200": "3920",
//               "price300": "3984",
//               "price400": "4047",
//               "priceOther": "4133"
//           }
//       },
//       {
//           "sku": "00000-00000-03102520000000000000-247",
//           "title": "Radier sprinkler ø11m",
//           "description": "",
//           "mainCategory": "Warehouse",
//           "subCategory": "Funderingen",
//           "unit": "st",
//           "prices": {
//               "price60": "19200",
//               "price100": "19200",
//               "price200": "19200",
//               "price300": "21942",
//               "price400": "21942",
//               "priceOther": "21942"
//           }
//       },
//       {
//           "sku": "00000-00000-05052015290000000000-015",
//           "title": "TC 40/80 - 2% - incl 1 opening ø270mm",
//           "description": "",
//           "mainCategory": "Warehouse",
//           "subCategory": "Structuur",
//           "unit": "st",
//           "prices": {
//               "price60": "3959",
//               "price100": "3963",
//               "price200": "4094",
//               "price300": "4823",
//               "price400": "4833",
//               "priceOther": "4843"
//           }
//       }
//   ],
//   "createdAt": "2023-01-11T17:52:01+01:00"
// }

import { z } from 'zod';


const PricesDtoScheme = z.object({
  price60: z.string(),
  price100: z.string(),
  price200: z.string(),
  price300: z.string(),
  price400: z.string(),
  priceOther: z.string(),
});

const ProductPriceDtoScheme = z.object({
  sku: z.string(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  mainCategory: z.string(),
  subCategory: z.string(),
  unit: z.string(),
  prices: PricesDtoScheme,
});

export const PriceListDtoScheme = z.object({
  id: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  productPrices: z.array(ProductPriceDtoScheme),
});

export type PriceListDto = z.infer<typeof PriceListDtoScheme>;
export type ProductPriceDto = z.infer<typeof ProductPriceDtoScheme>;
export type PricesDto = z.infer<typeof PricesDtoScheme>;
