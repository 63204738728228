import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const officeGroundFloorAreaCalculation = (productPrice: ProductPrice, distance: number, groundFloorOfficeArea: number): OfferLine | undefined => {
  if (groundFloorOfficeArea <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(groundFloorOfficeArea);
  const quantity = formatters.formatSquareMeter(groundFloorOfficeArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default officeGroundFloorAreaCalculation;
