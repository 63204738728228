import { AxiosHeaders, AxiosRequestConfig } from 'axios';
import authService from './authService';
import { NoValidTokensError, UnauthorizedByServerError } from './exceptions';
import { authApi } from '../data/api';
import authStorage from '../data/authStorage';

const onRequest = async (config: AxiosRequestConfig) => {
  let accessToken = authStorage.getAccessToken();
  if (!accessToken) {
    await refreshTokens();
  }

  accessToken = authStorage.getAccessToken();
  if (!accessToken) throw new NoValidTokensError('No valid access token');

  (config.headers as AxiosHeaders).set('Authorization', `Bearer ${accessToken}`);

  return config;
};

const onError = async (error: any) => {
  console.log('Error interceptor', error);
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    authService.logout();
    return Promise.reject(new UnauthorizedByServerError('Unauthorized by server'));
  }
  return Promise.reject(error);
};


const refreshTokens = async () => {
  try {
    const refreshToken = authStorage.getRefreshToken();
    if (!refreshToken) {
      throw new NoValidTokensError('No valid refresh token');
    }
    const newTokens = await authApi.refreshTokens(refreshToken);
    authStorage.saveTokens(newTokens);
  } catch (e) {
    if (e instanceof UnauthorizedByServerError) {
      authService.logout();
    }
  }
};

const tokenInterceptor = {
  onRequest,
  onError,
};

export default tokenInterceptor;
