import { FormEvent, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Labels, useTranslation } from '../../../i18n';
import routes from '../../../navigation/routes';
import authService from '../../domain/authService';
import { LoadingIndicator } from '../../../common';

import styles from './LoginForm.module.scss';
import classNames from 'classnames';
import { useErrorBanner } from '../../../common/ui/ErrorBanner';

const MAX_CHARACTERS = 250;

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const t = useTranslation();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usedInvalidCredentials, setUsedInvalidCredentials] = useState(false);
  const { setVisible } = useErrorBanner();

  const onLoginSuccessful = useCallback(() => {
    const origin = location.state?.from ?? routes.HOME;
    console.log('Login success, navigating to: ', origin);
    navigate(origin);
  }, [navigate, location]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (isLoggingIn) return;
    const isValidForm = isValidUsername(username) && isValidPassword(password);

    if (!isValidForm) return;

    try {
      setIsLoggingIn(true);
      setUsedInvalidCredentials(false);
      const success = await authService.login(username, password);
      if (success) {
        onLoginSuccessful();
      } else {
        setUsedInvalidCredentials(true);
      }
    } catch (error) {
      // uncaught errors in a try-finally are not caught by the ErrorBanner and need to be handled manually
      setVisible(true);
    } finally {
      setIsLoggingIn(false);
    }
  };

  const buttonClasses = classNames('form-submit', { 'form-submit--loader': isLoggingIn });

  return (
    <form onSubmit={ onSubmit } className={ styles.form }>
      <div className="form-item form-item--required">
        <label htmlFor="username">
          { t(Labels.loginUsernameLabel) }
        </label>
        <input
          id="username"
          name="username"
          type="email"
          value={ username }
          onChange={ (e) => setUsername(e.target.value.substring(0, MAX_CHARACTERS)) }
          disabled={ isLoggingIn }
          required
        />
      </div>

      <div className="form-item form-item--required">
        <label htmlFor="password">
          { t(Labels.loginPasswordLabel) }
        </label>
        <input
          id="password"
          name="password"
          type="password"
          value={ password }
          onChange={ (e) => setPassword(e.target.value.substring(0, MAX_CHARACTERS)) }
          disabled={ isLoggingIn }
          required
        />
      </div>

      { usedInvalidCredentials && <InvalidCredentialsErrorMessage /> }

      <div className="form-actions">
        <button type="submit" className={ buttonClasses }>
          <span>{ t(Labels.loginLoginCta) }</span>
          { isLoggingIn && <LoadingIndicator /> }
        </button>
      </div>
    </form>
  );
};

const InvalidCredentialsErrorMessage = () => {
  const t = useTranslation();
  return (
    <div className={ styles.error } data-cy="invalidCredentialsErrorMessage">
      { t(Labels.loginInvalidCredentials) }
    </div>
  );
};


const isValidUsername = (username: string): boolean => {
  let valid = true;
  valid &&= username.length > 0;
  valid &&= username.length < MAX_CHARACTERS;
  return valid;
};

const isValidPassword = (password: string): boolean => {
  let valid = true;
  valid &&= password.length > 0;
  valid &&= password.length < MAX_CHARACTERS;
  return valid;
};

export default LoginForm;
