
type TranslateFunction = (label: string, placeholders?: InterpolationQuery) => string;
interface InterpolationQuery {
  [name: string]: any;
}
/**
 * Since this project currently only supports a single language ('EN'),
 * this is kept quite simplistic as opposed to other projects.
 * 
 * When multiple languages with dynamic label files are required,
 * have a look at projects like LHF, Genscom webshop, etc
 * 
 * @returns
 */
export default function useTranslation(): TranslateFunction {
  return standaloneTFunction;
}

function containsPlaceholder(text: string) {
  const regex = getMatchingRegex('(.*?)');
  return regex.test(text);
}

function interpolate(text?: string, query?: InterpolationQuery): string {
  if (!text || !query) return text || '';

  return Object.keys(query).reduce((all, varKey) => {
    const regex = getMatchingRegex(varKey);
    // If a given key does not exist in the label
    if (!regex.test(all)) {
      console.warn(`Interpolation query '${varKey}' not found in '${text}'.`);
    }

    // Replace all instances of the key in the label
    return all.replace(regex, () => {
      const value = query[varKey];
      return (value);
    });
  }, text);
}

function getMatchingRegex(varKey: string) {
  // Prefixes all special characters with a backslash
  const escapeRegex = (str: string) => str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const prefix = '{{';
  const suffix = '}}';
  const regexEnd = `(?:[\\s,]+([\\w-]*))?\\s*${escapeRegex(suffix)}`;
  const regexStart = `${escapeRegex(prefix)}\\s*`;
  // Creates a regex containing: '{{placeholder_key}}'
  return new RegExp(`${regexStart}${varKey}${regexEnd}`, 'gm');
}

const standaloneTFunction = (label: string, interpolationQuery?: InterpolationQuery): string => {
  if (containsPlaceholder(label) && interpolationQuery == null) {
    console.warn(`Label '${label}' contains a non-replaced placeholder.`);
  }

  const text = label;
  return interpolationQuery
    ? interpolate(text, interpolationQuery)
    : text;
};
