import { MainSidebar } from '../../common';
import { CompanyFilter, useCompany } from '../../company';
import { Labels, useTranslation } from '../../i18n';
import { CreateProject } from '../../projects';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  return (
    <div className={ styles.page } data-cy="dashboard">
      <div className={ styles.sidebar }>
        <MainSidebar dashboardSelected />
      </div>

      <div className={ styles.image }>
        <picture>
          <img src="img/bg__we-build-things.jpg" width={ 984 } height={ 800 } alt="The Circle - building the future" />
        </picture>

        <div className={ styles.lines8 }>
          <img src="img/lines/style8.png" alt="" />
        </div>
      </div>

      <div className={ styles.logo }>
        <a href="#the-circle">
          <img src="img/logo__the-circle-willy-naessens.png" width={ 273 } height={ 90 } alt="The Circle - Willy Naessens" />
        </a>
      </div>

      <div className={ styles.create }>
        <h1 className={ styles.title }><span>The</span><br />Circle</h1>
        <CreateProject />
      </div>

      <div className={ styles.change }>
        <SelectedCompany />
      </div>

      <div className={ styles.lines1 }>
        <img src="img/lines/style1.png" alt="" />
      </div>
    </div>
  );
};

export const SelectedCompany = () => {
  const [company] = useCompany();
  const t = useTranslation();

  const message = company ?
    t(Labels.dashboardSelectedCompany, { name: company.name })
    : t(Labels.dashboardAllCompanies);
  return (
    <div className={ styles.company } data-cy="dashboardSelectedCompany">
      <div className={ styles.info }>{ message }</div>
      <CompanyFilter buttonText={ Labels.dashboardChangeCompanyCta } dashboard={ true } />
    </div>
  );
};


export default Dashboard;
