import { Email } from './../domain/types';
import { Offer, Money } from './../offer/domain/types';
import { apiClient } from '../../common';
import { Address } from '../../common/domain/types';
import { Company, CompanyContact } from '../../company/domain/types';
import { OfferPdf, Project, ProjectLanguage, ProjectStatus, ProjectSummary } from '../domain/types';
import projectMapper from './mappers';
import { ProjectApi } from './projectApi';
import { OfferPdfSchema, ProjectDto, ProjectDtoScheme, ProjectSummaryDto, ProjectSummaryListDtoScheme, ProjectUpdateRequest } from './types';
import { saveAs } from 'file-saver';
import { Configuration } from '../configurator/domain/types';

const projectsEndpoint = '/api/project';
const projectEndpoint = (projectId: string) => `/api/project/${projectId}`;
const visualsEndpoint = (projectId: string) => `/api/project/${projectId}/visuals`;
const floorPlansEndpoint = (projectId: string) => `/api/project/${projectId}/floor-plans`;
const offerEndpoint = () => 'api/offer';
const mailOfferEndpoint = (offerId: string) => `api/offer/${offerId}/mail`;

const getProjectSummaries = async (): Promise<ProjectSummary[]> => {
  try {
    const response = await apiClient.get<ProjectSummaryDto[]>(projectsEndpoint);
    const dto = ProjectSummaryListDtoScheme.parse(response.data);
    return dto.map(projectMapper.mapProjectSummaryDtoToProjectSummary);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const createProject = async (company: Company, contact: CompanyContact, projectName: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  try {
    const body = {
      name: projectName,
      company: company.id,
      contact: contact.id,
      language,
      ...address,
    };

    const response = await apiClient.post<ProjectDto[]>(projectsEndpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

/**
 * Update basic project information
 * 
 * @param projectId 
 * @param companyContactId 
 * @param name 
 * @param language 
 * @param address Address information can be removed by passing empty strings
 * @returns Project with updated information
 */
const updateProject = async (projectId: string, companyContactId: string, name: string, language: ProjectLanguage, address: Address): Promise<Project> => {
  try {
    const body: ProjectUpdateRequest = {
      name,
      contact: companyContactId,
      language,
      country: address.country,
      street: address.street || '',
      number: address.number || '',
      postalCode: address.postalCode || '',
      municipality: address.municipality || '',
    };

    const endpoint = projectEndpoint(projectId);
    const response = await apiClient.patch<ProjectDto[]>(endpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateProjectStatus = async (projectId: string, status: ProjectStatus): Promise<Project> => {
  try {
    const body: ProjectUpdateRequest = {
      status,
    };

    const endpoint = projectEndpoint(projectId);
    const response = await apiClient.patch<ProjectDto[]>(endpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateConfiguration = async (projectId: string, configuration: Configuration) => {
  try {
    const body: ProjectUpdateRequest = {
      configuration: configuration,
    };

    const endpoint = projectEndpoint(projectId);
    const response = await apiClient.patch<ProjectDto[]>(endpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateDiscount = async (projectId: string, discount: Money) => {
  try {
    const body: ProjectUpdateRequest = {
      discount,
    };

    const endpoint = projectEndpoint(projectId);
    const response = await apiClient.patch<ProjectDto[]>(endpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateVisuals = async (projectId: string, images: string[]): Promise<string[]> => {
  try {
    const body: ProjectUpdateRequest = {
      visuals: images,
    };

    const endpoint = visualsEndpoint(projectId);
    const response = await apiClient.post<{ visuals: string[]; }>(endpoint, body);
    return response.data.visuals;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const updateFloorPlans = async (projectId: string, images: string[]): Promise<string[]> => {
  try {
    const body: ProjectUpdateRequest = {
      floorPlans: images,
    };

    const endpoint = floorPlansEndpoint(projectId);
    const response = await apiClient.post<{ floorPlans: string[]; }>(endpoint, body);
    return response.data.floorPlans;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createOffer = async (projectId: string, offer: Offer, offerHash: string): Promise<OfferPdf> => {
  try {
    const body = {
      project: projectId,
      offerHash,
      offer,
    };

    const endpoint = offerEndpoint();
    const response = await apiClient.post<OfferPdf>(endpoint, body);
    const dto = OfferPdfSchema.parse(response.data);
    return projectMapper.mapOfferPdfDtoToOfferPdf(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getProject = async (projectId: string): Promise<Project> => {
  try {
    const endpoint = projectEndpoint(projectId);
    const response = await apiClient.get<ProjectDto>(endpoint);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const duplicateProject = async (project: Project, name: string): Promise<Project> => {
  try {
    const body = {
      name,
      company: project.companyId,
      contact: project.contact.id,
      language: project.language,
      ...project.address,
      configuration: project.configuration,
      status: project.status,
      visuals: project.visuals,
      mailSettings: project.mailSettings,
      discount: project.discount,
      floorPlans: project.floorPlans,
    };

    const response = await apiClient.post<ProjectDto[]>(projectsEndpoint, body);
    const dto = ProjectDtoScheme.parse(response.data);
    return projectMapper.mapProjectDtoToProject(dto);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const deleteProject = async (projectId: string): Promise<void> => {
  try {
    const endpoint = projectEndpoint(projectId);
    await apiClient.delete(endpoint);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const downloadOfferPdf = async (endpoint: string): Promise<void> => {
  try {
    const response = await apiClient.get(endpoint, {
      responseType: 'blob',
    });
    saveAs(response.data, 'offer.pdf');
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const mailOffer = async (offerId: string, emailAddresses: Email[], message: string): Promise<void> => {
  try {
    const endpoint = mailOfferEndpoint(offerId);
    const body = {
      emailAddresses: emailAddresses,
      message: message,
    };

    await apiClient.post(endpoint, body);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const projectApiImpl: ProjectApi = {
  getProjectSummaries,
  createProject,
  updateProject,
  updateProjectStatus,
  updateConfiguration,
  updateDiscount,
  updateVisuals,
  updateFloorPlans,
  createOffer,
  getProject,
  duplicateProject,
  deleteProject,
  downloadOfferPdf,
  mailOffer,
};

export default projectApiImpl;
