import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const quantityCalculation = (productPrice: ProductPrice, distance: number, quantity: number): OfferLine | undefined => {
  if (quantity <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(quantity);
  const formattedQuantity = formatters.formatPiece(quantity);
  const unitPrice = formatters.formatPricePerPiece(distancePrice);
  return offerMapper.constructOfferLine(productPrice, formattedQuantity, unitPrice, totalPrice);
};

export default quantityCalculation;
