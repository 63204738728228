type AppConfig = {
  API_BASE_URL: string;
  CONFIGURATOR_URL: string;
  USE_MOCK_API: boolean;
};

const AppConfig: AppConfig = {
  API_BASE_URL: process.env.REACT_APP_BASE_URL_API!,
  CONFIGURATOR_URL: process.env.REACT_APP_CONFIGURATOR_URL!,
  USE_MOCK_API: process.env.REACT_APP_USE_MOCK_API === 'TRUE',
};

export default AppConfig;
