import React, { useEffect } from 'react';
import { Labels, useTranslation } from '../../i18n';
import { IconClose } from '../../assets/icons';
import styles from './ErrorBanner.module.scss';

const ErrorBannerContext = React.createContext({
  isVisible: false,
  setVisible: (visible: boolean) => {
    console.log('ErrorBannerContext.setVisible', visible);
  },
});

type ErrorBannerProps = {
  children?: React.ReactNode;
};

const ErrorBannerProvider = (props: ErrorBannerProps) => {
  const [isVisible, setVisible] = React.useState(false);

  useEffect(() => {
    const onUncaughtError = (event: ErrorEvent) => {
      console.error('Uncaught error', event);
      setVisible(true);
    };
    window.addEventListener('error', onUncaughtError);
    return () => window.removeEventListener('error', onUncaughtError);
  }, [setVisible]);

  return (
    <ErrorBannerContext.Provider value={ { isVisible, setVisible } }>
      <div className={ styles.container }>
        { props.children }
        <ErrorBanner />
      </div>
    </ErrorBannerContext.Provider>
  );
};

const ErrorBanner = (props: ErrorBannerProps) => {
  const t = useTranslation();

  return (
    <ErrorBannerContext.Consumer>
      { ({ isVisible, setVisible }) => (
        <div className={ styles.banner } data-visible={ isVisible } data-cy='error-banner'>
          <div className={ styles.message }>
            { t(Labels.generalError) }
          </div>
          <div className={ styles.close }>
            <button onClick={ () => setVisible(false) }>
              <IconClose />
            </button>
          </div>
        </div>
      ) }
    </ErrorBannerContext.Consumer>
  );
};

export const useErrorBanner = () => {
  const { isVisible, setVisible } = React.useContext(ErrorBannerContext);
  return {
    isVisible,
    setVisible,
  };
};

export default ErrorBannerProvider;
