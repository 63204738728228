import { companyApi } from '../data/api';
import companyFilterStorage from '../data/companyFilterStorage';

const getCompanies = async () => companyApi.getCompanies();

const getCompanyFilter = () => companyFilterStorage.getCompanyFilter();

const setCompanyFilter = (companyId: string | null) => companyFilterStorage.setCompanyFilter(companyId);

const companyService = {
  getCompanies,
  getCompanyFilter,
  setCompanyFilter,
};

export default companyService;
