import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import AppConfig from '../../../config/appConfig';
import { formatters } from '../../../i18n';
import { routes } from '../../../navigation';
import { useViewModel } from '../../domain/ProjectDetailViewModel';
import offerMapper from '../../offer/domain/offerMapper';
import { ConfiguratorBridgeListener, useConfiguratorBridge } from './ConfiguratorBridge';

const Configurator = () => {
  const viewModel = useViewModel();
  const { ref, bridge } = useConfiguratorBridge();
  const navigate = useNavigate();

  const editPath = generatePath(routes.PROJECT_DETAILS_EDIT, { projectId: viewModel.project.id });

  const listener: ConfiguratorBridgeListener = useMemo(() => ({
    onLoaded: () => bridge?.loadConfiguration(viewModel.project.configuration),
    onGetPrice: () => bridge?.setPrice(viewModel.offer ? formatters.formatMoney(viewModel.offer.pricing.totalPrice) : formatters.formatMoney(offerMapper.mapMoney(-99))),
    onBack: () => navigate(editPath),
    onUpdateConfiguration: viewModel.updateConfiguration, // Debounced action because the configurator sends a lot of actions at once
    onUpdateImages: viewModel.updateImages, // Debounced action because the configurator sends a lot of actions at once
    onUpdateFloorPlans: viewModel.updateFloorPlans, // Debounced action because the configurator sends a lot of actions at once
  }), [bridge, editPath, navigate, viewModel]);
  useEffect(() => bridge?.addListener(listener), [bridge, listener]);

  return (
    <div style={ {
      gridColumn: 'col-start 1 / span 13',
    } }>
      <iframe
        ref={ ref }
        src={ AppConfig.CONFIGURATOR_URL }
        style={
          {
            width: '100%',
            height: '100%',
          }
        }
      ></iframe>
    </div >
  );
};

export default Configurator;
