import BigNumber from 'bignumber.js';
import { Prices } from '../../../../prices/domain/types';

// Internally we work with distances in meter, but the price points are in km. A method is used to keep the code readable
export const getDistancePrice = (distance: number, prices: Prices): BigNumber => {
  let distancePrice: number = prices.priceOther;
  if (distance < kilometerToMeter(60)) {
    distancePrice = prices.price60;
  } else if (distance < kilometerToMeter(100)) {
    distancePrice = prices.price100;
  } else if (distance < kilometerToMeter(200)) {
    distancePrice = prices.price200;
  } else if (distance < kilometerToMeter(300)) {
    distancePrice = prices.price300;
  } else if (distance < kilometerToMeter(400)) {
    distancePrice = prices.price400;
  }
  return BigNumber(distancePrice);
};

const kilometerToMeter = (kilometer: number): number => {
  return kilometer * 1000;
};
