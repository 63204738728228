import React, { useCallback, useEffect } from 'react';
import { SwiperRef } from 'swiper/react';
import { Swiper } from 'swiper/react';
import { Navigation, A11y, Mousewheel } from 'swiper';
import { NavigationOptions } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';


// Wrapper around swiper to handle all the annoying stuff

type SliderProps = {
  slidesPerView: number;
  onIndexChanged: (index: number) => any;
  children: React.ReactNode;
  nextButtonRef?: React.MutableRefObject<any>;
  previousButtonRef?: React.MutableRefObject<any>;
};

const Slider = (props: SliderProps) => {
  const swiperRef = React.useRef<SwiperRef>(null);

  const onUpdateIndex = useCallback(() => {
    if (swiperRef.current) {
      const index = swiperRef.current.swiper.realIndex;
      props.onIndexChanged(index);
    }
  }, [props, swiperRef]);


  useEffect(() => {
    if (swiperRef.current == null) return;

    const swiperInstance = swiperRef.current.swiper;
    swiperInstance.on('slideChange', onUpdateIndex);

    return () => {
      swiperInstance.off('slideChange', onUpdateIndex);
    };
  }, [swiperRef, onUpdateIndex]);


  const navigationSettings = {
    prevEl: props.previousButtonRef?.current,
    nextEl: props.nextButtonRef?.current,
  };

  const onBeforeInit = (swiper: any) => {
    ((swiper.params.navigation!) as NavigationOptions).prevEl = props.previousButtonRef?.current;
    ((swiper.params.navigation!) as NavigationOptions).nextEl = props.nextButtonRef?.current;
  };

  return (
    <Swiper
      ref={ swiperRef }
      modules={ [Navigation, A11y, Mousewheel] }
      threshold={ 30 } // prevents 'drag'-clicks..
      spaceBetween={ 0 }
      slidesPerView={ props.slidesPerView }
      speed={ 750 }
      navigation={ navigationSettings }
      onBeforeInit={ onBeforeInit }
      mousewheel={ {
        forceToAxis: true,
        releaseOnEdges: true,
        thresholdDelta: 30,
      } }
    >
      {
        props.children
      }
    </Swiper>
  );
};

export default Slider;
