import { InvalidCredentialsError } from '../../domain/exceptions';
import { AuthTokens } from '../../domain/types';
import { AuthApi } from './authApi';

const requestTokensForCredentials = async (username: string, password: string): Promise<AuthTokens> => {
  await new Promise((r) => setTimeout(r, 1000));

  if (username === 'admin@example.com' && password === 'admin') {
    return {
      accessToken: fakeAccessToken,
      refreshToken: fakeRefreshToken,
    };
  }

  throw new InvalidCredentialsError('Invalid credentials');

};

const forgotPassword = async (email: string): Promise<void> => {
  await new Promise((r) => setTimeout(r, 1000));
};

const requestPasswordReset = async (): Promise<void> => {
  await new Promise((r) => setTimeout(r, 1000));

};

const refreshTokens = async (refreshToken: string): Promise<AuthTokens> => {
  await new Promise((r) => setTimeout(r, 1000));
  return {
    accessToken: fakeAccessToken,
    refreshToken: fakeRefreshToken,
  };
};

// Generated with http://jwtbuilder.jamiekurtz.com/
// {
//   "iss": "www.duo.be",
//   "iat": 1673274164,
//   "exp": 1804810164,
//   "aud": "www.example.com",
//   "sub": "admin@example.com",
//   "firstName": "Johnny",
//   "username": "admin@example.com"
// }
const fakeAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJ3d3cuZHVvLmJlIiwiaWF0IjoxNjczMjc0MTY0LCJleHAiOjE4MDQ4MTAxNjQsImF1ZCI6Ind3dy5leGFtcGxlLmNvbSIsInN1YiI6ImFkbWluQGV4YW1wbGUuY29tIiwiZmlyc3ROYW1lIjoiSm9obm55IiwidXNlcm5hbWUiOiJhZG1pbkBleGFtcGxlLmNvbSJ9.HSRsPUc7ld6LKvrVN3TWSpSfzuvFMWr33p1LU-p6SBY';
// Not a JWT token, just a random string
const fakeRefreshToken = '92a3d47e41f548b2c3e8e606037d3f3672f628eec17c81598128d2171c3fb63d13fd0c059753352186c160f549a8215b37d83879baadefbce37a446df07a9b86';

const mockAuthApi: AuthApi = {
  requestTokensForCredentials,
  forgotPassword,
  requestPasswordReset,
  refreshTokens,
};

export default mockAuthApi;
