import { FormEvent, useEffect, useState } from 'react';
import { LoadingIndicator, TextField, Address } from '../../../../common';
import { CompanyContact } from '../../../../company/domain/types';
import { Labels, useTranslation } from '../../../../i18n';
import { ProjectLanguage } from '../../../domain/types';
import classNames from 'classnames';
import CountryDropdown from '../../form/CountryDropdown';
import LanguageDropdown from '../../form/LanguageDropdown';
import ContactDropdown from '../../form/ContactDropdown';
import { useCompany } from '../../../../company';
import { useViewModel } from '../../../domain/ProjectDetailViewModel';

type EditProjectFormProps = {
  onUpdated: () => any;
};

function EditProjectForm({ onUpdated }: EditProjectFormProps) {
  const [, , companies] = useCompany();
  const t = useTranslation();

  const { project, updating, updateProject } = useViewModel();
  if (!project) {
    throw new Error('Project is not defined');
  }

  const [projectName, setProjectName] = useState(project.name);
  const [contact, setContact] = useState<CompanyContact | null>(project.contact);
  const [language, setLanguage] = useState<ProjectLanguage>(project.language);
  const [address, setAddress] = useState<Address>(project.address);

  useEffect(() => {
    setProjectName(project.name);
    setContact(project.contact);
    setLanguage(project.language);
    setAddress(project.address);
  }, [project]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await updateProject(contact!, projectName, language, address);
    onUpdated();
  };

  const company = companies.find((company) => company.id === project.companyId)!;
  const hasContacts = company.companyContacts.length > 0 && contact != null;
  const buttonClasses = classNames('form-submit', { 'form-submit--loader': updating });

  return (
    <form onSubmit={ onSubmit } data-cy="editProjectForm">

      {
        !hasContacts
          ? <p>{ t(Labels.editProjectModalNoContacts) }</p>
          : <>
            <div className="form-item">
              <ContactDropdown
                selectedContact={ contact }
                label={ t(Labels.editProjectModalContact) }
                contacts={ company.companyContacts }
                onChange={ setContact }
                disabled={ updating }
              />
            </div>

            <div className="form-item">
              <TextField
                identifier='name'
                value={ projectName }
                label={ t(Labels.editProjectModalName) }
                onChange={ setProjectName }
                required={ true }
                disabled={ updating }
              />
            </div>

            <div className="form-item">
              <LanguageDropdown
                selectedLanguage={ language }
                label={ t(Labels.editProjectModalLanguage) }
                onChange={ setLanguage }
                disabled={ updating }
              />
            </div>

            <div className="form-item">
              <CountryDropdown
                selectedCountry={ address.country }
                label={ t(Labels.editProjectModalCountry) }
                onChange={ (country) => setAddress({ ...address, country }) }
                disabled={ updating }
              />
            </div>

            <div className="form-item form-item--double">
              <TextField
                identifier='street'
                value={ address.street ?? '' }
                label={ t(Labels.editProjectModalStreet) }
                onChange={ (street) => setAddress({ ...address, street: street.trim() === '' ? null : street }) }
                disabled={ updating }
              />

              <TextField
                identifier='number'
                value={ address.number ?? '' }
                label={ t(Labels.editProjectModalNumber) }
                onChange={ (number) => setAddress({ ...address, number: number.trim() === '' ? null : number }) }
                disabled={ updating }
              />
            </div>

            <div className="form-item form-item--double">
              <TextField
                identifier='postal-code'
                value={ address.postalCode ?? '' }
                label={ t(Labels.editProjectModalPostalCode) }
                onChange={ (postalCode) => setAddress({ ...address, postalCode: postalCode.trim() }) }
                required={ true }
                disabled={ updating }
              />

              <TextField
                identifier='municipality'
                value={ address.municipality ?? '' }
                label={ t(Labels.editProjectModalMunicipality) }
                onChange={ (municipality) => setAddress({ ...address, municipality: municipality.trim() }) }
                required={ true }
                disabled={ updating }
              />
            </div>

            <div className="form-actions">
              <button className={ buttonClasses } type="submit">
                <span>{ t(Labels.editProjectModalSubmit) }</span>
                { updating && <LoadingIndicator /> }
              </button>
            </div>
          </>
      }
    </form>
  );
}


export default EditProjectForm;
