import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { IconAccount } from '../../assets/icons';
import { User } from '../../auth';
import authService from '../../auth/domain/authService';
import { Labels, useTranslation } from '../../i18n';
import ConfirmLogoutContent from './modalContent/ConfirmLogoutContent';
import MenuContent from './modalContent/MenuContent';
import RequestPasswordContent from './modalContent/RequestPasswordContent';

import styles from './UserSettings.module.scss';

export enum ModalContent {
  menu,
  requestPassword,
  confirmLogout,
}

const UserSettings = () => {
  // might change to another way of state management (via context, maybe redux, ...)
  const user = authService.getUser();
  const [userSettingsVisible, setUserSettingsVisible] = useState(false);

  if (!user) throw Error('Something went horribly wrong');

  const openSettings = () => setUserSettingsVisible(true);
  const closeSettings = () => setUserSettingsVisible(false);

  return (
    <>
      <UserSettingsModal
        user={ user }
        isOpen={ userSettingsVisible }
        onClose={ closeSettings }
      />
      <div
        className={ styles.sidebarTab }
        data-cy="userSettingsTab"
        onClick={ openSettings }
      >
        <button className={ styles.sidebarBtn }>
          <IconAccount />
          <span>{ user.firstName }</span>
        </button>

      </div>
    </>
  );
};

type UserSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: User;
};

const UserSettingsModal = (props: UserSettingsModalProps) => {
  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className="modal modal--user-info"
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner">
        <div className="modal__content">
          <ModalContentRenderer user={ props.user } />
          <CloseButton onClose={ props.onClose } />
          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};


type ModalContentRendererProps = {
  user: User;
};

const ModalContentRenderer = ({ user }: ModalContentRendererProps) => {
  const [currentContent, setCurrentContent] = useState<ModalContent>(ModalContent.menu);

  switch (currentContent) {
    case ModalContent.menu:
      return (
        <MenuContent user={ user } onMenuItemClicked={ setCurrentContent } />
      );

    case ModalContent.requestPassword:
      return (
        <RequestPasswordContent onBack={ () => setCurrentContent(ModalContent.menu) } user={ user } />
      );

    case ModalContent.confirmLogout:
      return (
        <ConfirmLogoutContent onBack={ () => setCurrentContent(ModalContent.menu) } />
      );

    default:
      throw Error('Unknown menu type');
  }
};


type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();
  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose }>
        { t(Labels.userSettingsCloseCta) }
      </button>
    </div>
  );
};


export default UserSettings;
