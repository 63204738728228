import Dropdown, { DropdownOption } from '../../../common/ui/form/Dropdown';
import { Company } from '../../../company/domain/types';
import { Labels, useTranslation } from '../../../i18n';

type CompanyDropdownOption = DropdownOption & {
  company: Company;
};

type CompanyDropdownProps = {
  selectedCompany: Company;
  companies: Company[];
  onChange: (selectedCompany: Company) => any;
  disabled: boolean;
};

function CompanyDropdown(props: CompanyDropdownProps) {
  const t = useTranslation();

  const options: CompanyDropdownOption[] = props.companies.map((company) => ({
    text: company.name,
    value: company.id,
    company,
  }));

  const selectedOption = options.find((option) => option.company.id === props.selectedCompany?.id);

  return (
    <Dropdown
      identifier='company'
      options={ options }
      label={ t(Labels.createProjectModalCompany) }
      onChange={ (option) => props.onChange(option!.company) }
      selectedOption={ selectedOption }
      required={ true }
      disabled={ props.disabled }
    />
  );
}

export default CompanyDropdown;
