import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FullscreenLoader } from '../../../common';
import { ProjectPdfSidebar } from '../../../common/ui/Sidebar';
import { Labels, useTranslation } from '../../../i18n';
import { routes } from '../../../navigation';
import useProjectDetailViewModel from '../../domain/ProjectDetailViewModel';

const ProjectPdf = () => {
  const t = useTranslation();
  const navigate = useNavigate();

  const { projectId } = useParams();
  if (!projectId) throw new Error('projectId is required!');
  const { project } = useProjectDetailViewModel(projectId);
  const sharePath = generatePath(routes.PROJECT_DETAILS_SHARE, { projectId });

  const downloadPdf = () => {
    console.log('Download PDF');
  };

  if (!project) {
    return (
      <div>
        <ProjectPdfSidebar onClose={ () => navigate(sharePath) } />
        <div>
          <FullscreenLoader />
        </div>
      </div>
    );
  }

  return (
    <div>
      <ProjectPdfSidebar onClose={ () => navigate(sharePath) } />
      <div>
        <div>
          <div>
            <h1>{ project.name }</h1>
            <span>{ t(Labels.viewProjectPdf) }</span>
          </div>
          <div>
            <button onClick={ downloadPdf }>
              DOWNLOAD ICON
              <span>{ t(Labels.viewProjectPdfDownload) }</span>
            </button>
          </div>
        </div>
        <div>
          {/* Pdf viewer */ }
        </div>
      </div>
    </div>
  );
};

export default ProjectPdf;
