import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const lengthMeterCalculation = (productPrice: ProductPrice, distance: number, lengthMeter: number): OfferLine | undefined => {
  if (lengthMeter <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(lengthMeter);
  const formattedLengthMeter = formatters.formatLengthMeter(lengthMeter);
  const unitPrice = formatters.formatPricePerLengthMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, formattedLengthMeter, unitPrice, totalPrice);
};

export default lengthMeterCalculation;
