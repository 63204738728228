import { Address } from '../../common/domain/types';
import { CompanyContact } from '../../company/domain/types';
import { Configuration } from '../configurator/domain/types';
import { Money } from '../offer';

export enum ProjectLanguage {
  nl_be = 'nl_be',
  nl_nl = 'nl_nl',
  fr = 'fr',
  en = 'en',
}

export enum ProjectStatus {
  ongoing = 'ongoing',
  sent = 'sent',
  rejected = 'rejected',
  sold = 'sold',
  postponed = 'postponed',
}

export type Project = {
  id: string;
  name: string;
  status: ProjectStatus;
  address: Address;
  companyId: string;
  contact: CompanyContact;
  language: ProjectLanguage;
  priceListId: string;
  distance: number; // in meter
  acreage: string | null;
  latestUpdate: Date;
  configuration: Configuration | null;
  offer: OfferPdf | null;
  mailSettings: MailSettings;
  visuals: string[];
  floorPlans: string[];
  thumbnail: string | null;
  discount: Money | null;
};

export type ProjectSummary = {
  id: string;
  name: string;
  status: ProjectStatus;
  address: Address;
  companyId: string;
  contact: CompanyContact;
  acreage: string | null;
  thumbnail: string | null;
};

export type OfferPdf = {
  id: string;
  priceList: string;
  number: string;
  offerHash: string;
  pdfs: {
    en: string;
    nl_be: string;
    nl_nl: string;
    fr: string;
  };
};

export type MailSettings = {
  emailAddresses: Email[];
  message: string;
};

export type Email = {
  email: string;
};
