import ReactModal from 'react-modal';
import { Labels, useTranslation } from '../../i18n';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => any;
  className?: string;
  title: string;
  message: string;
  onConfirm: () => any;
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const t = useTranslation();

  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className={ props.className }
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner" data-cy="confirmationModal">
        <div className="modal__content">
          <h2 className="modal__title">{ props.title }</h2>
          <div className="modal__text">
            <p>{ props.message }</p>
          </div>

          <div className="modal__confirm">
            <button type="button" onClick={ props.onConfirm } data-cy="confirmCta">
              <span className="text">{ t(Labels.confirmationModalConfirm) }</span>
            </button>
          </div>

          <CloseButton onClose={ props.onClose } />

          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose } data-cy="closeCta">
        { t(Labels.createProjectModalClose) }
      </button>
    </div>
  );
};

export default ConfirmationModal;
