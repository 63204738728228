const LOGIN = '/login';
const HOME = '/';
const PROJECTS = '/projects';
const PROJECT_DETAILS = '/projects/:projectId';
const PROJECT_DETAILS_EDIT = '/projects/:projectId/';
const PROJECT_DETAILS_VISUALS = '/projects/:projectId/visuals';
const PROJECT_DETAILS_SHARE = '/projects/:projectId/share';
const PROJECT_DETAILS_CONFIGURATOR = '/projects/:projectId/configurator';
const PROJECT_VIEW_PDF = '/projects/:projectId/pdf';

const routes = {
  HOME,
  LOGIN,
  PROJECTS,
  PROJECT_DETAILS,
  PROJECT_DETAILS_EDIT,
  PROJECT_DETAILS_VISUALS,
  PROJECT_DETAILS_SHARE,
  PROJECT_DETAILS_CONFIGURATOR,
  PROJECT_VIEW_PDF,
};

export default routes;
