import { MoneyApiDto } from './../../common/data/types';
import { Configuration, Material, Mezzanine, Office, WarehousePricingInfo } from './../configurator/domain/types';
import { MailSettings, OfferPdf } from './../domain/types';
import { Project, ProjectSummary } from '../domain/types';
import { ProjectDto, ProjectSummaryDto, OfferPdfDto, MailSettingsDto } from './types';
import { Money } from '../offer';

// Project
const mapProjectDtoToProject = (dto: ProjectDto): Project => {
  return {
    id: dto.id,
    name: dto.name,
    status: dto.status,
    contact: {
      id: dto.contact.id,
      email: dto.contact.email,
      firstName: dto.contact.firstName,
      lastName: dto.contact.lastName,
    },
    companyId: dto.company.id,
    latestUpdate: new Date(dto.updatedAt),
    language: dto.language,
    address: {
      country: dto.country,
      street: castEmptyStringToNull(dto.street),
      number: castEmptyStringToNull(dto.number),
      postalCode: dto.postalCode,
      municipality: dto.municipality,
    },
    distance: dto.distance,
    priceListId: dto.priceList,
    acreage: dto.acreage,
    configuration: dto.configuration ? mapConfigurationDtoToConfiguration(dto.configuration) : null,
    offer: dto.offer ? mapOfferPdfDtoToOfferPdf(dto.offer) : null,
    mailSettings: mapMailSettingsDtoToMailSettings(dto.mailSettings),
    visuals: dto.visuals,
    floorPlans: dto.floorPlans,
    thumbnail: dto.thumbnail,
    discount: dto.discount ? mapDiscount(dto.discount) : { amount: 0, currency: 'EUR' },
  };
};

const mapProjectSummaryDtoToProjectSummary = (dto: ProjectSummaryDto): ProjectSummary => {
  return {
    id: dto.id,
    name: dto.name,
    status: dto.status,
    address: {
      country: dto.country,
      street: castEmptyStringToNull(dto.street),
      number: castEmptyStringToNull(dto.number),
      postalCode: dto.postalCode,
      municipality: dto.municipality,
    },
    companyId: dto.company.id,
    contact: {
      id: dto.contact.id,
      email: dto.contact.email,
      firstName: dto.contact.firstName,
      lastName: dto.contact.lastName,
    },
    acreage: dto.acreage,
    thumbnail: dto.thumbnail,
  };
};

// Subfields
const mapConfigurationDtoToConfiguration = (configDto: any): Configuration | null => {
  try {
    const pricingInfo = configDto.pricingInfo;
    return {
      metadata: configDto.metadata,
      extraOptions: {
        concreteFloor: {
          label: configDto.extraOptions.concreteFloor.label || '',
          value: configDto.extraOptions.concreteFloor.value || '',
        },
        foundation: {
          label: configDto.extraOptions.foundation.label || '',
          value: configDto.extraOptions.foundation.value || '',
        },
        soil: {
          label: configDto.extraOptions.soil.label || '',
          value: configDto.extraOptions.soil.value || '',
        },
        techniques: {
          label: configDto.extraOptions.techniques.label || '',
          value: configDto.extraOptions.techniques.value || '',
        },
      },
      pricingInfo: {
        general: {
          measurements: {
            areaSite: pricingInfo.general.measurements.areaSite,
            perimeterSite: pricingInfo.general.measurements.perimeterSite,
            areaGreen: pricingInfo.general.measurements.areaGreen,
            areaConcreteExterior: pricingInfo.general.measurements.areaConcreteExterior,
            areaRoad: pricingInfo.general.measurements.areaRoad,
          },
          soil: pricingInfo.general.soil,
          materials: pricingInfo.general.materials,
        },
        warehouses: pricingInfo.warehouses.map((warehouse: any): WarehousePricingInfo => ({
          name: warehouse.name,
          concreteFloor: warehouse.concreteFloor,
          techniques: warehouse.techniques,
          foundation: warehouse.foundation,
          measurements: {
            area: warehouse.measurements.area,
            perimeter: warehouse.measurements.perimeter,
            height: warehouse.measurements.height,
            width: warehouse.measurements.width,
            length: warehouse.measurements.length,
            lenghtFireWall: warehouse.measurements.lenghtFireWall,
          },
          mezzanines: warehouse.mezzanines.map((mezzanine: any): Mezzanine => ({
            area: mezzanine.area,
            perimeter: mezzanine.perimeter,
            width: mezzanine.width,
            length: mezzanine.length,
          })),
          offices: warehouse.offices.map((office: any): Office => ({
            area: office.area,
            perimeter: office.perimeter,
            width: office.width,
            length: office.length,
            groundFloor: office.groundFloor,
          })),
          materials: warehouse.materials.map((material: any): Material => ({
            sku: material.sku,
            amount: material.amount,
          })),
        })),
      },
    };
  } catch (e) {
    console.error('Received invalid configuration, returning null');
    return null;
  }
};

const mapOfferPdfDtoToOfferPdf = (dto: OfferPdfDto): OfferPdf => {
  return {
    ...dto,
  };
};

const mapMailSettingsDtoToMailSettings = (dto: MailSettingsDto): MailSettings => {
  return {
    ...dto,
  };
};

const mapDiscount = (dto: MoneyApiDto): Money => {
  return {
    amount: parseFloat(dto.amount),
    currency: dto.currency,
  };
};

// Helper
const castEmptyStringToNull = (string: string | null) => {
  return string?.trim() === ''
    ? null
    : string;
};

const projectMapper = {
  mapProjectDtoToProject,
  mapProjectSummaryDtoToProjectSummary,
  mapOfferPdfDtoToOfferPdf,
};

export default projectMapper;
