import ReactModal from 'react-modal';
import { Labels, useTranslation } from '../../i18n';

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => any;
  className?: string;
  imageUrl: string;
  alt?: string;
};
const ImageModal = (props: ImageModalProps) => {
  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className={ props.className }
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner">
        <div className="modal__content">
          <div className="modal__text">
            <img src={ props.imageUrl } alt={ props.alt } />
          </div>

          <CloseButton onClose={ props.onClose } />

          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose }>
        { t(Labels.createProjectModalClose) }
      </button>
    </div>
  );
};

export default ImageModal;
