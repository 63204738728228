type TextFieldProps = {
  identifier: string; //To use as html#id
  value: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
  hideAsterisk?: boolean;
};

export function TextField(props: TextFieldProps) {

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.readonly) {
      return;
    }
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div>
      {
        props.label && (
          <label htmlFor={ props.identifier }>
            { props.label }
            { props.required && !props.hideAsterisk && (
              <span> *</span>
            ) }
          </label >
        )
      }
      <input
        type={ props.type ?? 'text' }
        id={ props.identifier }
        name={ props.identifier }
        value={ props.value }
        onChange={ onChange }
        placeholder={ props.placeholder }
        disabled={ props.disabled }
        required={ props.required }
      />
    </div>
  );
}
