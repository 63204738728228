import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconHamburger, IconHome, IconClose, IconEdit, IconView, IconShare } from '../../assets/icons';
import { Labels, useTranslation } from '../../i18n';
import { routes } from '../../navigation';
import { UserSettings } from '../../settings';

import styles from './Sidebar.module.scss';

type MainSidebarProps = {
  dashboardSelected?: boolean;
  projectsSelected?: boolean;
};

export const MainSidebar = (props: MainSidebarProps) => {
  return (
    <nav className={ styles.sidebar }>
      <DashboardTab
        selected={ props.dashboardSelected ?? false }
      />
      <ProjectsTab
        selected={ props.projectsSelected ?? false }
      />
      <UserSettings />
    </nav>
  );
};

type ProjectSidebarProps = {
  onClose: () => void;
  editSelected?: boolean;
  visualsSelected?: boolean;
  shareSelected?: boolean;
  onEditClick: () => void;
  onVisualsClick: () => void;
  onShareClick: () => void;
};

export const ProjectSidebar = (props: ProjectSidebarProps) => {
  return (
    <nav className={ styles.sidebar }>
      <CloseButton
        onClick={ props.onClose }
      />
      <EditTab
        selected={ props.editSelected ?? false }
        onClick={ props.onEditClick }
      />
      <VisualsTab
        selected={ props.visualsSelected ?? false }
        onClick={ props.onVisualsClick }
      />
      <ShareTab
        selected={ props.shareSelected ?? false }
        onClick={ props.onShareClick }
      />
    </nav >
  );
};

type ProjectPdfSidebarProps = {
  onClose: () => void;
};
export const ProjectPdfSidebar = (props: ProjectPdfSidebarProps) => {
  return (
    <nav className={ styles.sidebar }>
      <CloseButton
        onClick={ props.onClose }
      />
    </nav >
  );
};


type SelectableTabProps = {
  selected: boolean;
};

const DashboardTab = ({ selected }: SelectableTabProps) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const onClick = () => {
    navigate(routes.HOME);
  };

  return (
    <SidebarTab
      selected={ selected }
      icon={ <IconHome /> }
      title={ t(Labels.sidebarDashboardTab) }
      onClick={ onClick }
    />
  );
};


const ProjectsTab = ({ selected }: SelectableTabProps) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const onClick = () => {
    navigate(routes.PROJECTS);
  };

  return (
    <SidebarTab
      selected={ selected }
      icon={ <IconHamburger /> }
      title={ t(Labels.sidebarProjectsTab) }
      onClick={ onClick }
    />
  );
};

type CloseButtonProps = {
  onClick: () => void;
};

const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className={ styles.closeBtn + ' ' + styles.sidebarTab }>
      <button className={ styles.sidebarBtn } onClick={ props.onClick }>
        <IconClose />
        <span>{ t(Labels.sidebarCloseBtn) }</span>
      </button>
    </div>
  );
};

type ProjectTabProps = SelectableTabProps & {
  onClick: () => void;
};

const EditTab = (props: ProjectTabProps) => {
  const t = useTranslation();

  return (
    <SidebarTab
      selected={ props.selected }
      icon={ <IconEdit /> }
      title={ t(Labels.sidebarEditTab) }
      onClick={ props.onClick }
    />
  );
};

const VisualsTab = (props: ProjectTabProps) => {
  const t = useTranslation();

  return (
    <SidebarTab
      selected={ props.selected }
      icon={ <IconView /> }
      title={ t(Labels.sidebarVisualsTab) }
      onClick={ props.onClick }
    />
  );
};

const ShareTab = (props: ProjectTabProps) => {
  const t = useTranslation();

  return (
    <SidebarTab
      selected={ props.selected }
      icon={ <IconShare /> }
      title={ t(Labels.sidebarShareTab) }
      onClick={ props.onClick }
    />
  );
};

type SidebarTabProps = {
  selected: boolean;
  icon: React.ReactElement;
  title: string;
  onClick: () => void;
};

const SidebarTab = (props: SidebarTabProps) => {
  return (
    <div
      className={ styles.sidebarTab }
      onClick={ props.onClick }
    >
      <button className={ styles.sidebarBtn } data-cy="sidebarButton" data-selected={ props.selected }>
        { React.cloneElement(props.icon) }
        <span>{ props.title }</span>
      </button>
    </div>
  );
};
