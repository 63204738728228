import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const warehousePerimeterCalculation = (productPrice: ProductPrice, distance: number, warehousePerimeter: number): OfferLine | undefined => {
  if (warehousePerimeter <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(warehousePerimeter);
  const quantity = formatters.formatMeter(warehousePerimeter);
  const unitPrice = formatters.formatPricePerMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default warehousePerimeterCalculation;
