import { useEffect, useRef, useState } from 'react';
import { Configuration } from '../domain/types';

export type ConfiguratorBridgeListener = {
  onLoaded: () => void;
  onGetPrice: () => void;
  onBack: () => void;
  onUpdateConfiguration: (configuration: Configuration) => void;
  onUpdateImages: (images: string[]) => void; // Images are base64 encoded strings
  onUpdateFloorPlans: (images: string[]) => void; // Images are base64 encoded strings
};

type ConfigurationBridgeConnector = {
  addListener: (listener: ConfiguratorBridgeListener) => void;
  removeListener: (listener: ConfiguratorBridgeListener) => void;
  onMessage: (event: MessageEvent<ConfiguratorMessage>) => void;
};

type ConfiguratorCommands = {
  setPrice: (price: string) => void;
  loadConfiguration: (configuration: Configuration) => void;
};

export type ConfiguratorBridge = ConfiguratorCommands & ConfigurationBridgeConnector;

type ConfiguratorMessage = {
  action: string;
  data: any;
};

const createBridge = (
  postCommand: (message: ConfiguratorMessage) => void
): ConfiguratorBridge => {
  const listeners: ConfiguratorBridgeListener[] = [];

  const addListener = (listener: ConfiguratorBridgeListener) => {
    listeners.push(listener);
  };

  const removeListener = (listener: ConfiguratorBridgeListener) => {
    const index = listeners.indexOf(listener);
    if (index > -1) {
      listeners.splice(index, 1);
    }
  };

  const onMessage = (event: MessageEvent<ConfiguratorMessage>) => {
    const { action, data } = event.data;
    switch (action) {
      case 'onLoaded':
        listeners.forEach((listener) => listener.onLoaded());
        break;
      case 'getPrice':
        listeners.forEach((listener) => listener.onGetPrice());
        break;
      case 'onBack':
        listeners.forEach((listener) => listener.onBack());
        break;
      case 'onUpdateConfiguration':
        listeners.forEach((listener) => listener.onUpdateConfiguration(data));
        break;
      case 'onUpdateImages':
        listeners.forEach((listener) => listener.onUpdateImages(data));
        break;
      case 'onUpdateFloorPlans':
        listeners.forEach((listener) => listener.onUpdateFloorPlans(data));
        break;
      default:
        console.log('Unknown action', action, data);
    }
  };

  // commands
  const setPrice = (price: string) => {
    postCommand({ action: 'setPrice', data: price });
  };

  const loadConfiguration = (configuration: Configuration) => {
    postCommand({ action: 'loadConfiguration', data: configuration });
  };

  const connector = {
    addListener,
    removeListener,
    onMessage,
  };

  const commands = {
    setPrice,
    loadConfiguration,
  };

  return {
    ...commands,
    ...connector,
  };
};

const useConfiguratorBridge = () => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [bridge, setBridge] = useState<ConfiguratorBridge | null>(null);

  useEffect(() => {
    const postCommand = (message: ConfiguratorMessage) => {
      if (ref.current) {
        ref.current.contentWindow?.postMessage(message, '*');
      }
    };
    const bridge = createBridge(postCommand);

    window.addEventListener('message', bridge.onMessage, false);
    setBridge(bridge);
    return () => {
      window.removeEventListener('message', bridge.onMessage);
    };
  }, []);

  return {
    ref,
    bridge,
  };
};

export {
  useConfiguratorBridge,
};
