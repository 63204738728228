import { useTranslation, Labels, formatters } from '../../../../i18n';
import { OfferSection } from '../../../offer';
import { Offer, GeneralDetails, GeneralCategory, WarehouseDetails, WarehouseCategory } from '../../../offer/domain/types';
import AccordionItem from './Accordion';
import { useEffect, useState } from 'react';
import { NumberField } from '../../../../common/ui/form/NumberField';
import { Label } from './EditProject';
import styles from './OfferOverview.module.scss';
import { useViewModel } from '../../../domain/ProjectDetailViewModel';
import { useDebounce } from 'react-use';
import { AppConfig } from '../../../../config';
import ImageModal from '../../../../common/ui/ImageModal';

interface OfferOverviewProps {
  offer: Offer;
}
const OfferOverview = ({ offer }: OfferOverviewProps) => {
  return (
    <>
      <li key={ 'offer-general-details' }><GeneralDetailsAccordion details={ offer.general.details } /></li>
      <li key={ 'offer-general-materials' }><GeneralMaterialsAccordion general={ offer.general } /></li>

      { offer.warehouses.flatMap((warehouse) => ([
        <li key={ `offer-warehouse-details-${warehouse.name}` }>
          <WarehouseDetailsAccordion nameLabelKey={ warehouse.name } details={ warehouse.details } />
        </li>,
        <li key={ `offer-warehouse-materials-${warehouse.name}` }>
          <WarehouseMaterialsAccordion nameLabelKey={ warehouse.name } warehouse={ warehouse } />
        </li>,
      ])) }

      <li key={ 'offer-total-cost' }><TotalCostAccordion offer={ offer } /></li>
      <li key={ 'offer-commercial-discount' }><CommercialDiscountAccordion offer={ offer } /></li>
    </>
  );
};

// Accordions
type OfferSectionAccordionProps = {
  offerSection: OfferSection;
};
const OfferSectionAccordion = ({ offerSection }: OfferSectionAccordionProps) => {
  const t = useTranslation();

  return (
    <div className={ styles.accordionContentItem }>
      <h2 className={ styles.accordionContentTitle }>{ offerSection.title }</h2>
      <table className={ styles.accordionContentTable }>
        <thead>
          <tr>
            <th></th>
            <th>{ t(Labels.editProjectOfferMaterialName) }</th>
            <th>{ t(Labels.editProjectOfferMaterialQuantity) }</th>
            <th>{ t(Labels.editProjectOfferMaterialPricePp) }</th>
            <th>{ t(Labels.editProjectOfferMaterialTotal) }</th>
          </tr>
        </thead>
        <tbody>
          { offerSection.lines.map(({ sku, title, quantity, unitPrice, totalPrice }) => (
            <tr key={ `line-${sku}` }>
              <td><SkuImage sku={ sku } alt={ title } /></td>
              <td>{ title }</td>
              <td>{ quantity }</td>
              <td>{ unitPrice }</td>
              <td>{ formatters.formatMoney(totalPrice) }</td>
            </tr>
          )) }
          <tr>
            <td className={ styles.accordionContentTotal } colSpan={ 4 }>{ t(Labels.editProjectOfferMaterialTotal) }</td>
            <td className={ styles.accordionContentTotal }>{ formatters.formatMoney(offerSection.totalPrice) }</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

type GeneralDetailsAccordionProps = {
  details: GeneralDetails;
};
const GeneralDetailsAccordion = ({ details }: GeneralDetailsAccordionProps) => {
  const t = useTranslation();

  return (
    <AccordionItem
      identifier='offer-general-details'
      title={ t(Labels.editProjectOfferGeneralDetails) }
      header={ <p>{ t(Labels.editProjectOfferSectionDetails) }</p> }
    >
      <Label title={ t(Labels.editProjectOfferGeneralDetailsSurface) } value={ details.areaSite } />
      <Label title={ t(Labels.editProjectOfferGeneralDetailsGreenArea) } value={ details.areaGreen } />
      <Label title={ t(Labels.editProjectOfferGeneralDetailsConcreteArea) } value={ details.areaConcreteExterior } />
      <Label title={ t(Labels.editProjectOfferGeneralDetailsRoadArea) } value={ details.areaRoad } />
      <Label title={ t(Labels.editProjectOfferGeneralDetailsPerimeter) } value={ details.perimeterSite } />
    </AccordionItem>
  );
};

interface GeneralMaterialsAccordionProps {
  general: GeneralCategory;
}
const GeneralMaterialsAccordion = ({ general }: GeneralMaterialsAccordionProps) => {
  const t = useTranslation();

  const offerSections: OfferSection[] = [
    general.preparatoryWorks,
    general.groundworks,
    general.environmentWorks,
  ];

  const filledSections = offerSections.filter((section) => section.lines.length !== 0);

  return (
    <AccordionItem
      identifier={ 'offer-general-materials' }
      title={ t(Labels.editProjectOfferGeneralDetails) }
      header={ <p>{ t(Labels.editProjectOfferSectionMaterials) }</p> }
    >
      { filledSections.length === 0
        ? <p>{ t(Labels.editProjectOfferWarehouseNoMaterials) }</p>
        : filledSections.map((section) => (
          <OfferSectionAccordion key={ section.title } offerSection={ section } />
        )) }
    </AccordionItem>
  );
};

type WarehouseDetailsAccordionProps = {
  nameLabelKey: string;
  details: WarehouseDetails;
};
const WarehouseDetailsAccordion = ({ nameLabelKey, details }: WarehouseDetailsAccordionProps) => {
  const t = useTranslation();

  return (
    <AccordionItem
      identifier={ `offer-warehouse-details-${nameLabelKey}` }
      title={ t(nameLabelKey) }
      header={ <p>{ t(Labels.editProjectOfferSectionDetails) }</p> }
    >
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsFootprint) } value={ details.area } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsPerimeter) } value={ details.perimeter } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsHeight) } value={ details.height } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsWidth) } value={ details.width } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsLength) } value={ details.length } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsMezzanines) } value={ details.totalMezzanineArea } />
      <Label title={ t(Labels.editProjectOfferWarehouseDetailsOffices) } value={ details.totalOfficeArea } />
    </AccordionItem>
  );
};

type WarehouseMaterialsAccordionProps = {
  nameLabelKey: string;
  warehouse: WarehouseCategory;
};
const WarehouseMaterialsAccordion = ({ nameLabelKey, warehouse }: WarehouseMaterialsAccordionProps) => {
  const t = useTranslation();

  const offerSections: OfferSection[] = [
    warehouse.preparatoryWorks,
    warehouse.groundworks,
    warehouse.foundation,
    warehouse.structure,
    warehouse.walls,
    warehouse.flatRoofs,
    warehouse.roofLighting,
    warehouse.rainwaterDrainage,
    warehouse.sewageSystem,
    warehouse.flooring,
    warehouse.gatesAndQuayEquipment,
    warehouse.exteriorJoinery,
    warehouse.techniques,
    warehouse.environmentalWorks,
    warehouse.mezzanines,
    warehouse.offices,
  ];

  const filledSections = offerSections.filter((section) => section.lines.length !== 0);

  return (
    <AccordionItem
      identifier={ `offer-warehouse-materials-${nameLabelKey}` }
      title={ t(nameLabelKey) }
      header={ <p>{ t(Labels.editProjectOfferSectionMaterials) }</p> }
    >
      { filledSections.length === 0
        ? <p>{ t(Labels.editProjectOfferWarehouseNoMaterials) }</p>
        : filledSections.map((section) => (
          <OfferSectionAccordion key={ section.title } offerSection={ section } />
        )) }
    </AccordionItem>
  );
};

interface TotalCostAccordionProps {
  offer: Offer;
}
const TotalCostAccordion = ({ offer }: TotalCostAccordionProps) => {
  const t = useTranslation();

  return (
    <AccordionItem
      identifier={ 'offer-total-cost' }
      title={ t(Labels.editProjectOfferTotalCost) }
    >
      <div className={ styles.accordionContentItem }>
        <table className={ styles.accordionContentTable }>
          <thead>
            <tr>
              <th>{ t(Labels.editProjectOfferMaterialName) }</th>
              <th>{ t(Labels.editProjectOfferMaterialTotal) }</th>
            </tr>
          </thead>
          <tbody>
            <tr key={ 'general' }>
              <td>{ t(Labels.mainCategoryGeneral) }</td>
              <td>{ formatters.formatMoney(offer.general.totalPrice) }</td>
            </tr>
            { offer.warehouses.map((warehouse) => (
              <tr key={ `warehouse-${warehouse.name}` }>
                <td>{ warehouse.name }</td>
                <td>{ formatters.formatMoney(warehouse.totalPrice) }</td>
              </tr>
            )) }
            { offer.pricing.discount.amount !== 0 &&
              <tr>
                <td>{ t(Labels.editProjectOfferCommercialDiscount) }</td>
                <td>{ formatters.formatMoney(offer.pricing.discount) }</td>
              </tr> }
            <tr>
              <td>{ t(Labels.editProjectOfferTotalCost) }</td>
              <td>{ formatters.formatMoney(offer.pricing.totalPrice) }</td>
            </tr>
          </tbody>
        </table>
        <h1></h1>
      </div>
    </AccordionItem>
  );
};

interface CommercialDiscountAccordionProps {
  offer: Offer;
}
const CommercialDiscountAccordion = ({ offer }: CommercialDiscountAccordionProps) => {
  const t = useTranslation();
  const { updateDiscount } = useViewModel();

  const [discount, setDiscount] = useState<number>(offer.pricing.discount.amount);
  useDebounce(
    () => {
      updateDiscount({ amount: discount, currency: 'EUR' });
    },
    500,
    [discount]
  );

  const onChangeCommercialDiscount = (discount: number) => {
    if (Number.isNaN(discount) || discount < 0) {
      setDiscount(0);
      return;
    }

    const subTotal = offer.pricing.subTotal.amount;
    if (discount > subTotal) { // The discount cannot be more than the subTotal
      setDiscount(subTotal);
      return;
    }

    setDiscount(discount);
  };

  return (
    <AccordionItem
      identifier={ 'offer-commercial-discount' }
      // title={ t(Labels.editProjectOfferCommercialDiscount) }
      title={ t(Labels.editProjectOfferAdmin) }
    >
      <div className={ styles.accordionContentForm }>
        <NumberField
          identifier={ 'commercial-discount' }
          value={ discount }
          label={ t(Labels.editProjectOfferCommercialDiscount) }
          onChange={ onChangeCommercialDiscount }
          step={ 100 }
        />
      </div>
    </AccordionItem>
  );
};

interface SkuImageProps {
  sku: string;
  alt?: string;
}
const SkuImage = ({ sku, alt }: SkuImageProps) => {
  const [imageExists, setImageExists] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const closeModal = () => setImageModalOpen(false);
  const showModal = () => setImageModalOpen(true);

  const skuImageurl = `${AppConfig.API_BASE_URL}/product-image/${sku}`;

  useEffect(() => {
    fetch(skuImageurl, { method: 'HEAD' })
      .then((res) => setImageExists(res.ok));
  });

  return imageExists
    ? (<>
      <ImageModal
        isOpen={ imageModalOpen }
        onClose={ closeModal }
        className={ 'modal modal--image' }
        imageUrl={ skuImageurl }
        alt={ alt }
      />
      <button className={ styles.modalButton } onClick={ showModal }>
        <img className={ styles.modalThumb } src={ skuImageurl } alt={ sku } />
      </button>
    </>)
    : (<></>);
};

export default OfferOverview;
