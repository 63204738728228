import { Company, CompanyContact } from '../../domain/types';
import { CompanyContactDto, CompanyDto } from './types';

const mapCompanyDtoToCompany = (companyDto: CompanyDto): Company => {
  return {
    id: companyDto.id,
    name: companyDto.name,
    logo: companyDto.logo,
    companyNumber: companyDto.companyNumber,
    address: mapAddress(companyDto),
    telephoneNumber: companyDto.telephoneNumber,
    companyContacts: companyDto.companyContacts.map(mapCompanyContactDtoToCompanyContact),
  };
};

const mapCompanyContactDtoToCompanyContact = (companyContactDto: CompanyContactDto): CompanyContact => {
  return {
    id: companyContactDto.id,
    firstName: companyContactDto.firstName,
    lastName: companyContactDto.lastName,
    email: companyContactDto.email,
  };
};

const mapAddress = (companyDto: CompanyDto): string => {
  return `${companyDto.street} ${companyDto.number}, ${companyDto.postalCode} ${companyDto.municipality}`;
};

const companyMapper = {
  mapCompanyDtoToCompany,
};

export default companyMapper;
