import React from 'react';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ProjectSidebar } from '../../../common/ui/Sidebar';
import { routes } from '../../../navigation';
import useProjectDetailViewModel from '../../domain/ProjectDetailViewModel';

import styles from './ProjectDetail.module.scss';

const ProjectDetail = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error('projectId is required!');

  const viewModel = useProjectDetailViewModel(projectId);

  return (
    <div className={ styles.project }>
      <ProjectNavigation projectId={ projectId } />
      <Outlet context={ viewModel } />
    </div>
  );
};

type ProjectNavigationProps = {
  projectId: string;
  children?: React.ReactNode;
};

const ProjectNavigation = ({ projectId }: ProjectNavigationProps) => {
  const path = useLocation().pathname!;

  const editPath = generatePath(routes.PROJECT_DETAILS_EDIT, { projectId });
  const visualsPath = generatePath(routes.PROJECT_DETAILS_VISUALS, { projectId });
  const sharePath = generatePath(routes.PROJECT_DETAILS_SHARE, { projectId });
  const configuratorPath = generatePath(routes.PROJECT_DETAILS_CONFIGURATOR, { projectId });

  const editSelected = path == editPath;
  const visualsSelected = path == visualsPath;
  const shareSelected = path == sharePath;
  const configuratorSelected = path == configuratorPath;

  const navigate = useNavigate();
  const navigateToEdit = () => navigate(editPath);
  const navigateToVisuals = () => navigate(visualsPath);
  const navigateToShare = () => navigate(sharePath);

  const hideSidebar = configuratorSelected;

  if (hideSidebar) return <></>;

  return (
    <ProjectSidebar
      onClose={ () => { navigate(routes.PROJECTS); } }
      editSelected={ editSelected }
      visualsSelected={ visualsSelected }
      shareSelected={ shareSelected }
      onEditClick={ navigateToEdit }
      onVisualsClick={ navigateToVisuals }
      onShareClick={ navigateToShare }
    />
  );
};

export default ProjectDetail;
