import { Company } from '../../domain/types';
import { CompanyApi } from './companyApi';
import { apiClient } from '../../../common';
import { CompanyDto, CompaniesApiResponseScheme } from './types';
import companyMapper from './mapper';

const companiesEndpoint = '/api/companies';

const getCompanies = async (): Promise<Company[]> => {
  try {
    const response = await apiClient.get<CompanyDto[]>(companiesEndpoint);
    const dtoList = CompaniesApiResponseScheme.parse(response.data);
    return dtoList.map(companyMapper.mapCompanyDtoToCompany);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const companyApiImpl: CompanyApi = {
  getCompanies,
};

export default companyApiImpl;
