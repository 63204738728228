import { useEvent } from 'react-use';

export function useFocus(
  wrapperRef: React.RefObject<HTMLElement>,
  onFocusLost: () => any
) {

  useEvent('mousedown', (event) => {
    const clickedNode = event.target as Node;
    if (!wrapperRef.current?.contains(clickedNode)) {
      onFocusLost();
    }
  });

  return [];
}
