import React from 'react';
import { IconChecked } from '../../assets/icons';
import { Labels, useTranslation } from '../../i18n';
import { Company } from '../domain/types';
import { useCompany } from './CompanyProvider';

import styles from './CompanyList.module.scss';

type CompanyListProps = {
  onSelect: (id: string | null) => void;
};

const CompanyList = (props: CompanyListProps) => {
  const [selectedCompany, setCompanyId, companies] = useCompany();
  const t = useTranslation();

  const onSelect = (id: string | null) => {
    setCompanyId(id);
    props.onSelect(id);
  };

  const companyItems = [
    <CompanyItem
      key={ 'all' }
      selected={ selectedCompany === null }
      onSelect={ () => onSelect(null) }
      name={ t(Labels.companyFilterAllCompanies) }
    />,
    ...createCompanyItems(selectedCompany, onSelect, companies),
  ];

  return (
    <div className={ styles.wrapper }>
      <ul className={ styles.list } data-cy="companyFilterList">
        { companyItems }
      </ul>
    </div>
  );
};

type CompanyItemProps = {
  selected: boolean;
  onSelect: () => void;
  name: string;
};

const CompanyItem = (props: CompanyItemProps) => {
  return (
    <li
      data-cy="companyFilterItem"
      onClick={ props.onSelect }
      className={ styles.item }
    >
      <button type="button">
        <span className={ styles.text }>{ props.name }</span>
        <span className={ styles.icon }>{ props.selected && <IconChecked /> }</span>
      </button>
    </li>
  );
};

const createCompanyItems = (
  selectedCompany: Company | null,
  onSelect: (id: string | null) => void,
  companies: Company[]
): React.ReactNode[] => {
  return companies.map((company) => (
    <CompanyItem
      key={ company.id }
      selected={ company.id === selectedCompany?.id }
      onSelect={ () => onSelect(company.id) }
      name={ company.name }
    />
  ));
};


export default CompanyList;
