import { formatters } from '../../../../../i18n';
import { ProductPrice } from '../../../../../prices';
import offerMapper from '../../offerMapper';
import { OfferLine } from '../../types';
import { getDistancePrice } from '../priceFinders';

const roadAreaCalculation = (productPrice: ProductPrice, distance: number, roadArea: number): OfferLine | undefined => {
  if (roadArea <= 0) return;

  const distancePrice = getDistancePrice(distance, productPrice.prices);

  const totalPrice = distancePrice.multipliedBy(roadArea);
  const quantity = formatters.formatSquareMeter(roadArea);
  const unitPrice = formatters.formatPricePerSquareMeter(distancePrice);
  return offerMapper.constructOfferLine(productPrice, quantity, unitPrice, totalPrice);
};

export default roadAreaCalculation;
