// Example response from the API for Company: 
// {
//   "id": "1BuGoNcwPLQTX78RcBHmEt",
//   "name": "Nascom",
//   "logo": "https://willy-naessens-the-circle-backoffice-acc.makeitfly-ops.net/media/company/05862ffb5e11ec61efdb6a376c5467cc.png",
//   "street": "C-mine",
//   "number": "129",
//   "postalCode": "3600",
//   "municipality": "Genk",
//   "country": "BE",
//   "telephoneNumber": "089/44.01.40",
//   "companyNumber": "BE0646.949.022",
//   "companyContacts": [
//       {
//           "id": "1BuGoNcx3vYQC45iAXiDZ4",
//           "firstName": "Wim",
//           "lastName": "Vandersmissen",
//           "email": "wim@nascom.be"
//       }
//   ],
//   "createdAt": "2023-01-12T17:36:10+01:00",
//   "updatedAt": "2023-01-12T17:36:10+01:00"
// }

import { z } from 'zod';

export const CompanyContactDtoScheme = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

const CompanyDtoScheme = z.object({
  id: z.string(),
  name: z.string(),
  logo: z.string(),
  street: z.string(),
  number: z.string(),
  postalCode: z.string(),
  municipality: z.string(),
  country: z.string(),
  telephoneNumber: z.string(),
  companyNumber: z.string(),
  companyContacts: z.array(CompanyContactDtoScheme),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const CompaniesApiResponseScheme = z.array(CompanyDtoScheme);

export type CompanyContactDto = z.infer<typeof CompanyContactDtoScheme>;
export type CompanyDto = z.infer<typeof CompanyDtoScheme>;

