import Dropdown, { DropdownOption } from '../../../common/ui/form/Dropdown';
import { CompanyContact } from '../../../company/domain/types';

type ContactDropdownProps = {
  selectedContact: CompanyContact;
  label: string;
  contacts: CompanyContact[];
  onChange: (contact: CompanyContact) => any;
  disabled: boolean;
};

function ContactDropdown(props: ContactDropdownProps) {
  const options: DropdownOption[] = props.contacts.map((contact) => ({
    text: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
  }));

  const selectedOption = options.find((option) => option.value === props.selectedContact?.id);

  const onChange = (contactId: string) => {
    const contact = props.contacts.find((contact) => contact.id === contactId)!;
    props.onChange(contact);
  };

  return (
    <Dropdown
      identifier='contact'
      options={ options }
      label={ props.label }
      onChange={ (option) => onChange(option!.value) }
      selectedOption={ selectedOption }
      required={ true }
      disabled={ props.disabled }
    />
  );
}

export default ContactDropdown;
