import { CompanyFilter, useCompany } from '../../../company';
import { Labels, useTranslation } from '../../../i18n';
import CreateProject from '../create/CreateProject';
import styles from './CompanyDetails.module.scss';

// Move to src\company\ui ?
const CompanyDetails = () => {
  const t = useTranslation();
  const [selectedCompany] = useCompany();

  if (!selectedCompany) {
    return <NoCompanySelected />;
  }

  return (
    <div className={ styles.details }>
      <div className={ styles.info }>
        <img src={ selectedCompany.logo } alt={ `Logo ${selectedCompany.name}` } className={ styles.logo } />
        <div className={ styles.header }>
          <h1 className={ styles.title }>{ selectedCompany.name }</h1>
          <CreateProject sidebar={ true } />
        </div>
        <dl className={ styles.list }>
          <ListItem title={ t(Labels.companyDetailsName) } value={ selectedCompany.name } />
          <ListItem title={ t(Labels.companyDetailsAddress) } value={ selectedCompany.address } />
          <ListItem title={ t(Labels.companyDetailsVAT) } value={ selectedCompany.companyNumber } />
        </dl>
      </div>
      <div className={ styles.filter }>
        <CompanyFilter buttonText={ t(Labels.companyDetailsChangeCompanyCta) } dashboard={ false } />
      </div>
    </div>
  );
};


type ListItemProps = {
  title: string;
  value: string;
};
const ListItem = ({ title, value }: ListItemProps) => {
  return (
    <>
      <dt>{ title }</dt>
      <dd>{ value }</dd>
    </>
  );
};

const NoCompanySelected = () => {
  const t = useTranslation();

  return (
    <div className={ styles.details }>
      <div className={ styles.info }>
        <div className={ styles.header }>
          <h1 className={ styles.title }>{ t(Labels.companyDetailsNoCompanySelectedTitle) }</h1>
          <CreateProject sidebar={ true } />
        </div>
      </div>
      <div className={ styles.filter }>
        <CompanyFilter buttonText={ t(Labels.companyDetailsChangeCompanyCta) } dashboard={ false } />
      </div>
    </div >
  );
};

export default CompanyDetails;
