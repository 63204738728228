import { IconArrow, IconEdit, IconView, IconShare } from '../../../assets/icons';
import { generatePath, Link } from 'react-router-dom';
import { Address } from '../../../common/domain/types';
import { Labels, useTranslation } from '../../../i18n';
import { routes } from '../../../navigation';
import { ProjectSummary } from '../../domain/types';
import styles from './ProjectTile.module.scss';

type ProjectTileProps = {
  projectSummary: ProjectSummary;
};

const ProjectTile = ({ projectSummary }: ProjectTileProps) => {
  const thumbnail = projectSummary.thumbnail || '../../img/bg__building.png';

  return (
    <article className={ styles.teaser }>
      <div className={ styles.image }>
        {/* TODO Theming: check size of image */ }
        <img src={ thumbnail } height={ 300 } alt={ `Image ${projectSummary.name}` } />
      </div>

      <div className={ styles.content }>
        <TileHeader project={ projectSummary } />
        <TileBody project={ projectSummary } />
        <CtaButtons project={ projectSummary } />
      </div>
    </article>
  );
};


type TileHeaderProps = {
  project: ProjectSummary;
};
const TileHeader = ({ project }: TileHeaderProps) => {
  return (
    <div className={ styles.header }>
      <h2>{ project.name }</h2>
      <div className={ styles.locationStatus }>
        {
          project.address.municipality &&
          <div className={ styles.location }>{ project.address.municipality }</div>
        }
        <div className={ styles.status }>{ project.status }</div>
      </div>
    </div>
  );
};

type TileBodyProps = {
  project: ProjectSummary;
};
const TileBody = ({ project }: TileBodyProps) => {
  const t = useTranslation();

  const country = Labels[`country_${project.address.country.toLowerCase()}`];
  const hasAddress = project.address.municipality && project.address.number && project.address.postalCode && project.address.street;

  return (
    <ul className={ styles.properties }>
      <ListItem>
        { t(Labels.projectTileName, { name: project.name }) }
      </ListItem>
      {
        project.acreage != null &&
        <ListItem>
          { t(Labels.projectTileSurface, { surface: project.acreage }) }
        </ListItem>
      }
      {
        hasAddress &&
        <ListItem>
          { formatAddress(project.address) }
        </ListItem>
      }
      <ListItem>
        { t(Labels.projectTileCountry, { country }) }
      </ListItem>
    </ul>
  );
};


type ListItemProps = {
  children: React.ReactNode;
};
const ListItem = ({ children }: ListItemProps) => {
  return (
    <li>
      <div>
        <IconArrow />
      </div>
      <div>
        { children }
      </div>
    </li>
  );
};


type CtaButtonsProps = {
  project: ProjectSummary;
};
const CtaButtons = ({ project }: CtaButtonsProps) => {

  const projectId = project.id;
  const editPath = generatePath(routes.PROJECT_DETAILS_EDIT, { projectId });
  const visualsPath = generatePath(routes.PROJECT_DETAILS_VISUALS, { projectId });
  const sharePath = generatePath(routes.PROJECT_DETAILS_SHARE, { projectId });

  return (
    <div className={ styles.ctaButtons }>
      <Link to={ editPath } className={ styles.ctaButtonEdit }>
        <IconEdit />
      </Link>
      <Link to={ visualsPath } >
        <IconView />
      </Link>
      <Link to={ sharePath } >
        <IconShare />
      </Link >
    </div >
  );
};


const formatAddress = ({ street, number, postalCode, municipality }: Address): string => {
  return `${street} ${number}, ${postalCode} ${municipality}`;
};


export default ProjectTile;
