// PriceListDto like
export type PriceList = {
  id: string;
  createdAt: Date;
  productPrices: ProductPrice[];
};

export type ProductPrice = {
  sku: string;
  title: string;
  description: string;
  mainCategory: string;
  subCategory: string;
  unit: string;
  prices: Prices;
};

// TODO maybe use BigNumber.js here
export type Prices = {
  price60: number;
  price100: number;
  price200: number;
  price300: number;
  price400: number;
  priceOther: number;
};

export enum CalculationType {
  QUANTITY = 'st',
  FIXED_PRICE = 'vast',

  TOTAL_AREA = 'm² totaalsite',
  SOIL_AREA = 'm² totaalsite soil',
  GREEN_AREA = 'm² groenzone',
  ROAD_AREA = 'm² wegenis',
  CONCRETE_FLOOR_OUTSIDE_AREA = 'm² betonvloer buiten',
  TOTAL_PERIMETER = 'lm omtrek totaalsite',
  FIREWALL_WIDTH = 'lm brandwand',
  VOLUME = 'm³',

  WAREHOUSE_AREA = 'm² warehouse',
  WAREHOUSE_CONCRETE_FLOOR_AREA = 'm² warehouse concreteFloor',
  WAREHOUSE_TECHNIQUES_AREA = 'm² warehouse techniques',
  WAREHOUSE_HEIGHT = 'lm hoogte warehouse x st',
  WAREHOUSE_PERIMETER = 'lm omtrek warehouse',
  PER_WAREHOUSE = 'per warehouse',

  MEZZANINE_WIDTH = 'lm breedte mezzanine',
  MEZZANINE_AREA = 'm² mezzanine',

  TOTAL_OFFICE_AREA = 'Totale m² kantoren',
  OFFICE_GROUND_FLOOR_AREA = 'm² kantoor op nulpas',
}

export enum MainCategory {
  General = 'General',
  Warehouse = 'Warehouse',
  Office = 'Kantoor',
  Mezzanine = 'Mezzanine',
}

export enum SubCategory {
  PreparatoryWorks = 'Voorbereidende werken',
  Groundworks = 'Grondwerken',
  Foundations = 'Funderingen',
  Structure = 'Structuur',
  Walls = 'Wanden',
  Floors = 'Verdiepingen',
  FlatRoofs = 'Platte daken',
  RoofLighting = 'Dakverlichting',
  RainwaterDrainage = 'Regenwaterafvoer',
  SewageSystem = 'Riolering',
  Flooring = 'Vloeren',
  GatesAndQuayEquipment = 'Poorten en kaaiuitrustingen',
  ExteriorJoinery = 'Buitenschrijnwerk',
  Stairs = 'Trappen',
  Techniques = 'Technieken',
  EnvironmentalWorks = 'Omgevingswerken',
  InteriorFinish = 'Binnenafwerking',
}
