import { useState } from 'react';
import ReactModal from 'react-modal';
import { IconAdd } from '../../../assets/icons';
import { Labels, useTranslation } from '../../../i18n';
import CreateProjectForm from './CreateProjectForm';

import styles from './CreateProject.module.scss';

type CreateProjectProps = {
  sidebar?: boolean;
};

const CreateProject = (props: CreateProjectProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  return (
    <>
      <CreateProjectModal
        isOpen={ modalVisible }
        onClose={ closeModal }
      />
      <CreateProjectButton
        onClick={ openModal }
        sidebar={ props.sidebar }
      />
    </>
  );
};

type CreateProjectButtonProps = {
  onClick: () => void;
  sidebar?: boolean;
};

const CreateProjectButton = (props: CreateProjectButtonProps) => {
  const t = useTranslation();
  return (
    <button
      className={ styles.btn }
      data-cy="createProjectCta"
      onClick={ props.onClick }
      data-create-in-sidebar={ props.sidebar }
    >
      <span className={ styles.btnIcon }><IconAdd /></span>
      <span className={ styles.btnText }>{ t(Labels.createProjectCta) }</span>
    </button>

  );
};


type CreateProjectModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateProjectModal = (props: CreateProjectModalProps) => {
  const t = useTranslation();

  return (
    <ReactModal
      isOpen={ props.isOpen }
      onRequestClose={ props.onClose }
      shouldCloseOnOverlayClick={ true }
      ariaHideApp={ true }
      className="modal modal--create-project"
      overlayClassName="modal-overlay"
    >
      <div className="modal__inner">
        <div className="modal__content">
          <h2 className="modal__title">{ t(Labels.createProjectModalTitle) }</h2>
          <div
            className="modal__form"
          >
            <CreateProjectForm />
          </div>
          <CloseButton onClose={ props.onClose } />
          <div className="modal__lines">
            <img src="img/lines/style4.png" alt="" />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};


type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = (props: CloseButtonProps) => {
  const t = useTranslation();

  return (
    <div className="modal__close">
      <button className="modal__close-btn" onClick={ props.onClose }>
        { t(Labels.createProjectModalClose) }
      </button>
    </div>
  );
};

export default CreateProject;
