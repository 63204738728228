import { authService } from '../../../auth';
import { Labels, useTranslation } from '../../../i18n';
import BackButton from './BackButton';

import styles from './ConfirmLogoutContent.module.scss';

type ConfirmLogoutContentProps = {
  onBack: () => void;
};

const ConfirmLogoutContent = ({ onBack }: ConfirmLogoutContentProps) => {
  const t = useTranslation();
  return (
    <>
      <BackButton onClick={ onBack } />
      <h2 className="modal__title">{ t(Labels.userSettingsLogoutConfirmTitle) }</h2>
      <div className={ styles.logout }>
        <LogoutButton />
      </div>
    </>
  );
};

const LogoutButton = () => {
  const t = useTranslation();
  const logout = () => {
    authService.logout();
  };

  return (
    <button onClick={ logout }>
      <span className={ styles.text }>{ t(Labels.userSettingsLogoutConfirmCta) }</span>
    </button>
  );
};

export default ConfirmLogoutContent;
