import BigNumber from 'bignumber.js';
import { Address } from '../common/domain/types';
import offerMapper from '../projects/offer/domain/offerMapper';
import { Money } from '../projects/offer/domain/types';

const formatAddress = ({ street, number, postalCode, municipality }: Address) => {
  // if all properties are empty, return empty string
  if (!street && !number && !postalCode && !municipality) {
    return '';
  }
  return `${street} ${number},\n${postalCode} ${municipality}`;
};

const formatMoney = ({ amount, currency }: Money) => {
  const format = new Intl.NumberFormat(undefined, { style: 'currency', currency });
  return format.format(amount);
};

// Unit formatters
const formatMeter = (value: number): string => {
  const roundedMeters = Math.round(value * 100) / 100;
  return `${roundedMeters} m`;
};
const formatSquareMeter = (value: number): string => {
  return formatMeter(value) + '²';
};
const formatCubicMeter = (value: number): string => {
  return formatMeter(value) + '³';
};

const formatPricePerMeter = (price: BigNumber): string => {
  const money: Money = offerMapper.mapBigNumberToMoney(price);
  return `${formatMoney(money)}/m`;
};
const formatPricePerSquareMeter = (price: BigNumber): string => {
  return formatPricePerMeter(price) + '²';
};
const formatPricePerCubicMeter = (price: BigNumber): string => {
  return formatPricePerMeter(price) + '³';
};


const formatPiece = (amount: number): string => {
  return `${amount} st`;
};
const formatPricePerPiece = (price: BigNumber): string => {
  const money: Money = offerMapper.mapBigNumberToMoney(price);
  return `${formatMoney(money)}/st`;
};

const formatLengthMeter = (amount: number): string => {
  return `${amount} lm`;
};
const formatPricePerLengthMeter = (price: BigNumber): string => {
  const money: Money = offerMapper.mapBigNumberToMoney(price);
  return `${formatMoney(money)}/lm`;
};

const formatters = {
  formatAddress,
  formatMoney,

  formatMeter,
  formatSquareMeter,
  formatCubicMeter,
  formatPricePerMeter,
  formatPricePerSquareMeter,
  formatPricePerCubicMeter,

  formatPiece,
  formatPricePerPiece,

  formatLengthMeter,
  formatPricePerLengthMeter,
};

export default formatters;
