import react, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import routes from '../../navigation/routes';
import authService from '../domain/authService';

type WrapperProps = {
  children: react.ReactNode;
};

const RequireAuth = ({ children }: WrapperProps) => {
  const loggedIn = authService.isLoggedIn();
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to={ routes.LOGIN } state={ { from: location } } />;
  }

  return (
    <LogoutListener>
      { children }
    </LogoutListener>
  );
};

const LogoutListener = ({ children }: WrapperProps) => {
  const [loggedIn, setLoggedIn] = react.useState(authService.isLoggedIn());

  useEffect(() => {
    const unsubscribe = authService.subscribe(() => {
      setLoggedIn(authService.isLoggedIn());
    });
    return () => {
      unsubscribe();
    };
  }, [setLoggedIn]);

  if (!loggedIn) {
    return <Navigate to={ routes.LOGIN } />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default RequireAuth;
