import BigNumber from 'bignumber.js';
import { ProductPrice } from '../../../prices';
import { Money, Offer, OfferSection, GeneralCategory, GeneralDetails, WarehouseCategory, WarehouseDetails, OfferLine, OfferPricing } from './types';

const constructOffer = (generalCategory: GeneralCategory, warehouseCategories: WarehouseCategory[], pricing: OfferPricing): Offer => {
  return {
    general: generalCategory,
    warehouses: warehouseCategories,
    pricing: pricing,
  };
};

const constructOfferPricing = (subTotal: Money, discount: Money, totalPrice: Money): OfferPricing => {
  return {
    subTotal,
    discount,
    totalPrice,
  };
};

const constructGeneralCategory = (totalPrice: Money, preparatoryWorks: OfferSection, groundworks: OfferSection, environmentWorks: OfferSection, details: GeneralDetails): GeneralCategory => {
  return {
    totalPrice: totalPrice,
    preparatoryWorks: preparatoryWorks,
    groundworks: groundworks,
    environmentWorks: environmentWorks,
    details: details,
  };
};

const constructWarehouseCategory = (
  name: string,
  totalPrice: Money,
  preparatoryWorks: OfferSection,
  groundworks: OfferSection,
  foundation: OfferSection,
  structure: OfferSection,
  walls: OfferSection,
  flatRoofs: OfferSection,
  roofLighting: OfferSection,
  rainwaterDrainage: OfferSection,
  sewageSystem: OfferSection,
  flooring: OfferSection,
  gatesAndQuayEquipment: OfferSection,
  exteriorJoinery: OfferSection,
  techniques: OfferSection,
  environmentalWorks: OfferSection,
  mezzanines: OfferSection,
  offices: OfferSection,
  details: WarehouseDetails
): WarehouseCategory => {
  return {
    name: name,
    totalPrice: totalPrice,
    preparatoryWorks: preparatoryWorks,
    groundworks: groundworks,
    foundation: foundation,
    structure: structure,
    walls: walls,
    flatRoofs: flatRoofs,
    roofLighting: roofLighting,
    rainwaterDrainage: rainwaterDrainage,
    sewageSystem: sewageSystem,
    flooring: flooring,
    gatesAndQuayEquipment: gatesAndQuayEquipment,
    exteriorJoinery: exteriorJoinery,
    techniques: techniques,
    environmentalWorks: environmentalWorks,
    mezzanines: mezzanines,
    offices: offices,
    details: details,
  };
};

const constructOfferSection = (title: string, offerLines: OfferLine[], totalPrice: BigNumber): OfferSection => {
  return {
    title: title,
    lines: offerLines,
    totalPrice: offerMapper.mapBigNumberToMoney(totalPrice),
  };
};

const constructOfferLine = (productPrice: ProductPrice, quantity: string, unitPrice: string, totalPrice: BigNumber): OfferLine => {
  return {
    sku: productPrice.sku,
    title: productPrice.title,
    quantity: quantity,
    unitPrice: unitPrice,
    totalPrice: offerMapper.mapBigNumberToMoney(totalPrice),
  };
};

const mapMoney = (amount: number): Money => {
  return {
    amount,
    currency: 'EUR',
  };
};

const mapBigNumberToMoney = (amount: BigNumber): Money => {
  return mapMoney(amount.dp(2).toNumber());
};

const offerMapper = {
  constructOffer,
  constructOfferPricing,

  constructGeneralCategory,
  constructWarehouseCategory,

  constructOfferSection,
  constructOfferLine,

  mapMoney,
  mapBigNumberToMoney,
};

export default offerMapper;
