import { Fragment, useState } from 'react';
import { useTranslation, Labels } from '../../../i18n';
import ForgotPasswordContent from './ForgotPasswordContent';
import LoginForm from './LoginForm';
import { IconArrow } from '../../../assets/icons';

import styles from './Login.module.scss';

const Login = () => {
  const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
  return (
    <div className={ styles.page }>

      <div className={ styles.image }>
        <picture>
          <img src="img/bg__we-build-things.jpg" width={ 1216 } height={ 800 } alt="The Circle - work in progress" />
        </picture>
        <div className={ styles.lines6 }>
          <img src="img/lines/style6.png" alt="" />
        </div>
        <div className={ styles.lines5 }>
          <img src="img/lines/style5.png" alt="" />
        </div>
      </div>

      <div className={ styles.logo }>
        <a href="#the-circle">
          <img src="img/logo__the-circle-willy-naessens.png" width={ 273 } height={ 90 } alt="The Circle - Willy Naessens" />
        </a>
      </div>

      <div className={ styles.form }>
        { displayForgotPassword ?
          <ForgotPasswordContent onBack={ () => setDisplayForgotPassword(false) } /> :
          <LoginCardContent onForgotPasswordClick={ () => setDisplayForgotPassword(true) } />
        }
        <div className={ styles.lines4 }>
          <img src="img/lines/style4.png" alt="" />
        </div>
        <div className={ styles.lines2 }>
          <img src="img/lines/style2.png" alt="" />
        </div>
      </div>

    </div>
  );
};

type LoginCardContentProps = {
  onForgotPasswordClick: () => void;
};

const LoginCardContent = (props: LoginCardContentProps) => {
  const t = useTranslation();
  return (
    <Fragment>
      <h1 className={ styles.title }>{ t(Labels.loginTitle) }</h1>
      <LoginForm />
      <a
        href="#forgot-password"
        className={ styles.link }
        data-cy="forgotPasswordLink"
        onClick={ props.onForgotPasswordClick }
      >
        <IconArrow />
        <span>{ t(Labels.loginForgotPasswordCta) }</span>
      </a>
    </Fragment>
  );
};

export default Login;
