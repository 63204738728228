import { useAsyncFn } from 'react-use';
import { User } from '../../../auth';
import { authService } from '../../../auth';
import { Labels, useTranslation } from '../../../i18n';
import BackButton from './BackButton';
import { LoadingIndicator } from '../../../common';

import classNames from 'classnames';
import { useErrorBanner } from '../../../common/ui/ErrorBanner';

type RequestPasswordContentProps = {
  user: User;
  onBack: () => void;
};

const RequestPasswordContent = ({ onBack, user }: RequestPasswordContentProps) => {
  return (
    <>
      <BackButton onClick={ onBack } />
      <RequestPassword user={ user } />
    </>
  );
};


type RequestPasswordProps = {
  user: User;
};

const RequestPassword = ({ user }: RequestPasswordProps) => {
  const t = useTranslation();
  const { setVisible } = useErrorBanner();

  const fetchRequestPassword = async () => {
    // Unfortunately, useAsyncFn doesn't support throwing errors, so we have to do this manually
    // Unfortunately, useAsyncFn doesn't have an idle state. We use 'null' als default state to indicate the idle state. 'true' is used as success state
    // https://github.com/streamich/react-use/issues/2464
    try {
      await authService.requestPasswordReset();
      return true;
    } catch (e) {
      setVisible(true);
    }
  };

  const [state, doFetch] = useAsyncFn(fetchRequestPassword);

  if (state.value === true) {
    return <RequestPasswordSuccess user={ user } />;
  }

  const buttonClasses = classNames('form-submit', { 'form-submit--loader': state.loading });

  return (
    <>
      <h2 className="modal__title">{ t(Labels.userSettingsRequestPasswordTitle) }</h2>
      <div className="modal__text">{ t(Labels.userSettingsRequestPasswordBody, { email: user.email }) }</div>

      <button className={ buttonClasses } onClick={ doFetch }>
        <span>{ t(Labels.userSettingsRequestPasswordSubmit) }</span>
        { state.loading && <LoadingIndicator /> }
      </button>
    </>
  );
};


type RequestPasswordSuccessProps = {
  user: User;
};

const RequestPasswordSuccess = ({ user }: RequestPasswordSuccessProps) => {
  const t = useTranslation();

  return (
    <p>{ t(Labels.userSettingsRequestPasswordSuccess, { email: user.email }) }</p>
  );
};


export default RequestPasswordContent;
