import { Configuration } from './../configurator/domain/types';
import { useReducer, useEffect, useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Address } from '../../common/domain/types';
import { useErrorBanner } from '../../common/ui/ErrorBanner';
import { CompanyContact } from '../../company/domain/types';
import priceService from '../../prices/domain/priceService';
import { offerService } from '../offer';
import { Money, Offer } from '../offer/domain/types';
import projectService from './projectService';
import { Project, ProjectLanguage, ProjectStatus } from './types';
import { useDebounce } from 'react-use';
import hash from 'object-hash';

const DEBOUNCE_RATE_UPDATE_CONFIGURATION = 250;
const DEBOUNCE_RATE_UPDATE_IMAGES = 50;
const DEBOUNCE_RATE_UPDATE_FLOOR_PLANS = 50;

export type ProjectDetailViewModelType = ReturnType<typeof ProjectDetailViewModel>;

type ProjectDetailViewModelState = {
  project: Project | null;
  offer: Offer | null;
  error: Error | null;
  loading: boolean;
  updating: boolean;
};

const initialState: ProjectDetailViewModelState = {
  project: null,
  offer: null,
  error: null,
  loading: false,
  updating: false,
};

export default function ProjectDetailViewModel(projectId: string) {
  const { setVisible } = useErrorBanner();
  const [state, dispatch] = useReducer((previousState: ProjectDetailViewModelState, action: any) => {
    switch (action.type) {
      case 'onInitStarted':
        return {
          ...initialState,
          loading: true,
        };
      case 'onUpdateStarted':
        return {
          ...previousState,
          loading: false,
          updating: true,
          error: null,
        };

      case 'onUpdateSuccess':
        return {
          ...previousState,
          project: action.payload.project,
          loading: false,
          updating: false,
          error: null,
        };
      case 'onCreateOfferSuccess':
        return {
          ...previousState,
          offer: action.payload.offer,
        };

      case 'onError':
        setVisible(true);
        return {
          ...previousState,
          loading: false,
          updating: false,
          error: action.payload.error,
        };
      default:
        return previousState;
    }
  }, initialState);

  useEffect(() => {
    dispatch({ type: 'onInitStarted' });
    projectService.getProject(projectId)
      .then((project) => {
        dispatch({ type: 'onUpdateSuccess', payload: { project } });
      })
      .catch((error) => {
        dispatch({ type: 'onError', payload: { error } });
      });
  }, [projectId]);

  const updateStatus = async (status: ProjectStatus) => {
    try {
      dispatch({ type: 'onUpdateStarted' });
      const result = await projectService.updateProjectStatus(projectId, status);
      dispatch({ type: 'onUpdateSuccess', payload: { project: result } });
      return result;
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  const updateProject = async (
    companyContact: CompanyContact,
    name: string,
    language: ProjectLanguage,
    address: Address
  ) => {
    try {
      dispatch({ type: 'onUpdateStarted' });
      const result = await projectService.updateProject(projectId, companyContact, name, language, address);
      dispatch({ type: 'onUpdateSuccess', payload: { project: result } });
      return result;
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  const postOffer = useCallback(async (offer: Offer) => {
    try {
      const project = state.project;
      const offerHash = hash(offer);
      if (offerHash !== project?.offer?.offerHash) { // Is the new offer different from the one saved on the backend?
        const offerPdf = await projectService.createOffer(project.id, offer, offerHash);

        const newProject = {
          ...project,
          offer: offerPdf,
        };
        dispatch({ type: 'onUpdateSuccess', payload: { project: newProject } });
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  }, [state.project]);

  const createOffer = useCallback(async () => {
    try {
      const project = state.project;
      if (project?.configuration) {
        const priceList = await priceService.getPriceList(project.priceListId);
        const offer = offerService.createOffer(project.configuration, priceList, project.distance, project.discount);

        dispatch({ type: 'onCreateOfferSuccess', payload: { offer } });
        await postOffer(offer);
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  }, [postOffer, state.project]);

  useEffect(() => {
    createOffer();
  }, [state.project?.configuration, state.project?.priceListId, state.project?.distance, state.project?.discount, createOffer]);

  const updateDiscount = async (discount: Money) => {
    try {
      if (discount.amount != state.offer?.pricing.discount.amount) {
        dispatch({ type: 'onUpdateStarted' });
        const result = await projectService.updateDiscount(projectId, discount);
        dispatch({ type: 'onUpdateSuccess', payload: { project: result } });
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  // updateConfiguration only gets triggered when the newConfiguration state hasn't been updated for 250ms
  const [newConfiguration, debouncedUpdateConfiguration] = useState<Configuration | undefined>(state.project?.configuration);
  useDebounce(
    () => {
      if (newConfiguration) {
        updateConfiguration(newConfiguration);
      }
    },
    DEBOUNCE_RATE_UPDATE_CONFIGURATION,
    [newConfiguration]
  );
  const updateConfiguration = async (configuration: Configuration) => {
    try {
      if (hash(configuration) !== hash(state.project?.configuration)) {
        dispatch({ type: 'onUpdateStarted' });
        const result = await projectService.updateConfiguration(projectId, configuration);
        dispatch({ type: 'onUpdateSuccess', payload: { project: result } });
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  // updateImages only gets triggered when the rawImages state hasn't been updated for 50ms
  const [rawImages, debouncedUpdateImages] = useState<string[]>([]);
  useDebounce(
    () => {
      updateImages(rawImages);
    },
    DEBOUNCE_RATE_UPDATE_IMAGES,
    [rawImages]
  );
  const updateImages = async (images: string[]) => {
    try {
      if (images.length > 0) { // You can't overwrite/remove all images when no new ones have been taken
        const result = await projectService.updateVisuals(projectId, images);

        const newProject = {
          ...state.project,
          visuals: result,
        };
        dispatch({ type: 'onUpdateSuccess', payload: { project: newProject } });
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  // updateFloorPlans only gets triggered when the rawImages state hasn't been updated for 250ms
  const [rawFloorPlans, debouncedUpdateFloorPlans] = useState<string[]>([]);
  useDebounce(
    () => {
      updateFloorPlans(rawFloorPlans);
    },
    DEBOUNCE_RATE_UPDATE_FLOOR_PLANS,
    [rawFloorPlans]
  );
  const updateFloorPlans = async (images: string[]) => {
    try {
      if (images.length > 0) { // You can't overwrite/remove all images when no new ones have been taken
        const result = await projectService.updateFloorPlans(projectId, images);

        const newProject = {
          ...state.project,
          floorPlans: result,
        };
        dispatch({ type: 'onUpdateSuccess', payload: { project: newProject } });
      }
    } catch (error) {
      dispatch({ type: 'onError', payload: { error } });
    }
  };

  return {
    ...state,
    updateStatus,
    updateProject,
    createOffer,
    updateDiscount,
    updateConfiguration: debouncedUpdateConfiguration,
    updateImages: debouncedUpdateImages,
    updateFloorPlans: debouncedUpdateFloorPlans,
  };
}

export const useViewModel = () => {
  return useOutletContext<ProjectDetailViewModelType>();
};
