import axios from 'axios';
import { NoValidTokensError } from '../../auth/domain/exceptions';
import tokenInterceptor from '../../auth/domain/tokenInterceptor';
import AppConfig from '../../config/appConfig';

const unauthenticatedApiClient = axios.create({
  baseURL: AppConfig.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiClient = axios.create({
  baseURL: AppConfig.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(tokenInterceptor.onRequest);
apiClient.interceptors.response.use((response) => response, tokenInterceptor.onError);

const errorDispatcher = (error: any) => {
  let handled = false;
  handled = handled || error?.response?.status === 401;
  handled = handled || error instanceof NoValidTokensError;

  if (!handled) {
    window.dispatchEvent(new ErrorEvent('error', {
      message: error.message,
      error,
    }));
  }

  return Promise.reject(error);
};

apiClient.interceptors.response.use((response) => response, errorDispatcher);
unauthenticatedApiClient.interceptors.response.use((response) => response, errorDispatcher);

export { unauthenticatedApiClient };

export default apiClient;
