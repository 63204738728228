import { User } from '../../../auth';
import { Labels, useTranslation } from '../../../i18n';
import { ModalContent } from '../UserSettings';

import styles from './MenuContent.module.scss';

type MenuContentProps = {
  user: User;
  onMenuItemClicked: (requestedContent: ModalContent) => void;
};

const MenuContent = ({ user, onMenuItemClicked }: MenuContentProps) => {
  const t = useTranslation();

  const greeting = t(Labels.userSettingsGreeting, {
    name: user.firstName,
  });

  return (
    <>
      <h2 className={ styles.hello }>
        <span>{ t(Labels.userSettingsHi) }</span>
        <span>{ greeting }</span>
      </h2>
      <ul className={ styles.list }>
        <li className={ styles.item }>
          <MenuButton
            onClick={ () => onMenuItemClicked(ModalContent.requestPassword) }
            text={ t(Labels.userSettingsRequestPasswordCta) }
          />
        </li>
        <li className={ styles.item + ' ' + styles.logout }>
          <MenuButton
            onClick={ () => onMenuItemClicked(ModalContent.confirmLogout) }
            text={ t(Labels.userSettingsLogoutCta) }
          />
        </li>
      </ul>
    </>
  );
};

// Might also need a 'className' prop to add extra styling
// MenuButton will have default styling and can be extended using the prop
type MenuButtonProps = {
  onClick: () => void;
  text: string;
};

const MenuButton = ({ onClick, text }: MenuButtonProps) => {
  return (
    <button onClick={ onClick }>
      <span className={ styles.text }>{ text }</span>
    </button>
  );
};

export default MenuContent;
