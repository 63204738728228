import Dropdown, { DropdownOption } from '../../../common/ui/form/Dropdown';
import { Labels } from '../../../i18n';
import { ProjectLanguage } from '../../domain/types';

type LanguageDropdownProps = {
  label: string;
  selectedLanguage: ProjectLanguage;
  onChange: (selectedLanguage: ProjectLanguage) => any;
  disabled: boolean;
};

function LanguageDropdown(props: LanguageDropdownProps) {
  const languages = Object.values(ProjectLanguage);

  const options: DropdownOption[] = languages.map((language) => ({
    text: Labels[`language_${language}`],
    value: language.toString(),
  }));

  const selectedOption = options.find((option) => option.value === props.selectedLanguage?.toString());

  return (
    <Dropdown
      identifier='language'
      options={ options }
      label={ props.label }
      onChange={ (option) => props.onChange(option!.value as ProjectLanguage) }
      selectedOption={ selectedOption }
      required={ true }
      disabled={ props.disabled }
    />
  );
}

export default LanguageDropdown;
