import { useState } from 'react';
import { IconArrow } from '../../../../assets/icons';
import styles from './Accordion.module.scss';

type AccordionItemProps = {
  identifier: string;
  header?: React.ReactNode;
  children: React.ReactNode;
  title: string;
};
const AccordionItem = ({ identifier, header, children, title }: AccordionItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={ styles.accordion }>
      <div className={ styles.accordionHeader }>
        <button className={ styles.btn } onClick={ () => setOpen(!open) } aria-expanded={ open } aria-controls={ identifier }>
          <span className={ styles.icon }><IconArrow /></span>
          <span className={ styles.text }>{ title }</span>
        </button>
        { header && <div className={ styles.change }>{ header }</div> }
      </div>
      <div id={ identifier } className={ styles.accordionContent } aria-hidden={ !open }>
        <ul className={ styles.accordionContentList }>
          { children }
        </ul>
      </div>
    </div>
  );
};

export default AccordionItem;
