import { Company } from '../../domain/types';
import { CompanyApi } from './companyApi';

const mockCompanies: Company[] = [
  {
    id: '1',
    name: 'H. Essers',
    logo: 'https://www.tl-hub.be/uploads/public/company/logos/5880bf7ebe781.png',
    companyNumber: 'BE 0459 418 625',
    address: 'Amerigo Vespuccistraat 2/A000, 9940 Evergem',
    telephoneNumber: '+32 50 96 10 00',
    companyContacts: [
      {
        id: '1337',
        email: 'billy.gates@duo-brugge.be',
        firstName: 'Billy',
        lastName: 'Gates',
      },
      {
        id: '42',
        email: 'jefke.bezos@duo-brugge.be',
        firstName: 'Jefke',
        lastName: 'Bezos',
      },
    ],
  },
  {
    id: '2',
    name: 'Duo',
    logo: 'https://www.duo.be/themes/custom/duo/dist/img/logos/duo-full.svg',
    companyNumber: 'BE 0459 418 625',
    address: 'Bargeweg 2, 8000 Brugge',
    telephoneNumber: '+32 50 96 10 00',
    companyContacts: [],
  },
];

const getCompanies = async (): Promise<Company[]> => {
  await new Promise((r) => setTimeout(r, 1000));
  return mockCompanies;
};

const mockCompanyApi: CompanyApi = {
  getCompanies,
};

export default mockCompanyApi;
